import { BERLIN_BLUE, ROW_FOCUSED_BG } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const PAGE_CONTENT = {
  viewMapLinkText: 'View map and add fields',
  deleteSuccessSuffix: 'was successfully deleted.',
  deleteErrorSuffix:
    "couldn't be deleted due to a technical error. Please try again.",
  deleteModalCancelText: 'Cancel',
  deleteModalAcceptText: 'Delete',
  fieldNameExistsError: 'Field name already exists',
  add_fields_on_map: 'Add fields on map',
  proof_of_implementation: 'Proof of implementation',
  add_fields: 'Field',
};

export const deleteTypes = ['farm', 'field'];

export const getDeleteModalTitle = (fieldName) => `Delete ${fieldName}`;
export const getDeleteModalSubTitle = (fieldName) =>
  `Are you sure you want to delete ${fieldName}? Any data entered will be lost.`;

export const deleteModalInit = {
  open: false,
  title: '',
  subtitle: '',
  deleteProps: null,
};

export const farmAndFieldTabStatus = {
  inProgress: 'In Progress',
  completed: 'Completed',
  notStarted: 'Not Started',
};

/**
 * @description Farm tab refers to left menu item where farm name(or participant name) is displayed
 * @returns background color if flag is set to true else returns text color
 */
export const getFarmTabColor = (backgroundColor = false) => {
  return checkTernaryCondition(backgroundColor, ROW_FOCUSED_BG, BERLIN_BLUE);
};
