import PropTypes from 'prop-types';
import { Box } from '@mui/system';

const TabPanel = (props) => {
  const {
    children,
    value,
    index,
    customType,
    style,
    boxHeight,
    extraClassName,
    customBoxStyle = {},
    ...other
  } = props;
  return (
    <div
      className={extraClassName}
      style={{ ...style, flex: 1 }}
      role={'tabpanel'}
      hidden={value !== index}
      id={`tab-${index}`}
      aria-labelledby={`page-tab-${index}`}
      {...other}>
      {value === index && (
        <Box
          sx={{
            ...customBoxStyle,
            my: customType === 'table' ? 0 : 1,
            ...(boxHeight && { height: boxHeight }),
          }}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  style: PropTypes.object,
  boxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  extraClassName: PropTypes.string,
  customType: PropTypes.string,
  customBoxStyle: PropTypes.object,
};

export default TabPanel;
