import * as React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import { ReactComponent as ChipCancelIcon } from '../../../assets/icons/chipcloseicon.svg';
import {
  AlertIconSx,
  CheckboxSx,
  FormFields,
  FormLabels,
  InputSx,
  InstructionWrapper,
  LabelTextWrapper,
  LabelToolTipWrapper,
  OptionalLabel,
  OutlinedInputSx,
  ToolTipAndAlertWrapper,
  ToolTipSx,
} from '../FormStyles';
import {
  CustomPaper,
  chipStyle,
  dropdownArrowSx,
  placeholderSx,
  slotProps,
  CustomPaperforContributers,
} from './AutoComplete.style';
import { FORM_CONTENT } from '../FormComponents.content';
import { checkTernaryCondition } from 'utils/helper';
import { useState, useEffect } from 'react';
import InfoTooltip from '../InfoTooltip/InfoTooltip.react';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import {
  autoCompleteOnErrorSx,
  autoCompleteSx,
  autoCompleteSxChippedState,
  hideAutoCompleteInput,
} from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import { GLOBAL_STRING_CONSTANTS } from 'utils/config';
import { BLACK } from 'theme/GlobalColors';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutoCompleteForm = ({
  hasCheckbox,
  isAddContributor,
  label,
  instructionText,
  primaryError,
  secondaryError,
  placeholder,
  valueSetter,
  isOptional,
  helperText,
  list,
  value,
  formValue,
  isOutlined = true,
  hasLimit,
  limitValue,
  customSx,
  marginTop,
  labelGap,
  marginBottom,
  minWidth,
  hasNewStyles = false,
  isDisabled = false,
  tooltipProps = null,
  optionalLabelMarginLeft = '0.5rem',
  hasNoBottomMargin = false,
  onInputChangeHandler = () => {
    /* do nothing */
  },
  chipOptionFormatter = (option) => option,
  customOptionFormatter = (_props, option) => option,
  onBlurHandler = () => {
    /* do nothing */
  },
  isMultiple = true,
  disableCloseOnSelect = true,
  onKeyDown = () => {},
  onFocusCapture = () => {},
  isContributors = false,
  customSlotProps = {},
  width,
  hideInput = false,
  disableClearable = false,
  hideNoOptionsLabel = false,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [disableClose, setDisableClose] = useState(disableCloseOnSelect);

  const handleOptionSelect = (newValue) => {
    if (hasLimit && newValue.length <= limitValue) {
      setDisableClose(true);
      setSelectedOptions(newValue);
      valueSetter(newValue);
    } else {
      valueSetter(newValue);
    }
  };

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  return (
    <FormFields hasNoBottomMargin={hasNoBottomMargin} minWidth={minWidth}>
      <FormLabels
        iserror={primaryError || secondaryError}
        marginTop={marginTop}
        marginBottom={marginBottom}
        gap={labelGap}>
        <LabelToolTipWrapper>
          <LabelTextWrapper>{label}</LabelTextWrapper>
          <ToolTipAndAlertWrapper>
            {tooltipProps && (
              <InfoTooltip
                title={tooltipProps.title}
                content={tooltipProps.content}
                csafToolTipSx={ToolTipSx}
              />
            )}
            {(primaryError || secondaryError) && (
              <AlertIcon
                data-testid="autocomplete-error-icon"
                style={AlertIconSx}
              />
            )}
          </ToolTipAndAlertWrapper>
        </LabelToolTipWrapper>
        <InstructionWrapper>{instructionText}</InstructionWrapper>
      </FormLabels>
      <Autocomplete
        disabled={isDisabled}
        PaperComponent={
          hasNewStyles &&
          checkTernaryCondition(
            isContributors,
            CustomPaperforContributers,
            CustomPaper,
          )
        }
        multiple={isMultiple}
        id="checkboxes-tags-demo"
        options={list}
        disableCloseOnSelect={disableClose}
        onChange={(_event, newValue) => {
          handleOptionSelect(newValue);
        }}
        onBlur={onBlurHandler}
        getOptionLabel={(option) => option}
        onInputChange={onInputChangeHandler}
        value={checkTernaryCondition(hasLimit, selectedOptions, value)}
        renderTags={checkTernaryCondition(
          isMultiple,
          (tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const keyVal = index;
              return (
                <Chip
                  data-testid="autocomplete-chip"
                  key={keyVal}
                  deleteIcon={<ChipCancelIcon />}
                  label={chipOptionFormatter(option)}
                  {...getTagProps({ index })}
                  sx={chipStyle}
                />
              );
            }),
          null,
        )}
        slotProps={{ ...slotProps, ...customSlotProps }}
        sx={{
          ...(primaryError
            ? {
                ...autoCompleteSx,
                ...autoCompleteOnErrorSx,
              }
            : { ...InputSx, ...OutlinedInputSx }),
          '& .MuiOutlinedInput-root': {
            padding: '0rem',
            minHeight: '2.25rem',
            borderRadius: '0.25rem',
          },
        }}
        renderOption={(props, option, { selected }, _state) => (
          <li
            {...props}
            aria-disabled={
              hasLimit && !selected && selectedOptions.length >= limitValue
            }>
            {hasCheckbox && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{
                  marginRight: 8,
                }}
                checked={selected}
                disabled={
                  hasLimit && !selected && selectedOptions.length >= limitValue
                }
                sx={CheckboxSx}
              />
            )}
            {customOptionFormatter(props, option)}
          </li>
        )}
        style={{ width: width || '100%' }}
        renderInput={(params) => (
          <TextField
            {...params}
            data-testid="autocomplete-input-field"
            placeholder={formValue?.length > 0 ? '' : placeholder}
            variant={checkTernaryCondition(
              isOutlined,
              FORM_CONTENT.outlined,
              FORM_CONTENT.standard_variant,
            )}
            error={primaryError}
            helperText={primaryError && helperText}
            sx={{
              ...checkTernaryCondition(
                selectedOptions.length > 0,
                {
                  ...autoCompleteSxChippedState,
                  ...(hideInput && hideAutoCompleteInput),
                  ...(!isAddContributor && placeholderSx),
                },
                {
                  ...customSx,
                  ...(!isAddContributor && placeholderSx),
                  ...dropdownArrowSx,
                  '& .MuiOutlinedInput-root': {
                    padding: '0rem',
                    minHeight: '2.25rem',
                    borderRadius: '0.25rem',
                  },
                  '&& .MuiAutocomplete-input': {
                    paddingLeft: '0.5rem',
                  },
                },
              ),
              ...(disableClearable && {
                '& .MuiAutocomplete-clearIndicator': {
                  display: 'none',
                },
              }),
            }}
          />
        )}
        onKeyDown={onKeyDown}
        onFocusCapture={onFocusCapture}
        {...(hideNoOptionsLabel && { noOptionsText: '' })}
      />
      {isOptional && (
        <OptionalLabel
          fontSize={'0.688rem'}
          lineHeight={'0.936rem'}
          color={BLACK}
          marginLeft={optionalLabelMarginLeft}>
          {GLOBAL_STRING_CONSTANTS.OPTIONAL_TEXT_WITHOUT_BRACKETS}
        </OptionalLabel>
      )}
    </FormFields>
  );
};

AutoCompleteForm.propTypes = {
  hasCheckbox: PropTypes.bool,
  label: PropTypes.string,
  instructionText: PropTypes.string,
  primaryError: PropTypes.string,
  secondaryError: PropTypes.string,
  placeholder: PropTypes.string,
  valueSetter: PropTypes.func,
  isOptional: PropTypes.bool,
  helperText: PropTypes.string,
  list: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOutlined: PropTypes.bool,
  minWidth: PropTypes.string,
  hasLimit: PropTypes.bool,
  limitValue: PropTypes.number,
  customSx: PropTypes.object,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasNewStyles: PropTypes.bool,
  isDisabled: PropTypes.bool,
  tooltipProps: PropTypes.object,
  optionalLabelMarginLeft: PropTypes.string,
  hasNoBottomMargin: PropTypes.bool,
  onInputChangeHandler: PropTypes.func,
  isMultiple: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  isAddContributor: PropTypes.bool,
  chipOptionFormatter: PropTypes.func,
  customOptionFormatter: PropTypes.func,
  onBlurHandler: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocusCapture: PropTypes.func,
  isContributors: PropTypes.bool,
  customSlotProps: PropTypes.object,
  width: PropTypes.string,
  hideInput: PropTypes.bool,
  disableClearable: PropTypes.bool,
  hideNoOptionsLabel: PropTypes.bool,
};

export default AutoCompleteForm;
