export const MAP_COMP_TEXT_CONTENT = {
  loading: 'Loading...',
  addBoundaryFilesBtnText: '+ Boundary file(s)',
};

export const libraries = ['places', 'drawing'];

export const defaultMapOptions = {
  minZoom: 2.6,
  maxZoom: 19,
  restriction: {
    latLngBounds: {
      north: 79.180854,
      south: -58.143958,
      east: 177.666436,
      west: -177.235908,
    },
  },
};
