import styled from 'styled-components';
import {
  BLUISH_CYAN,
  COOL_GRAY,
  DARK_GREEN,
  QUILL_GRAY,
  RED,
  NEW_DARK_GREEN,
  WHITE,
  DARK_CHARCOAL,
  BLACK,
} from 'theme/GlobalColors';

export const MainContainer = styled.div`
  background-color: white;
  max-height: calc(100vh - 10.8rem);
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${QUILL_GRAY};
    border-radius: 3px;
  }
`;

export const TextWrapper = styled.div`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  line-height: ${({ lineHeight }) => lineHeight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-left: ${({ marginLeft }) => marginLeft};
  white-space: ${({ whiteSpace }) => whiteSpace};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
`;
export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
`;
export const DatePickersWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
export const submitButtonStyle = {
  background: DARK_GREEN,
  width: '5rem',
  color: 'white',
  textTransform: 'none',
  '&:hover': {
    background: DARK_GREEN,
  },
};
export const toastStyle = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
export const addProjectActivityBtnSx = {
  textTransform: 'none',
  height: '1.75rem',
  fontSize: '0.875rem',
  padding: '0.375rem 0.5rem',
  fontWeight: '400',
  fontFamily: 'Open sans, sans-serif',
  width: 'fit-content',
  marginBottom: '1.5rem',
};

export const dividerSx = {
  marginTop: '0rem',
};

export const FieldWebformDisclaimer = styled.div`
  width: 544px;
  margin: 0.25rem 0rem 0rem 0.5rem;
  color: ${COOL_GRAY};
  font-size: 0.688rem;
  line-height: 14.98px;
`;
export const BoldTextWrapper = styled.span`
  font-weight: ${({ fontWeight }) => fontWeight || 400};
`;
export const DropdownDateWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
`;
export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const PlannedProjectActivitiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
`;
export const PlannedProjectActivityName = styled.div`
  width: 21.375rem;
  height: 2rem;
  display: flex;
  align-items: center;
`;
export const PlannedProjectActivityAcreInputField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
export const PlannedProjectActivityDataContainer = styled.div`
  width: 7.938rem;
  height: 2rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
`;
export const PlannedProjectActivitiesErrorContainer = styled.div`
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 0.25rem;
  margin-left: 22.875rem;
  display: ${({ display }) => display || 'flex'};
`;
export const ErrorIconStyle = {
  paddingTop: '0.094rem',
  marginLeft: '0.5rem',
  top: '0',
  color: RED,
  height: '1rem',
  width: '1rem',
};

export const DeleteIconSx = {
  color: COOL_GRAY,
  width: '1.5rem',
  height: '1.5rem',
  '&:hover': {
    color: BLUISH_CYAN,
  },
};
export const PlannedProjectActivityHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;
export const HeadingWrapper = styled.div`
  display: flex;
  padding-left: 1.5rem;
  width: 17.813rem;
  height: 1.75rem;
  gap: 1rem;
  padding-top: 0.5rem;
`;
export const deleteIconHoverStyle = {
  '&.MuiSvgIcon-root': {
    ':hover': { fill: NEW_DARK_GREEN },
  },
};
export const PlannedProjectActivityTooltipSx = {
  padding: 0,
  marginLeft: '0.5rem',
  width: '1rem',
  height: '1rem',
  marginBottom: '-0.3rem',
};
export const backdropSx = { color: WHITE, zIndex: 9999999 };
export const AlertIcontStyle = {
  top: '0',
};

export const FormslableStyle = {
  color: DARK_CHARCOAL,
};

export const deleteModalStyle = {
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const switchStyleLabel = {
  fontSize: '0.75rem',
  color: BLACK,
  alignSelf: 'center',
  width: '6rem',
};

export const LiveStockInfoWrapper = styled.div`
  display: flex;
`;
export const ModalLinkWrapper = styled.div`
  color: ${BLUISH_CYAN};
  font-size: 0.875rem;
  cursor: pointer;
`;
