import PropTypes from 'prop-types';
import { Switch } from '@mui/material';
import {
  SwitchAndLabelWrapper,
  SwitchSx,
  TextWrapper,
} from './SwitchComponent.style';
import { isEmpty } from 'utils/helper';
import { FORM_BOTTOM_BORDER, WHITE } from 'theme/GlobalColors';

const SwitchComponent = ({
  name,
  isChecked,
  handleChange,
  label,
  fontSize,
  disabled = false,
  stylesSx = {},
  customDisableBackgroundColor = FORM_BOTTOM_BORDER,
  customThumbColor = WHITE,
}) => {
  return (
    <SwitchAndLabelWrapper>
      <Switch
        sx={{
          ...SwitchSx({
            customDisableBackgroundColor: customDisableBackgroundColor,
            customThumbColor: customThumbColor,
          }),
          ...stylesSx,
        }}
        checked={isChecked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled', 'data-testid': name }}
        name={name}
        disabled={disabled}
      />
      {!isEmpty(label) && (
        <TextWrapper fontSize={fontSize}>{label}</TextWrapper>
      )}
    </SwitchAndLabelWrapper>
  );
};

SwitchComponent.propTypes = {
  name: PropTypes.string,
  isChecked: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  stylesSx: PropTypes.object,
  customDisableBackgroundColor: PropTypes.string,
  customThumbColor: PropTypes.string,
};

export default SwitchComponent;
