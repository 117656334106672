import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_GREEN,
  NEW_DARK_GREEN,
  NEW_HOVER_GREEN_BACKGROUND,
  BLACK,
  WHITE,
} from 'theme/GlobalColors';

const addTypeHoverBackground = NEW_HOVER_GREEN_BACKGROUND;

export const FieldAddTypeListItem = styled.div`
  outline: none;
  padding: 0.75rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-radius: 0.125rem;
  border: ${({ border }) => border || `1px solid ${DARK_GREEN}`};
  min-width: 12.5rem;
  margin-bottom: ${({ marginBottom }) => marginBottom || '1rem'};
  cursor: pointer;

  &:hover {
    background-color: ${({ hoverEffect }) =>
      hoverEffect && addTypeHoverBackground};
  }
`;

export const FieldAddTypeText = styled.div`
  font-size: ${({ subtext }) => (subtext ? '0.75rem' : '0.875rem')};
  font-family: 'Open sans', sans-serif;
  color: ${DARK_GREEN};
  letter-spacing: ${({ subtext }) => (subtext ? '0.2px' : 'inherit')};
`;

export const UploaderWrapper = styled.div`
  margin-bottom: 1rem;
  border: 1px solid ${DARK_GREEN};
  border-radius: 0.125rem;
`;
export const closeIconStyle = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: (theme) => theme.palette.grey[500],
};

export const subtitleSx = { marginBottom: '1rem', fontSize: '0.875rem' };

export const DropZoneWrapper = styled.div`
  padding: 1rem;
`;

export const UploadBtnWrapper = styled.div`
  width: 100%;
  text-align: right;
`;

export const uploadBtnSx = {
  fontSize: '0.875rem',
  textTransform: 'none',
  width: '5.5rem',
  color: WHITE,
  backgroundColor: DARK_GREEN,
  margin: '5px',
  height: '1.75rem',

  '&:hover': {
    color: WHITE,
    backgroundColor: DARK_GREEN,
  },

  '&.Mui-disabled': {
    color: BLACK,
  },
};

export const ModalTitleSx = { m: 0, p: '1rem 1rem 0 1rem' };

export const deleteIconStyle = {
  color: COOL_GRAY,
  '&:hover': {
    fill: NEW_DARK_GREEN,
  },
};
