import { TooltipWrapper } from './InfoTooltip.style';

export const customTitle = (title, content, edgeOfFieldToolTip) => {
  return (
    <TooltipWrapper
      flexWrap={'wrap'}
      fontWeight={edgeOfFieldToolTip ? '0' : '600'}
      fontSize="14px"
      alignItems={edgeOfFieldToolTip ? 'left' : 'center'}>
      {title}
      <TooltipWrapper fontWeight="400" fontSize="12px">
        {(content instanceof Array && (
          <ul>
            {content.map((item, index) => {
              const keyVal = index;
              return <li key={keyVal}>{item}</li>;
            })}
          </ul>
        )) ||
          content}
      </TooltipWrapper>
    </TooltipWrapper>
  );
};
