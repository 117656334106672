import { ReactComponent as DropPinIcon } from '../../../../../assets/icons/drop_pin.svg';
import { ReactComponent as DrawBoundaryIcon } from '../../../../../assets/icons/draw_boundary.svg';

export const TOOLTIP_CONTENT_LIST = [
  {
    label: 'Draw Boundary',
    content: 'Click on map to add boundary via pins along the field perimeter',
    icon: <DrawBoundaryIcon />,
  },
  {
    label: 'Drop Pin',
    content: 'Click on map to drop pin and field boundary will be determined',
    icon: <DropPinIcon />,
  },
];
