import styled from 'styled-components';
import { COOL_GRAY, DARK_CHARCOAL } from 'theme/GlobalColors';

export const TooltipWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: 140%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  font-weight: ${({ alignItems }) => alignItems};
  color: ${DARK_CHARCOAL};
`;
export const IconStyle = (bottomMargin) => {
  return {
    height: '1.2rem',
    width: '1.2rem',
    color: COOL_GRAY,
    cursor: 'pointer',
    marginBottom: bottomMargin,
  };
};
