export const MODAL_CONTENT = {
  TITLE: 'Edge of field area guidance',
  DESCRIPTION: [
    'Edge of Field practice activities must be submitted in acres. Use the following equation to calculate the acreage for each Edge of Field practice: ',
    'Acreage = ((Length in Ft. x Width in Ft.) * # of strips/borders/barriers/buffers) / 43,560 ft. ',
    'Depending on the magnitude of the Edge of Field practice, total acreage could be <1 acre per field.',
    'Please check NRCS State Guidelines to ensure that the length and width of your planned practices align with the recommendations in your state.',
  ],
  ACCEPT_BTN: 'Ok',
  LABEL: 'Guidance for calculating acres',
};
