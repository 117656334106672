import { DARK_CHARCOAL } from 'theme/GlobalColors';

export const FarmLockModalContextSx = {
  '& .MuiDialogContent-root': {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
};
export const FarmLockingSx = {
  '& .MuiDialog-paper': {
    minWidth: '28.25rem',
    width: '28.25rem',
    height: '10.75rem',
    overflow: 'hidden',
  },
  ...FarmLockModalContextSx,
};
export const FarmLockModalSubtitleSx = {
  color: DARK_CHARCOAL,
  fontSize: '0.875rem',
  overflow: 'hidden',
  letterSpacing: '0rem',
  lineHeight: '1.225rem',
};
export const FarmExtendSx = {
  '& .MuiDialog-paper': {
    minWidth: '28.25rem',
    width: '28.25rem',
    height: '9.75rem',
    overflow: 'hidden',
  },
  ...FarmLockModalContextSx,
};
export const FormLockedSx = {
  '& .MuiDialog-paper': {
    minWidth: '28.25rem',
    width: '28.25rem',
    height: '7.25rem',
  },
  ...FarmLockModalContextSx,
};
export const TimeoutTextSx = {
  marginTop: '1rem',
  marginBottom: '0rem',
};
