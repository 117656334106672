import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@mui/material';

const Loader = (props) => {
  const { loading, zIndex } = props;

  return (
    <Backdrop
      open={loading}
      sx={{
        color: '#fff',
        zIndex: (theme) => zIndex ?? theme.zIndex.drawer + 1,
      }}>
      <CircularProgress color="success" />
    </Backdrop>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool,
  zIndex: PropTypes.number,
};

Loader.defaultProps = {
  zIndex: (theme) => theme.zIndex.drawer + 1,
};

export default Loader;
