import styled from 'styled-components';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const DialogStyles = {
  main: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: '31.25rem',
        padding: '1.5rem',
        fontWeight: 400,
      },
    },
  },
  title: {
    padding: '0 0 1rem 0',
    fontFamily: 'Open sans, sans serif',
    fontSize: '1.25rem',
    fontWeight: 400,
    color: DARK_CHARCOAL,
  },
  content: {
    padding: '0 0 1rem 0',
    fontFamily: 'Open sans, sans serif',
    fontSize: '0.875rem',
    lineHeight: '19.6px',
    color: DARK_CHARCOAL,
  },
  actions: {
    padding: 0,
    justifyContent: 'flex-start',
  },
};
