export const EditModeLabelSx = ({ labelColor }) => {
  return {
    display: 'flex',
    fontFamily: 'Open Sans',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.021rem',
    letterSpacing: '0.20000000298023224px',
    textAlign: 'right',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    width: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.25rem',
    color: labelColor,
  };
};

export const IconSx = ({ color }) => ({
  height: '1rem',
  width: '1rem',
  color: color,
});
