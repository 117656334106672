import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import Tabber from 'components/Tabber/Tabber.react';
import UnsavedChangesModal from '../UnsavedChangesModal/UnsavedChangesModal.react';
import {
  HeadingWrapper,
  switchCompStyle,
  SwitchContainer,
} from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import { FormHeading } from 'components/FormComponents/FormStyles';
import { ADMIN, ORIGINATION_MANAGER } from 'utils/config';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import SwitchComponent from 'components/FormComponents/SwitchComponent/SwitchComponent.react';
import DeleteFarmAndFields from '../DeleteFarmAndFields/DeleteFarmAndFields.react';
import { activityInstanceStatus } from 'pages/ParticipantDataCollection/ActivityReporting/ActivityReporting.content';
import FarmEditModeLabel from '../FarmEditModeLabel/FarmEditModeLabel.react';
import { BRIGHTER_RED } from 'theme/GlobalColors';

const FarmWithMapTabs = ({
  currentTab,
  setCurrentTab,
  tabList,
  handleSwitchChange,
}) => {
  const {
    addFarmClicked,
    setCurrentFarmId,
    setExpandedFarmId,
    setFarmInfoCurrentTab,
    setShowFieldInfoForm,
    farmInfoCurrentTab,
    setAddFarmClicked,
    setIsTabSwitched,
    userActivityDetected,
    unsavedChangesModalOpenProps,
    setUnsavedChangesModalOpenProps,
    closeUnsavedChangesModal,
    setTriggerFarmInfoSubmit,
    setUserActivityDetected,
    currentFarmId,
    setCurrentFarmLocation,
    isParticipantContributor,
    switchState,
    isSwitchDisabled,
    activeUser,
    farmInfo,
    fetchParticipantData,
    currentCycleStartDate,
    currentCycleEndDate,
  } = useContext(participantInfoContext);

  const { userRoles } = useContext(userAuthRolesContext);

  // Restructured farm list for delete component usage
  const farmList = useMemo(() => {
    if (!farmInfo || farmInfo.length === 0) return [];

    return farmInfo.map((farm) => ({
      ...farm,
      farmId: farm.id,
      farmName: farm.value,
      fieldDetails: farm.fields.map((field) => ({
        ...field,
        fieldId: field.id,
        fieldName: field.value,
        farmId: farm.id,
      })),
    }));
  }, [farmInfo]);

  useEffect(() => {
    if (farmInfoCurrentTab === 1 && addFarmClicked.flag) {
      setCurrentFarmId(addFarmClicked.id);
      setExpandedFarmId(addFarmClicked.id);
      setFarmInfoCurrentTab(0);
      setShowFieldInfoForm(false);
      setAddFarmClicked({ id: 0, flag: false });
    }
  }, [addFarmClicked]);

  const switchTab = () => {
    setCurrentTab(unsavedChangesModalOpenProps.nextTab);
    closeUnsavedChangesModal();
    setUserActivityDetected(false);
    setCurrentFarmLocation(currentFarmId);
  };

  const handleSaveAndLeave = () => {
    setTriggerFarmInfoSubmit(true);
  };

  const handleFarmWithMapTabChange = (tab) => {
    if (currentTab === tab) return;

    // Note: Open unsave changes modal on tab shift if there are unsaved changes, and the tab is switched from farm to map
    if (userActivityDetected && currentTab === 0) {
      setUnsavedChangesModalOpenProps({
        isOpen: true,
        nextTab: tab,
        targetFieldId: null,
        targetFieldName: '',
        targetFarmId: currentFarmId,
        targetFarmName: '',
        navigateNextTo: 'Tab',
        triggeredFrom: 'Tab',
      });
      return;
    }
    setCurrentTab(tab);
    setCurrentFarmLocation(currentFarmId);
  };

  const currentFarmName = farmInfo.find(
    (farm) => farm.id === currentFarmId,
  )?.value;

  const handleNavigateAfterDelete = (navigateProps) => {
    const { farmData } = navigateProps;

    setCurrentFarmId(farmData.farmId);
    setExpandedFarmId(farmData.farmId);
    fetchParticipantData(false); // Refresh enrollment page with latest farms list
  };

  const isFarmApproved =
    farmInfo?.filter((farm) => farm.id === currentFarmId)[0]
      ?.activityReportingStatus === activityInstanceStatus.approved;

  return (
    <>
      <HeadingWrapper>
        <FormHeading>{currentFarmName ?? ''}</FormHeading>
        <SwitchContainer pointerEvents={isSwitchDisabled ? 'none' : 'inherit'}>
          <SwitchComponent
            handleChange={handleSwitchChange}
            name="farm-lock"
            isChecked={switchState}
            stylesSx={switchCompStyle}
            disabled={isSwitchDisabled}
            customDisableBackgroundColor={
              isSwitchDisabled && activeUser.name ? BRIGHTER_RED : null
            }
          />
          <FarmEditModeLabel
            isEditModeOn={switchState}
            isdisabled={isSwitchDisabled}
            userName={
              userRoles?.includes(ADMIN) ||
              userRoles?.includes(ORIGINATION_MANAGER)
                ? activeUser.name
                : 'another user'
            }
            activeUserId={activeUser.activeUserId}
          />
        </SwitchContainer>
        {switchState && !isFarmApproved && (
          <DeleteFarmAndFields
            farmList={farmList}
            currentFarmId={currentFarmId}
            currentFieldId={null}
            projectCycleData={{
              cycleStartDate: currentCycleStartDate,
              cycleEndDate: currentCycleEndDate,
            }}
            refreshData={() => fetchParticipantData(false)}
            navigateCallback={handleNavigateAfterDelete}
            hideFarmDelete={isParticipantContributor}
            isParticipantContributor={isParticipantContributor}
          />
        )}
      </HeadingWrapper>
      <Tabber
        currentTab={currentTab}
        setCurrentTab={handleFarmWithMapTabChange}
        tabList={tabList}
        onTabChange={(tab) => setIsTabSwitched(tab === 1)}
        customTabberStyles={{ paddingTop: '0.813rem' }}
        marginTop={0}
      />
      <UnsavedChangesModal
        open={
          unsavedChangesModalOpenProps?.isOpen &&
          unsavedChangesModalOpenProps?.triggeredFrom === 'Tab'
        }
        handleClose={closeUnsavedChangesModal}
        handleLeaveWithoutSave={switchTab}
        handleSaveAndLeave={handleSaveAndLeave}
      />
    </>
  );
};

FarmWithMapTabs.propTypes = {
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  tabList: PropTypes.arrayOf(PropTypes.string),
  handleSwitchChange: PropTypes.func,
};
export default FarmWithMapTabs;
