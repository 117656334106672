import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

export const ReportOnEmailViaBaseline = ({ children }) => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const { reportOnBaselineViaEmailLink } = useContext(LifeCycleContext);
  const enrollOnLoad = async () => {
    await reportOnBaselineViaEmailLink(projectId, participantId);
  };

  useEffect(() => {
    enrollOnLoad();
  }, []);

  return <>{children}</>;
};

ReportOnEmailViaBaseline.propTypes = {
  children: PropTypes.node.isRequired,
};
