import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';
import {
  ContentSubWrapper,
  ContentTextWrapper,
  ContentWrapper,
  IconWrapper,
  LabelWrapper,
} from './AddingBoundaryTooltip.style';
import { TOOLTIP_CONTENT_LIST } from './AddingBoundaryTooltip.content';

const AddingBoundaryTooltip = () => {
  return (
    <InfoTooltip
      maxWidth="468"
      title=""
      content={
        <div style={{ width: '29.25rem' }}>
          {TOOLTIP_CONTENT_LIST.map((content) => (
            <ContentWrapper key={content.label}>
              <IconWrapper>{content.icon}</IconWrapper>
              <ContentSubWrapper>
                <LabelWrapper>{content.label}</LabelWrapper>
                <ContentTextWrapper>{content.content}</ContentTextWrapper>
              </ContentSubWrapper>
            </ContentWrapper>
          ))}
        </div>
      }
    />
  );
};

export default AddingBoundaryTooltip;
