import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useLocation } from 'react-router';
import { checkTernaryCondition, isEmpty } from 'utils/helper';
import { primaryButtonStyle } from 'components/FormComponents/FormStyles';
import {
  DARK_CHARCOAL,
  HOVER_LIGHT_BLUE,
  ROW_FOCUSED_BG,
} from 'theme/GlobalColors';
import { Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { startIconStyle } from './AddButton.style';
const StyledMenu = styled((props) => (
  <Menu
    disableRestoreFocus
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: checkTernaryCondition(
        props.positionFlagForProjectActivity,
        'left',
        'right',
      ),
    }}
    style={{
      marginLeft: checkTernaryCondition(
        props.positionFlagForProjectActivity,
        '-6.438rem',
        '',
      ),
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '0.125rem',
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0.5rem 0',
    },
    '& .MuiMenuItem-root': {
      minWidth: '9.375rem',
      height: '1.75rem',
      padding: '6px 8px',
      fontSize: '0.875rem',
      color: DARK_CHARCOAL,
      ':hover': { backgroundColor: HOVER_LIGHT_BLUE },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: ROW_FOCUSED_BG,
      },
    },
  },
}));

AddButton.propTypes = {
  buttonName: PropTypes.string,
  noDropdown: PropTypes.bool,
  options: PropTypes.array,
  onClick: PropTypes.func,
  toggleModal: PropTypes.func,
  setSelectedOption: PropTypes.func,
  isModalPresent: PropTypes.bool,
  disabledOption: PropTypes.bool,
  disableButton: PropTypes.bool,
  customBtnSx: PropTypes.object,
  addBtnPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasMultipleModals: PropTypes.bool,
  hasStartIcon: PropTypes.bool,
  handleForProjectActivities: PropTypes.bool,
  onClickHandlerForProjectActivities: PropTypes.func,
  applySort: PropTypes.bool,
};

export default function AddButton({
  buttonName,
  noDropdown,
  options,
  onClick,
  toggleModal,
  setSelectedOption,
  isModalPresent,
  disabledOption = false,
  disableButton = false,
  customBtnSx = {},
  addBtnPadding,
  hasMultipleModals = false,
  hasStartIcon = false,
  handleForProjectActivities = false,
  onClickHandlerForProjectActivities,
  applySort = false,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // check if options exist or not
  const emptyOptions = isEmpty(options);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openModal = (name) => {
    if (hasMultipleModals) {
      options.find((option) => option.name === name).modalHandler();
    } else {
      toggleModal(true);
      setSelectedOption(name);
    }
    handleClose();
  };

  /**
   * @description This function is used to get the menu items
   * @returns {JSX.Element} returns the menu items
   */
  const getMenuItemOptions = () => {
    if (!emptyOptions) {
      const sortedOptions = checkTernaryCondition(
        applySort,
        [...options]?.sort((a, b) => a?.name?.localeCompare(b?.name)) ?? [],
        options,
      );
      return sortedOptions.map((option) => {
        if (handleForProjectActivities) {
          return (
            <MenuItem
              key={option.id}
              onClick={() => {
                onClickHandlerForProjectActivities(option.id);
                setAnchorEl(null);
              }}>
              {option.name}
            </MenuItem>
          );
        } else {
          return checkTernaryCondition(
            isModalPresent,
            <>
              {option?.hasDivider && <Divider />}{' '}
              <MenuItem
                onClick={() => openModal(option.name)}
                disableRipple
                disabled={
                  option?.isDisabled ||
                  checkTernaryCondition(
                    option.id !== 2 && disabledOption,
                    false,
                    disabledOption,
                  )
                }
                key={option.name}>
                {option.name}
              </MenuItem>
            </>,
            <MenuItem
              onClick={() =>
                navigation(option.link, {
                  state: { previousPath: pathname },
                })
              }
              disableRipple
              key={option.name}>
              {option.name}
            </MenuItem>,
          );
        }
      });
    }
  };

  return (
    <div>
      <Button
        startIcon={hasStartIcon && <AddIcon style={startIconStyle} />}
        disabled={disableButton || (!noDropdown && emptyOptions)}
        id="add-new-button"
        data-testid="add-new-button"
        aria-controls={open ? 'add-new-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={onClick || handleClick}
        sx={{ ...primaryButtonStyle(addBtnPadding), ...customBtnSx }}
        endIcon={!noDropdown ? <KeyboardArrowDownIcon /> : ''}>
        {buttonName || 'Add New'}
      </Button>
      {!noDropdown && !emptyOptions && (
        <StyledMenu
          positionFlagForProjectActivity={handleForProjectActivities}
          id="add-new-menu"
          MenuListProps={{
            'aria-labelledby': 'add-new-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          {getMenuItemOptions()}
        </StyledMenu>
      )}
    </div>
  );
}
