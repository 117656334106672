import moment from 'moment';
import PropTypes from 'prop-types';
import {
  checkTernaryCondition,
  isValueEmptyNullorUndefined,
} from 'utils/helper';
import { COOL_GRAY, DARK_CHARCOAL } from 'theme/GlobalColors';
import { ContentBox, ErrorBox, TextWrapper } from 'theme/GlobalStyledComps';
import { MainContainer } from '../ActivityReporting/ActivityReporting.style';
import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';
import { GLOBAL_STRING_CONSTANTS } from 'utils/config';

export const BASELINE_MODAL_CONTENT = {
  irrigation: 'Irrigation',
  irrigation_list: 'irrigationTypeList',
  irrigation_deleted_list: 'deletedIrrigationList',
  residue_mgmt: 'Residue management',
  residue_mgmt_list: 'residueManagementTypeList',
  residue_mgmt_deleted_list: 'deletedResidueManagementList',
  fertilizer: 'Fertilizer',
  fertilizer_list: 'nitrogenList',
  no_tillage: 'No tillage',
  fertilizer_deleted_list: 'deletedFertilizerList',
  cover_crop: 'Cover crop',
  cover_crop_list: 'coverCropList',
  cover_crop_deleted_list: 'deletedCoverCropList',
  tillage: 'Tillage',
  tillage_list: 'tillageTypeDtoList',
  tillage_deleted_list: 'deletedTillageList',
  soil_amendments: 'Soil amendments',
  soil_amendments_list: 'soilAmendmentList',
  soil_amendments_deleted_list: 'deletedSoilAmendmentList',
  edge_of_field: 'Edge of field',
  edge_of_field_list: 'edgeOfFieldList',
  edge_of_field_deleted_list: 'deletedEdgeOfFieldList',
  irrigation_nodata_option_key: 'irrigationType',
  irrigation_nodata_option_value: 0,
  fertilizer_nodata_option_key: 'fertilizerType',
  fertilizer_nodata_option_value: 0,
  cover_crop_nodata_option_key: 'cropId',
  cover_crop_nodata_option_value: 0,
  tillage_type_nodata_option_key: 'tillagePractice',
  tillage_type_nodata_option_value: 3,
  cash_crop: 'Cash crop',
  cash_crop_value: 'CASH_CROP',
  cover_crop_value: 'COVER_CROP',
  fallow_crop_value: 'FALLOW',
  activity_field_heading:
    'Please provide details on crop production and management practices for ',
  optional_text: '(Optional)',
  activity_farm_heading:
    'Please provide details on tractor / vehicle fuel consumption for  ',
  empty_farm_label: 'Tractors / vehicles fuel (0)',
  empty_crop_label: 'Crop (0)',
  residue_mgmt_nodata_option_value: 1,
  residue_mgmt_nodata_option_key: 'agricultureResiduesManaged',
  fallow: 'Fallow',
  baseline_header_field:
    'Please provide details on crop production / management practices for each year of the baseline scenario.',
  baseline_header_farm:
    'Please provide details on tractor / vehicle fuel consumption for each year of the baseline scenario.',
  activity_type: 'Activity',
  phase_baseline: 'baseline',
};

export const deletedModalsInitialData = {
  deletedSoilAmendmentList: [],
  deletedFertilizerList: [],
  deletedFossilFuelList: [],
  deletedResidueManagementList: [],
  deletedCoverCropList: [],
  deletedIrrigationList: [],
  deletedTillageList: [],
  deletedEdgeOfFieldList: [],
};

export const tractorVehicleList = (
  arrayList,
  fossilFuelTypeDropdown,
  fuelUomList,
) => {
  let secondaryLabel = [];
  const finalData = arrayList.map((item) => {
    secondaryLabel.push(
      fossilFuelTypeDropdown.find((type) => type.value === item?.fossilFuelType)
        ?.label,
    );
    return [
      {
        label: 'Fuel consumption',
        value: item.consumption,
        unit: fuelUomList[0]?.FUEL_CONSUMPTION?.find(
          (obj) => +obj.uomId === +item.unitOfMeasure,
        )?.uomNameDisplay,
        id: item.id,
      },
    ];
  });
  return {
    finalData: finalData,
    modalType: 'Tractor / vehicle fuel',
    secondaryLabel: secondaryLabel,
  };
};
export const irrigationTypeList = (arrayList, irrigationMethodDropDown) => {
  let secondaryLabel = [];
  let itemId = [];
  const finalData = arrayList.map((item) => {
    secondaryLabel.push(
      irrigationMethodDropDown.find(
        (type) => type.value === item?.data?.irrigationType,
      )?.label,
    );
    itemId.push(item.id);
    return [
      {
        label: 'Irrigation volume',
        value: item?.data?.irrigationRate,
        unit: item?.data?.uom,
        id: item.id,
      },
    ];
  });
  return {
    finalData: finalData,
    modalType: 'Irrigation',
    secondaryLabel: secondaryLabel,
    itemId: itemId,
  };
};
export const coverCropList = (
  arrayList,
  coverCropTypeDropdown,
  plannedUsage,
  terminationType,
) => {
  let secondaryLabel = [];
  let itemId = [];
  const finalData = arrayList.map((item) => {
    secondaryLabel.push(
      coverCropTypeDropdown.find((type) => type.value === item?.data?.cropId)
        ?.label,
    );
    itemId.push(item.id);
    let labelValuePairs = [];
    const plannedUsageValue = plannedUsage.find(
      (type) => type.value === item?.data?.plannedUsage,
    )?.label;
    const terminationTypeVal = plannedUsageValue === 'Termination';

    labelValuePairs.push(
      ...[
        {
          label: 'Cover crop area',
          value: item?.data?.coverCropArea,
          unit: 'acres',
          id: item.id,
        },
        {
          label: 'Planned usage',
          value: plannedUsageValue,
          id: item.id,
        },
      ],
    );
    if (terminationTypeVal) {
      labelValuePairs.push({
        label: 'Termination type',
        id: item.id,
        value:
          terminationType.find(
            (type) => type.value === item.data.terminationType,
          )?.label || '',
      });
    }
    labelValuePairs.push(
      ...[
        {
          label: 'Date planted',
          value: checkTernaryCondition(
            item.data.cropPlantDate?.toString().length > 0,
            moment(item.data.cropPlantDate).format('MM/DD/YYYY'),
            '',
          ),
          id: item.id,
        },
        {
          label: 'Date terminated',
          value: checkTernaryCondition(
            item.data.cropTerminateDate?.toString().length > 0,
            moment(item.data.cropTerminateDate).format('MM/DD/YYYY'),
            '',
          ),
          id: item.id,
        },
      ],
    );
    return labelValuePairs;
  });
  return {
    finalData: finalData,
    modalType: 'Cover crop',
    secondaryLabel: secondaryLabel,
    itemId: itemId,
  };
};
export const nitrogenList = (
  arrayList,
  fertilizerTypeDropDown,
  animalTypeDropdown,
  manureTypeDropdown,
  fertilizerApplicationMethod,
) => {
  let secondaryLabel = [];
  let itemId = [];
  const finalData = arrayList.map((item) => {
    let labelValuePairs = [];

    const isOrganic = item.data.fertilizerCategory === 'Organic';
    const formattedDates = item?.data?.datesApplied
      .map((date) => {
        return checkTernaryCondition(
          date?.toString().length > 0,
          moment(date).format('MM/DD/YYYY'),
          '',
        );
      })
      .join(', ');

    if (isOrganic) {
      labelValuePairs.push({
        label: 'Animal type',
        id: item.id,
        value:
          animalTypeDropdown.find((type) => type.value === item.data.animalType)
            ?.label || '',
      });
      labelValuePairs.push({
        label: 'Manure type',
        id: item.id,
        value:
          manureTypeDropdown.find((type) => type.value === item.data.manureType)
            ?.label || '',
      });
    }

    labelValuePairs.push({
      label: 'Dates applied',
      id: item.id,
      value: formattedDates,
    });
    labelValuePairs.push({
      label: 'Contains inhibitor',
      id: item.id,
      value: item.data.hasInhibitor,
    });

    labelValuePairs.push({
      label: 'Application method',
      id: item.id,
      value:
        fertilizerApplicationMethod.find(
          (method) => method.value === item.data.applicationMethod,
        )?.label || '',
    });

    labelValuePairs.push({
      label: 'Application rate',
      id: item.id,
      value: item.data.applicationRate,
      unit: item.data.unitOfMeasure,
    });

    if (isOrganic) {
      if (item.data.nPercentage !== '') {
        labelValuePairs.push({
          label: 'N rate',
          id: item.id,
          value: item.data.nPercentage,
          unit: item.data.unitOfMeasure,
        });
      }
      if (item.data.pPercentage !== '') {
        labelValuePairs.push({
          label: 'P rate',
          id: item.id,
          value: item.data.pPercentage,
          unit: item.data.unitOfMeasure,
        });
      }
    } else {
      labelValuePairs.push({
        label: 'N rate',
        id: item.id,
        value: item.data.nPercentage,
        unit: item.data.unitOfMeasure,
      });
      labelValuePairs.push({
        label: 'P rate',
        id: item.id,
        value: item.data.pPercentage,
        unit: item.data.unitOfMeasure,
      });
      labelValuePairs.push({
        label: 'K rate',
        id: item.id,
        value: item.data.kPercentage,
        unit: item.data.unitOfMeasure,
      });
    }

    secondaryLabel.push(
      fertilizerTypeDropDown.find(
        (type) => type.value === item?.data?.fertilizerType,
      )?.label,
    );
    itemId.push(item.id);
    return labelValuePairs;
  });

  return {
    finalData: finalData,
    modalType: 'Fertilizer',
    secondaryLabel: secondaryLabel,
    itemId: itemId,
  };
};
export const soilAmendmentList = (arrayList, soilAmendmentsDropdown) => {
  let secondaryLabel = [];
  let itemId = [];
  const finalData = arrayList.map((item) => {
    secondaryLabel.push(
      soilAmendmentsDropdown.find(
        (type) => type.value === item?.data?.soilAmendmentsType,
      )?.label,
    );
    itemId.push(item.id);
    return [
      {
        label: 'Amount applied',
        value: item?.data?.amountApplied,
        unit: item?.data?.unitOfMeasure,
        id: item.id,
      },
      {
        label: 'isImplementedInActivities',
        value: item?.data?.addedFromImplementedActivities,
        id: item.id,
      },
    ];
  });
  return {
    finalData: finalData,
    modalType: 'Soil amendments',
    secondaryLabel: secondaryLabel,
    itemId: itemId,
  };
};
export const tillageTypeDtoList = (arrayList, tillageTypeDropdown) => {
  let secondaryLabel = [];
  let itemId = [];

  const finalData = arrayList.map((item) => {
    let labelValue;
    const foundType = tillageTypeDropdown.find(
      (type) => type.value === item?.data?.tillagePractice,
    );
    if (foundType) {
      labelValue = foundType.label;
      if (labelValue === 'Other') {
        labelValue = item?.data?.customTillagePractice;
      }
    }
    secondaryLabel.push(labelValue);
    itemId.push(item.id);

    const formattedDates = item?.data?.tillageDates
      .map((date) => {
        return checkTernaryCondition(
          date?.toString().length > 0,
          moment(date).format('MM/DD/YYYY'),
          '',
        );
      })
      .join(', ');

    const newData = [
      {
        label: 'Tillage date(s)',
        value: formattedDates,
        id: item.id,
      },
      {
        label: 'isImplementedInActivities',
        value: item?.data?.addedFromImplementedActivities || false,
      },
    ];
    if (item?.data?.surfaceDisturbance) {
      const surfaceDisturbance = {
        label: 'Surface Disturbance',
        value: item?.data?.surfaceDisturbance,
        unit: '%',
        id: item.id,
      };

      newData.push(surfaceDisturbance);
    }
    if (item?.data?.depthOfTillage) {
      const depthTillage = {
        label: 'Depth of tillage',
        value: item?.data?.depthOfTillage,
        unit: 'in',
        id: item.id,
      };
      newData.push(depthTillage);
    }
    return newData;
  });
  return {
    finalData: finalData,
    modalType: 'Tillage',
    secondaryLabel: secondaryLabel,
    itemId: itemId,
  };
};
export const residueManagementTypeList = (arrayList, dropDownList) => {
  let secondaryLabel = [];
  let itemId = [];
  const finalData = arrayList.map((item) => {
    secondaryLabel?.push(
      dropDownList?.find(
        (type) => type.value === item?.data?.agricultureResiduesManaged,
      )?.label,
    );
    itemId.push(item.id);
    return [
      {
        label: 'Crop residues removed',
        value: item?.data?.cropResidueRemoved,
        unit: '%',
        id: item?.data?.id,
      },
      {
        label: 'isImplementedInActivities',
        value: item?.data?.isImplementedInActivities || false,
        id: item?.data?.id,
      },
      ...optionalFields(
        item?.data?.agricultureResiduesManaged,
        item.data,
        dropDownList,
      ),
    ];
  });
  return {
    finalData: finalData,
    modalType: 'Residue management',
    secondaryLabel: secondaryLabel,
    itemId: itemId,
  };
};

const getUOMValue = (uomValue) => {
  return uomValue == undefined || uomValue?.startsWith('null')
    ? '--'
    : checkTernaryCondition(
        uomValue?.[uomValue?.length - 1] === '/',
        uomValue?.substring(0, uomValue?.length - 1),
        uomValue,
      );
};

export const cropList = (
  arrayList,
  practiceModalsData,
  practiceModalDropdowns,
) => {
  let itemId = null;
  const finalData = arrayList.map((item) => {
    itemId = item.id;
    return [
      {
        label: 'isImplementedInActivities',
        value: item?.addedFromImplementedActivities,
        id: item.id,
      },
      {
        label: 'Crop area',
        value: item.cropArea,
        unit: 'acres',
        id: item.id,
      },
      {
        label: 'Planned management type',
        value: checkTernaryCondition(
          isValueEmptyNullorUndefined(item.managementType?.toString()),
          '--',
          item.managementType,
        ),
        id: item.id,
      },
      {
        label: 'Annual yield',
        value: checkTernaryCondition(
          isValueEmptyNullorUndefined(item.annualYield?.toString()),
          '--',
          item.annualYield,
        ),
        unit: checkTernaryCondition(
          isValueEmptyNullorUndefined(item.annualYield?.toString()),
          '',
          getUOMValue(item.annualYieldUom),
        ),
        id: item.id,
      },
      {
        label: 'Crop value ($)',
        value: checkTernaryCondition(
          isValueEmptyNullorUndefined(item.cropValue?.toString()),
          '--',
          item.cropValue,
        ),
        unit: checkTernaryCondition(
          isValueEmptyNullorUndefined(item.cropValue?.toString()) ||
            isValueEmptyNullorUndefined(item.annualYield?.toString()),
          '',
          checkTernaryCondition(
            item?.annualYieldUom?.[item?.annualYieldUom?.length - 1] === '/',
            item?.annualYieldUom?.substring(
              0,
              item?.annualYieldUom?.length - 1,
            ),
            `${'per'} ${item?.annualYieldUom}`,
          ).split('/')[0],
        ),
        id: item.id,
      },
      {
        label: 'Date planted',
        value: checkTernaryCondition(
          item.cropPlantDate?.toString().length > 0,
          moment(item.cropPlantDate).format('MM/DD/YYYY'),
          '',
        ),
        id: item.id,
      },
      {
        label: 'Date terminated',
        value: checkTernaryCondition(
          item.cropTerminationDate?.toString().length > 0,
          moment(item.cropTerminationDate).format('MM/DD/YYYY'),
          '',
        ),
        id: item.id,
      },
      {
        label: 'Planting density',
        value: checkTernaryCondition(
          isValueEmptyNullorUndefined(item.plantingDensity?.toString()),
          '--',
          item.plantingDensity,
        ),
        unit: item.plantingDensityUom,
        id: item.id,
      },
      {
        label: 'Seeding depth',
        value: checkTernaryCondition(
          isValueEmptyNullorUndefined(item.cropDepth?.toString()),
          '--',
          item.cropDepth,
        ),
        unit: item.cropDepthUom,
        id: item.id,
      },
      {
        label: 'Tillage system',
        value: checkTernaryCondition(
          isValueEmptyNullorUndefined(item.tillageSystem?.toString()),
          '--',
          item.tillageSystem,
        ),
        id: item.id,
      },
      {
        label: 'Tillage practice',
        value: checkTernaryCondition(
          isValueEmptyNullorUndefined(item.tillageSystemOther?.toString()),
          '--',
          item.tillageSystemOther,
        ),
        id: item.id,
      },
      {
        label: 'Disturbance type',
        value: checkTernaryCondition(
          isValueEmptyNullorUndefined(item.disturbanceType?.toString()),
          '--',
          item.disturbanceType,
        ),
        id: item.id,
      },
    ];
  });
  return {
    finalData: finalData,
    modalType: 'Crop',
    secondaryLabel: [],
    practicesModals: [
      {
        irrigationTypeList: irrigationTypeList(
          practiceModalsData?.irrigationTypeList.filter(
            (obj) => obj.parentId === itemId,
          ),
          practiceModalDropdowns?.irrigationMethodDropDown,
        ),
      },
      {
        nitrogenList: nitrogenList(
          practiceModalsData?.nitrogenList.filter(
            (obj) => obj.parentId === itemId,
          ),
          practiceModalDropdowns?.fertilizerTypeDropDown,
          practiceModalDropdowns?.animalTypeDropdown,
          practiceModalDropdowns?.manureTypeDropdown,
          practiceModalDropdowns?.fertilizerApplicationMethod,
        ),
      },
      {
        coverCropList: coverCropList(
          practiceModalsData?.coverCropList.filter(
            (obj) => obj.parentId === itemId,
          ),
          practiceModalDropdowns?.coverCropTypeDropdown,
          practiceModalDropdowns?.plannedUsage,
          practiceModalDropdowns?.terminationType,
        ),
      },
      {
        tillageTypeDtoList: tillageTypeDtoList(
          practiceModalsData?.tillageTypeDtoList.filter(
            (obj) => obj.parentId === itemId,
          ),
          practiceModalDropdowns?.tillageTypeDropdown,
        ),
      },
      {
        residueManagementTypeList: residueManagementTypeList(
          practiceModalsData?.residueManagementTypeList.filter(
            (obj) => obj.parentId === itemId,
          ),
          practiceModalDropdowns?.residueTypeDropdown,
        ),
      },
      {
        soilAmendmentList: soilAmendmentList(
          practiceModalsData?.soilAmendmentList.filter(
            (obj) => obj.parentId === itemId,
          ),
          practiceModalDropdowns?.soilAmendmentsDropdown,
        ),
      },
    ],
  };
};

const optionalFields = (id, item, dropDownList) => {
  if (
    ['Contour Buffer Strips', 'Field Strips'].includes(
      dropDownList?.filter((item) => item.value === id)[0]?.label,
    )
  ) {
    return [
      {
        label: 'Strip width',
        value: item.stripWidth,
        unit: 'ft',
        id: item.id,
      },
    ];
  } else if (
    ['Herbaceous Wind Barriers'].includes(
      dropDownList?.filter((item) => item.value === id)[0]?.label,
    )
  ) {
    return [
      {
        label: 'Barrier width',
        value: item.barrierWidth,
        unit: 'ft',
        id: item.id,
      },
      {
        label: 'No. of rows',
        value: item.rowCount,
        unit: '',
        id: item.id,
      },
    ];
  } else if (
    ['Vegetative Barriers']?.includes(
      dropDownList?.filter((item) => item.value === id)[0]?.label,
    )
  ) {
    return [
      {
        label: 'Barrier width',
        value: item.barrierWidth,
        unit: 'ft',
        id: item.id,
      },
    ];
  } else if (
    ['Riparian Forest Buffers'].includes(
      dropDownList.filter((item) => item.value === id)[0]?.label,
    )
  ) {
    return [
      {
        label: 'Species Density',
        value: item.speciesDensity,
        unit: 'trees planted per acre',
        id: item.id,
      },
    ];
  } else {
    return [];
  }
};

export const getProductionPurposeValue = (productionPurpose) => {
  if (
    productionPurpose === BASELINE_MODAL_CONTENT.cash_crop ||
    productionPurpose === BASELINE_MODAL_CONTENT.cover_crop ||
    productionPurpose === BASELINE_MODAL_CONTENT.fallow
  ) {
    return productionPurpose;
  }
  if (productionPurpose === BASELINE_MODAL_CONTENT.cash_crop_value) {
    return BASELINE_MODAL_CONTENT.cash_crop;
  }
  if (productionPurpose === BASELINE_MODAL_CONTENT.cover_crop_value) {
    return BASELINE_MODAL_CONTENT.cover_crop;
  }
  if (productionPurpose === BASELINE_MODAL_CONTENT.fallow_crop_value) {
    return BASELINE_MODAL_CONTENT.fallow;
  }
  if (
    productionPurpose?.toLowerCase() ===
    BASELINE_MODAL_CONTENT.cash_crop.toLowerCase()
  ) {
    return BASELINE_MODAL_CONTENT.cash_crop;
  }
  if (
    productionPurpose?.toLowerCase() ===
    BASELINE_MODAL_CONTENT.cover_crop.toLowerCase()
  ) {
    return BASELINE_MODAL_CONTENT.cover_crop;
  }
};

export const ActivityReportingMappingErrorMessage = ({
  activityReportingMappingErrorMessage,
}) => {
  return (
    <div>
      {activityReportingMappingErrorMessage && (
        <ErrorBox>
          <ContentBox width="95%">
            <InfoTooltip />
            <TextWrapper color={DARK_CHARCOAL}>
              {activityReportingMappingErrorMessage}
            </TextWrapper>
          </ContentBox>
          <ContentBox
            width="5%"
            content="flex-end"
            cursor="pointer"
            data-testid="closeWarningIcon"></ContentBox>
        </ErrorBox>
      )}
    </div>
  );
};

export const HeadersComp = (
  year,
  type,
  isInFarm,
  activityReportingMappingErrorMessage,
) => {
  return (
    <>
      <MainContainer
        color={DARK_CHARCOAL}
        padding="0rem 0rem 1rem 0rem"
        direction="row"
        gap="0.25rem">
        <TextWrapper fontSize="14px" color={DARK_CHARCOAL}>
          {checkTernaryCondition(
            type === BASELINE_MODAL_CONTENT.empty_farm_label,
            `${BASELINE_MODAL_CONTENT.activity_farm_heading}${year}.`,
            `${BASELINE_MODAL_CONTENT.activity_field_heading}${year}.`,
          )}
        </TextWrapper>
        {isInFarm && (
          <TextWrapper fontSize="0.75rem" color={COOL_GRAY}>
            &nbsp;{GLOBAL_STRING_CONSTANTS.OPTIONAL_TEXT}
          </TextWrapper>
        )}
      </MainContainer>
      <ActivityReportingMappingErrorMessage
        activityReportingMappingErrorMessage={
          activityReportingMappingErrorMessage
        }
      />
    </>
  );
};

HeadersComp.propTypes = {
  year: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isInFarm: PropTypes.bool.isRequired,
  activityReportingMappingErrorMessage: PropTypes.string,
};

cropList.propTypes = {
  arrayList: PropTypes.array.isRequired,
  practiceModalsData: PropTypes.object.isRequired,
  practiceModalDropdowns: PropTypes.object.isRequired,
};

// edgeOfFiledList.propTypes = {
//   arrayList: PropTypes.array.isRequired,
//   dropDownList: PropTypes.array.isRequired,
//   speciesCategory: PropTypes.array.isRequired,
// };

ActivityReportingMappingErrorMessage.propTypes = {
  activityReportingMappingErrorMessage: PropTypes.string,
};
