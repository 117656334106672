import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FETCH_PROJECT_LIST,
  FETCH_VIEW_ORIGINATION_PROJECT,
  GET_LAST_ACCESSED_PROJECT,
} from 'urls';
import axios from 'axios';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import { ERROR_MSGS } from 'utils/config';
import useDebounce from 'hooks/useDebounce';

export const sidenavContext = createContext({});
export const SideNavProvider = ({ children }) => {
  const [tab, setTab] = useState({ module: '', tab: '' });

  const [projectQueryOptions, setProjectQueryOptions] = useState({
    pageNumber: 0,
    pageSize: 10,
    commercialPurpose: [],
    projectName: '',
    sortFilter: { column: 'projectName', order: 'ASC' },
  });

  const [currentProject, setCurrentProject] = useState({
    projectId: null,
    projectName: null,
  });

  const [projectList, setProjectList] = useState([]);
  const [loaders, setLoaders] = useState({
    projectList: false,
    lastAccessedProject: false,
    // Add more as needed
  });

  const toggleProjectListLoader = () => {
    setLoaders((old) => ({
      ...old,
      projectList: !old.projectList,
    }));
  };

  const getUniqueProjectList = (oldList, newList) => {
    const existingProjectIds = new Set(
      Array.from(oldList, (project) => project.id),
    );

    const uniqueProjects = newList.filter(
      (project) => !existingProjectIds.has(project.id),
    );

    return [...oldList, ...uniqueProjects];
  };

  // Note: This fetch is used when user scrolls to the bottom of project list dropdown(paginated fetching)
  const fetchProjectsOnScrollDown = (queryOptions) => {
    toggleProjectListLoader();
    axios
      .post(FETCH_PROJECT_LIST, queryOptions)
      .then((response) => {
        const newList = response.data.content;
        const updatedList = getUniqueProjectList(projectList, newList);
        setProjectList(updatedList);
      })
      .catch(() => {
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(toggleProjectListLoader);
  };

  const debouncedFetchOnScroll = useDebounce(fetchProjectsOnScrollDown, 200);

  const handleProjectFetchOnScroll = () => {
    const newQueryOptions = {
      ...projectQueryOptions,
      pageNumber: projectQueryOptions.pageNumber + 1,
    };
    setProjectQueryOptions(newQueryOptions);
    debouncedFetchOnScroll(newQueryOptions);
  };

  // Note: Whole project list is replaced during search since we want only projects with the search text
  const updateProjectsOnSearch = (queryOptions) => {
    toggleProjectListLoader();
    axios
      .post(FETCH_PROJECT_LIST, queryOptions)
      .then((response) => {
        const newList = response.data.content;
        setProjectList(newList);
      })
      .catch(() => {
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(toggleProjectListLoader);
  };

  const debouncedFetchOnSearch = useDebounce(updateProjectsOnSearch, 1000);

  const clearSearchText = () => {
    const newQueryOptions = {
      pageNumber: 0,
      pageSize: 10,
      commercialPurpose: [],
      projectName: '',
      sortFilter: { column: 'projectName', order: 'ASC' },
    };
    setProjectQueryOptions(newQueryOptions);
    updateProjectsOnSearch(newQueryOptions); // We dont want to debounce this since we want to clear the search text immediately
  };

  const handleProjectSearch = (searchText) => {
    if (searchText === '') {
      clearSearchText(); // This wont be debounced
      return;
    }

    const newQueryOptions = {
      pageNumber: 0,
      pageSize: 10,
      commercialPurpose: [],
      projectName: searchText,
      sortFilter: { column: 'projectName', order: 'ASC' },
    };
    setProjectQueryOptions(newQueryOptions);
    debouncedFetchOnSearch(newQueryOptions);
  };

  const loadLastAccessedProject = () => {
    toggleProjectListLoader();
    Promise.all([
      axios.post(FETCH_PROJECT_LIST, projectQueryOptions),
      axios.get(GET_LAST_ACCESSED_PROJECT),
    ])
      .then(([projectListResponse, lastAccessedProjectResponse]) => {
        const { internalProjectId, projectName } =
          lastAccessedProjectResponse.data;

        if (internalProjectId && projectName) {
          setCurrentProject({ projectId: internalProjectId, projectName });
        } else {
          setCurrentProject({
            projectId: projectListResponse.data.content[0].id,
            projectName: projectListResponse.data.content[0].projectName,
          });
        }

        setProjectList(projectListResponse.data.content);
      })
      .catch(() => {
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(toggleProjectListLoader);
  };

  const handleUserOnProjectPage = async (projectId) => {
    try {
      const projectResp = await axios.get(
        FETCH_VIEW_ORIGINATION_PROJECT + `/${projectId}`,
      );
      const projectData = projectResp.data;

      fetchProjectsOnScrollDown(projectQueryOptions);
      setCurrentProject({
        projectId,
        projectName: projectData.projectName,
      });
    } catch (err) {
      loadLastAccessedProject();
    }
  };

  const value = useMemo(
    () => ({
      tab,
      setTab,
      projectQueryOptions,
      setProjectQueryOptions,
      currentProject,
      setCurrentProject,
      projectList,
      handleProjectSearch,
      handleProjectFetchOnScroll,
      loaders,
      clearSearchText,
      handleUserOnProjectPage,
      loadLastAccessedProject,
    }),
    [tab, projectQueryOptions, currentProject, projectList, loaders],
  );
  return (
    <sidenavContext.Provider value={value}>{children}</sidenavContext.Provider>
  );
};

SideNavProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
