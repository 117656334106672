import styled from 'styled-components';
import {
  DARK_CHARCOAL,
  DARK_GREEN,
  NEW_DARK_GREEN,
  NEW_HOVER_DARK_GREEN,
  TEAL_GREEN_FOCUS,
} from 'theme/GlobalColors';

export const InputWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 16px;
`;
export const tertiaryButtonStyle = {
  fontWeight: '400',
  textTransform: 'none',
  color: NEW_DARK_GREEN,
  '&:hover': {
    color: NEW_HOVER_DARK_GREEN,
  },
  '&:focus': {
    color: TEAL_GREEN_FOCUS,
  },
};
export const ButtonStyle = {
  fontWeight: '400',

  textTransform: 'none',
  color: 'white',
  background: DARK_GREEN,
  '&:hover': {
    background: DARK_GREEN,
    color: 'white',
  },
  padding: '0.625rem 1rem',
  height: '2.25rem',
};

export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '34.5rem',
      maxWidth: '34.5rem',
      padding: '1.5rem 1.5rem 1rem 1.5rem',
      maxHeight: '39.3125rem',
      overflowY: 'auto',
    },
  },
};
export const DialogTitleStyle = {
  fontSize: '20px',
  padding: '0rem',
  color: DARK_CHARCOAL,
  fontWeight: 400,
};
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 1.75rem;
`;
export const DialogSubTitle = styled.div`
  font-family: Open Sans;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1919rem;
  text-align: left;
  margin-top: 1rem;
  color: ${DARK_CHARCOAL};
`;
export const DialogContentStyle = {
  marginTop: '1rem',
  padding: '0rem',
  overflowX: 'hidden',
};
export const DialogActionStyle = {
  justifyContent: 'flex-end',
  padding: '1.5rem 0rem 0rem 0rem',
  marginBottom: '0.5rem',
  gap: '1rem',
};
