export const MIDNIGHT_GREEN = '#17585E';
export const GREEN = '#26890D';
export const BLUE = '#0076A8';
export const RED = '#DA291C';
export const COOL_GRAY = '#63666A';
export const BRIGHT_GREEN = '#228A6C';
export const BLACK = '#333333';
export const BLUISH_WHITE = '#E8EEEF';
export const BORDER_GRAY_COLOR = '#E0E0E0';
export const WHITE = '#FFFFFF';
export const GRAY = 'rgba(0, 0, 0, 0.12)';
export const GRAYISH_BLACK = 'rgba(0, 0, 0, 0.26)';
export const QUILL_GRAY = '#D0D0CE';
export const DARK_CHARCOAL = '#333333';
export const GRAYISH_BLUE = '#97999B';
export const PAGE_BACKGROUND_COLOR = '#F1f1f1';
export const DIM_GRAY = '#53565A';
export const ACCESSIBLE_BLUE_URLS = '#007CB0';
export const TYPOGRAPHY_GRAY = '#A7A8AA';
export const SILVER = '#898b8d';
export const CYAN_BLUE = '#56899A';
export const NEUTRAL_BACKGROUND_COLOR = '#F5F5F5';
export const ACTIVE_GREEN = '#86BC25';
export const DARK_GREEN = '#17585E';
export const LIGHT_GREEN = '#AAC4CC';
export const AQUA_MARINE = '#045206';
export const NEUTRAL_GRAY = 'A5ABB3';
export const BRIGHT_GRAY = '#EBEEF2';
export const COOL_BLACK = '#21323A';
export const DARK_BLUE = '#00597F';
export const DIM_BLUE_BACKGROUND = '#F1F6F8';
export const LIGHT_BLUE_BACKGROUND = '#E3EDF2';
export const HOVER_DARK_GREEN = '#103E43';
export const FOCUS_DARK_GREEN = '#0D3034';
export const PALE_GREEN = '#B2F2E2';
export const FLUORESCENT_GREEN = '#06D6A0';
export const LIGHT_RED = '#EFBDC1';
export const BRIGHT_RED = '#CC2936';
export const LIGHT_CHARCOAL = '#313233';
export const HOVER_GREEN_BACKGROUND = '#B7CBCD';
export const FOCUS_GREEN_BACKGROUND = '#648F93';
export const FOCUS_GREEN_BORDER = '#94B2B5';
export const DARK_YELLOW = '#FFD500';
export const LIGHT_YELLOW = '#FFF2B0';
export const LIGHT_BLACK = '#0A2527';
export const BERLIN_BLUE = '#00344A';
export const INPUT_ERROR_RED = '#D32F2F';
export const DOWNLOAD_LINK = '#0071A0';
export const ACTIVE_TAG_BG = '#B0C8CC';
export const FOCUSED_GREEN = '#A1C4D3';
export const BLURRED_DARK_GREEN = '#17585E40';
export const LIGHT_GRAY = '#bdbdbd';
export const DARK_MODERATE_BLUE = '#556A9B';
export const CURIOUS_BLUE = '#3396C0';
// Note: This blue is used for all links, tabbers and breadcrumbs
export const GLOBAL_BLUE = '#0071A0';
export const GLOBAL_BLUE_HOVER = '#00597F';
export const RED_BACKGROUND = '#DA291C1A';
export const BLUISH_CYAN = '#0D8390';
export const DARK_BLUISH_CYAN = '#095D66';
export const FADED_BLUISH_CYAN = '#548990';
export const LIGHT_BLUISH_CYAN = '#E6EDEE';
export const ORANGE = '#ED8B00';
//Deloitte updated color palette
export const NEW_DARK_GREEN = '#0D8390';
export const BLURRED_NEW_DARK_GREEN = '#0d839080';
export const NEW_HOVER_DARK_GREEN = '#095D66';
export const NEW_FOCUS_DARK_GREEN = '#07484F';
export const FOCUSED_BORDER = '#8AAEB3';
export const NEW_HOVER_GREEN_BACKGROUND = '#E7F3F4';
export const NEW_FOCUS_GREEN_BACKGROUND = '#B0C8CC';
export const TEAL_GREEN_FOCUS = '#05373C';
export const NEW_TEAL_GREEN_FOCUS = '#5DACB5';
export const TEAL_GREEN_HOVER = '#B4D9DD';
export const BLURRED_TEAL_GREEN_HOVER = '#B4D9DD80';
export const DARK_GRAY = '#D9D9D9';
export const TABS_HIGHLIGHT_BORDER = '#004F59';
export const ROW_HOVER_BG = '#F2F8FA';
export const ROW_FOCUSED_BG = '#E6F2F7';
export const TOAST_SUCCESS = '#B0E0C5';
export const TOAST_SUCCESS_BORDER = '#009A44';
export const TOAST_ERROR_BG = '#F4BDB9';
export const TOAST_WARNING_BG = '#FFF0B0';
export const TOAST_WARNING_BORDER = '#FFCD00';
export const ACTIVE_TAG_TEXT_COLOR = '#002125';
export const TEXT_HOVER_COLOR = '#00587D';
export const SEA_GREEN = '#228A6C';
export const NATURAL_GRAY = '#898B8D';
export const HOVER_LIGHT_BLUE = 'rgba(230, 242, 247, 0.5)';
export const DISABLED_TEXT_COLOR = '#9E9E9E';
export const EMPTY_STATE_COLOR = '#E8EEEF';
export const EMPTY_STATE_TEXT_COLOR = '#63666A';
export const RED_HOVER = '#9B1D14';
export const RED_FOCUS = '#78170F';
export const RED_FOCUSED_BORDER = '#EE9D97';
export const DISABLED_TEXT_COLOR_GRAY = '#D3D3D3';
export const IMAGE_REVIEW_COLOR = '#3F4041';
export const LANDING_CARD_HOVER_BLUE = ' #E7F3F4';
export const LANDING_CARD_FOCUSED_BLUE = '#B4D9DD';
export const WARNING_BOX_YELLOW = '#FFCD00';
export const LIGHT_GRAY_SELECTED = '#F6F6F6';
export const SHADOW_COLOR = '#0000001A';
export const QUARTZ = '#4B4B4B';
export const FARM_FIELD_LABEL_COLOR = '#1F1F1F';
export const FORM_BG_BORDER = '#97999B';
export const FORM_BOTTOM_BORDER = '#D0D0CE';
export const DARK_BLACK_80_PERCENT = '#000000CC';
export const SHEPRA_BLUE = '#004F59';
export const DARK_BLACK = '#000000';
export const PROJECTED_BAR_GRAY = '#747676';
export const PROJECTED_DASHED_GRAY = '#AEB0B5';
export const DARK_BLACK_60_PERCENT = '#00000099';
export const X_AXIS_LINE_GRAY = '#0000001F';
export const GREY_BORDER = '#DDDDDD';
export const LIGHTISH_GREY = '#75787B';
export const BRIGHTER_RED = '#D13A27';
export const BRIGHTER_BLUE = '#0171A0';

// Graph colors
export const GHG_GRAPH_COLORS = {
  PROJECTED_REMOVALS: '#747676',
  PROJECTED_REDUCTIONS: '#AEB0B5',
  ACTUAL_REMOVALS: NEW_DARK_GREEN,
  ACTUAL_REDUCTIONS: '#6FC2B4',
  LINE_PLOT: QUILL_GRAY,
};
