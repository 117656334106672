import React from 'react';
import {
  ButtonWrapper,
  continueBtnSx,
  SaveAndContinueBtnWrapper,
  saveBtnSx,
} from './UserNavigationButtonsV2.style';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { pathName } from 'Routes/routes.const';
import { checkTernaryCondition } from 'utils/helper';
import { BUTTON_NAMES } from './UserNavigationButtonV2.content';

// Note: Using V2(version 2) to differentiate from the original UserNavigationButton component
const UserNavigationButtonsV2 = ({
  handleSave,
  handleContinue,
  handleSubmitAllFarms,
  disabledSave = false,
  disabledContinue = false,
  disableSubmitAllFarms = false,
  enableImplicitHandlerForSubmitAllFormsButton = false,
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const navigateHandlerForSubmitAndContinueButton = () => {
    const projectIdValue = searchParams.get('projectid');
    const participantIdValue = searchParams.get('participantid');
    const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
    const projectCycleId = searchParams.get('projectcycleid');

    navigate(
      {
        pathname: pathName.participant.menuPage,
        search: createSearchParams({
          projectid: projectIdValue,
          participantid: participantIdValue,
          enrollmentInstanceId: enrollmentInstanceId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: true } },
    );
  };
  return (
    <ButtonWrapper>
      <SaveAndContinueBtnWrapper>
        <Button
          variant="outlined"
          data-testId="saveBtn"
          size="medium"
          color="success"
          sx={saveBtnSx}
          onClick={(e) => {
            e.target.blur();
            handleSave();
          }}
          disabled={disabledSave}
          disableElevation>
          {BUTTON_NAMES.SAVE}
        </Button>
        <Button
          variant="contained"
          data-testId="continueBtn"
          size="medium"
          color="success"
          sx={continueBtnSx}
          onClick={handleContinue}
          disabled={disabledContinue}
          disableElevation>
          {BUTTON_NAMES.SAVE_AND_CONTINUE}
        </Button>
      </SaveAndContinueBtnWrapper>
      <Button
        variant="contained"
        data-testId="continueBtn"
        size="medium"
        color="success"
        sx={continueBtnSx}
        onClick={checkTernaryCondition(
          enableImplicitHandlerForSubmitAllFormsButton,
          navigateHandlerForSubmitAndContinueButton,
          handleSubmitAllFarms,
        )}
        disabled={disableSubmitAllFarms}
        disableElevation>
        {BUTTON_NAMES.SUBMIT_ALL_FARMS}
      </Button>
    </ButtonWrapper>
  );
};

// proptypes
UserNavigationButtonsV2.propTypes = {
  handleSave: PropTypes.func,
  handleContinue: PropTypes.func,
  disabledSave: PropTypes.bool,
  disabledContinue: PropTypes.bool,
  handleSubmitAllFarms: PropTypes.func,
  disableSubmitAllFarms: PropTypes.bool,
  enableImplicitHandlerForSubmitAllFormsButton: PropTypes.bool,
};

export default UserNavigationButtonsV2;
