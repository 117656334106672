import { communicationMethodList } from '../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';

export const FORM_CONTENT = {
  add_participant_contributors_label: 'Add participant contributors',
  add_participant_contributor_label: 'Add participant contributor',
  edit_participant_contributor_label: 'Edit participant contributor',
  edit_participant_contributor_label_save_text: 'Save',
  add_participant_contributors_instruction_text:
    'Select an existing participant contributor or add a new participant contributor below. Individuals that are added as participant contributors to a farm for this project will have access to report on the specific farm that is enrolled in a project.',
  add_participant_contributors_placeholder:
    'Type and select name to add existing participant contributors',
  assign_to_farms_label: 'Assign to farm(s)',
  duplicate_email_error:
    'The email address {{email}} is already assigned to a different role. Please use a unique email address for each role.',
  assign_to_farms_placeholder: 'Select option',
  send_invite_btn_label: 'Send invite',
  add_participant_contributors_btn_label: 'Participant contributor',
  participant_contributor_section_heading: 'Participant contributor',
  aria_labelledby: 'scroll-dialog-title',
  aria_describedby: 'scroll-dialog-description',
  first_name_label: 'First Name',
  first_name_placeholder: 'Type first name',
  first_name_required_error: 'A first name is required.',
  last_name_label: 'Last Name',
  last_name_required_error: 'A last name is required.',
  last_name_placeholder: 'Type last name',
  email_label: 'Email',
  email_placeholder: 'Type email address',
  email_invalid_error: 'A valid email address is required.',
  email_invalid_domain_error:
    'An email address must follow a valid format. e.g., name@domain.com',
  email_already_exists_error:
    'The email address [email@example.com] is already assigned to a different role. Please use a unique email address for each role.',
  phone_label: 'Phone',
  phone_placeholder: 'Type phone number',
  phone_number_required_error: 'A valid phone number is required.',
  phone_invalid_error:
    'A phone number must follow a valid format. e.g., (555) 555-5555 (dashes and brackets not required).',
  communication_method_label: 'What’s your preferred communication method?',
  communication_method_label_error:
    'The preferred method of communication selection is required.',
  farm_and_project_assignment_error:
    'A participant contributor needs to be assigned to at least one project and an associated farm.',
  communication_method_helptext: 'Select all that apply',
  invite_sent_help_text: `You may adjust the farms assigned to this contributor, which will be reflected in the contributor's portal. They will also receive an additional email notification.`,
  invite_error_toast_message:
    'Invite failed to send due to an invalid email address. Please update and try again.',
  farm_assignment_required_error:
    'If a participant contributor(s) is added then they must be assigned to a farm to save information.',
  cancelBtn: 'Cancel',
  resend_invite_btn_label: 'Resend invite',
  DUPLICATE_CONTRIBUTOR_EMAIL_ERROR:
    'The mail id already exists, Please try again with a different mail id.',
  ADD_PARTICIPANT_CONTRIBUTOR_CONTENT:
    'Participant contributors added to a farm in this project will have access to report on the specific farm.',
  SUBMIT_LABEL: 'Submit',
};

export const validateEmail = (email) => {
  const regex = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})/;
  return email.match(regex);
};

export const NEW_CONTRIBUTOR_STATUS_VALUES = [
  'New Contributor',
  'New Contributor Invite Sent',
];

export const INITIAL_FORM_STATE = {
  participantContributors: [],
  participantContirbutorsCompleteValues: [],
  assignedFarms: [],
  assignedFarmsError: false,
};

export const ADD_CONTRIBUTORS_INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  communicationMethod: communicationMethodList,
  assignedFarms: [],
  status: null,
};

export const ERROR_INITIAL_STATE = {
  firstNameError: false,
  lastNameError: false,
  emailError: false,
  phoneError: false,
  invalidPhoneError: false,
  invalidEmailError: false,
  communicationMethodError: false,
  assignedFarmsError: false,
};
