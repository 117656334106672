import axios from 'axios';
import {
  baselineReOpen,
  baselineReview,
} from 'pages/Origination/Org_Project/Participants/BaselineReview';
import {
  enrollmentReOpen,
  enrollmentReview,
} from 'pages/Origination/Org_Project/Participants/EnrollmentReview';
import { getProjectAccountingApproachAndParticipantStatus } from 'pages/Origination/Org_Project/Participants/ListParticipants.functions';
import { getActionIdFromActionCode } from 'pages/Origination/Org_Project/Participants/Participants.content';
import React, { createContext } from 'react';
import {
  GENERATE_BASELINE_INSTANCE_ID,
  SEND_ACTIVITY_EMAIL,
  SEND_BASELINE_REQUEST_EMAIL,
  UPDATE_BASELINE_ACTVITY_STATUS,
  UPDATE_PARTICIPANT_ACTIVITY_STATUS,
} from 'urls';
import { INVENTORY_ACCOUNTING, USER_STATUS } from 'utils/config';
import { checkTernaryCondition } from 'utils/helper';
import PropTypes from 'prop-types';

const LIFE_CYCLE_STATUS_ACTIONS = {
  1: 'Interested',
  2: 'Contract - Added',
  3: 'Enrollment - Not started',
  '3a': 'Enrollment - In progress',
  '3b': 'Enrollment - In progress',
  4: 'Enrollment - In progress',
  '5a': 'Enrollment - Submitted',
  '5b': 'Enrollment - Submitted',
  6: 'Enrollment - In review',
  7: 'Enrollment - In review',
  '7a': 'Enrollment - In progress',
  '7b': 'Enrollment - In progress',
  8: 'Enrollment - Approved',
  9: 'Baseline Reporting - Not started',
  '9a': 'Baseline Reporting - In progress',
  '9b': 'Baseline Reporting - In progress',
  10: 'Baseline Reporting - In progress',
  '11a': 'Baseline Reporting - Submitted',
  '11b': 'Baseline Reporting - Submitted',
  12: 'Baseline Reporting - In review',
  13: 'Baseline Reporting - In review',
  '13a': 'Baseline Reporting - Requested',
  '13b': 'Baseline Reporting - In progress',
  14: 'Baseline Reporting - Approved',
};

/**
 * Caution!: Do not change these mapping unless you know what you are doing
 */
const ACTION_ENABLED = {
  Interested: [2],
  1: [2],
  2: [3],
  3: [5],
  4: [5],
  5: [5],
  6: [5],
  7: [5],
  8: [9],
  9: [9],
  10: [5],
  11: [5],
  12: [13],
  13: [13],
  14: [13],
  16: [13],
  17: [13],
  18: [18],
  19: [18],
  20: [13],
  21: [13],
  22: [22],
};

export const ADD_PARTICIPANT_TO_PROJECT = 1;
export const ADD_CONTRACT = 2;
export const CONTRACT_ADDED = 3;
export const SEND_REQUEST = 4;
export const ENROLL_ON_BEHALF = 5;
export const ENROLL_VIA_EMAIL_LINK = 4;
export const INITIATE_ENROLLMENT = 5;
export const ENROLLMENT_SUBMITTED = 6;
export const REVIEW_ENROLLMENT = 9;
export const REOPEN_ENROLLMENT_SEND_REQUEST = 10;
export const REOPEN_ENROLLMENT_REOPEN_ON_BEHALF = 11;
export const APPROVE_ENROLLMENT = 12;
export const INITIATE_BASELINE_REPORTING = 13;
export const BASELINE_REPORTING_SEND_REQUEST = 14;
export const BASELINE_REPORTING_REPORT_ON_BEHALF = 16;
export const BASELINE_REPORTING_CONTINUE = 17;
export const BASELINE_REPORTING_SUBMITTED = 18;
export const REVIEW_BASELINE_REPORTING = 19;
export const REOPEN_BASELINE_REPORTING_SEND_REQUEST = 20;
export const REOPEN_BASELINE_REPORTING_REOPEN_ON_BEHALF = 21;
export const APPROVE_BASELINE_REPORTING = 22;

export const LifeCycleContext = createContext({});
export const LifeCycleProvider = ({ children }) => {
  const dispatch = async (
    _status,
    _projectId,
    _participantId,
    instanceId = null,
  ) => {
    return Promise.resolve();
  };

  const updateParticipantStatus = ({
    contractId,
    projectId,
    participantId,
    instanceId,
    actionId,
    projectCycleId,
  }) => {
    return axios.post(UPDATE_PARTICIPANT_ACTIVITY_STATUS, {
      internalProjectId: +projectId,
      participantId: +participantId,
      contractId: +contractId,
      enrollmentInstanceId: +instanceId,
      actionId: +actionId,
      projectCycleId: +projectCycleId,
    });
  };

  const sendBaselineRequest = (projectId, participantId, projectCycleId) => {
    const body = {
      internalProjectId: projectId,
      participantId: participantId,
      projectCycleId: projectCycleId,
    };
    axios.post(SEND_BASELINE_REQUEST_EMAIL, body);
  };

  const sendActivityRequest = (projectId, participantId, projectCycleId) => {
    const body = {
      approvalDescription: '',
      emailFrom: '',
      enrollmentInstanceId: null,
      internalProjectId: projectId,
      participantId,
      projectCycleId,
    };
    axios.post(SEND_ACTIVITY_EMAIL, body);
  };

  const generateBaselineId = ({
    projectId,
    participantId,
    enrollmentType,
    projectCycleId,
  }) => {
    return axios.post(GENERATE_BASELINE_INSTANCE_ID, {
      internalProjectId: +projectId,
      participantId: +participantId,
      enrolmentType: enrollmentType,
      projectCycleId,
    });
  };

  const updateParticipantBaselineStatus = ({
    formId,
    actionId,
    projectCycleId,
  }) => {
    return axios.post(UPDATE_BASELINE_ACTVITY_STATUS, {
      formId: +formId,
      actionId: +actionId,
      projectCycleId,
    });
  };

  const addParticipantToProject = (projectId, participantId) =>
    dispatch(`1:${LIFE_CYCLE_STATUS_ACTIONS[1]}`, projectId, participantId);

  const addContract = (projectId, participantId) =>
    dispatch(`2:${LIFE_CYCLE_STATUS_ACTIONS[2]}`, projectId, participantId);

  const initiateEnrollment = (projectId, participantId) =>
    dispatch(`3:${LIFE_CYCLE_STATUS_ACTIONS[3]}`, projectId, participantId);

  const sendRequest = (projectId, participantId) =>
    dispatch(`3a:${LIFE_CYCLE_STATUS_ACTIONS['3a']}`, projectId, participantId);

  const enrollOnBehalf = (projectId, participantId) =>
    dispatch(`3b:${LIFE_CYCLE_STATUS_ACTIONS['3b']}`, projectId, participantId);

  const enrollViaEmailLink = (projectId, participantId) =>
    dispatch(`4:${LIFE_CYCLE_STATUS_ACTIONS[4]}`, projectId, participantId);

  const submitEnrollmentOnBehalf = (projectId, participantId) =>
    dispatch(`5a:${LIFE_CYCLE_STATUS_ACTIONS['5a']}`, projectId, participantId);

  const submitEnrollment = (projectId, participantId) =>
    dispatch(`5b:${LIFE_CYCLE_STATUS_ACTIONS['5b']}`, projectId, participantId);

  const reviewEnrollment = (projectId, participantId) =>
    dispatch(`6:${LIFE_CYCLE_STATUS_ACTIONS[6]}`, projectId, participantId);

  const reopenEnrollment = (projectId, participantId) =>
    dispatch(`7:${LIFE_CYCLE_STATUS_ACTIONS[7]}`, projectId, participantId);

  const reopenEnrollmentSendRequest = (projectId, participantId) =>
    dispatch(`7a:${LIFE_CYCLE_STATUS_ACTIONS['7a']}`, projectId, participantId);

  const reopenEnrollmentReopenOnBehalf = (projectId, participantId) =>
    dispatch(`7b:${LIFE_CYCLE_STATUS_ACTIONS['7b']}`, projectId, participantId);

  const approveEnrollment = (projectId, participantId, instanceId) => {
    return dispatch(
      `8:${LIFE_CYCLE_STATUS_ACTIONS[8]}`,
      projectId,
      participantId,
      instanceId,
    );
  };

  const isActionEnabled = (currentActionOfParticipant, actionToBeEnabled) => {
    if (currentActionOfParticipant == undefined) {
      currentActionOfParticipant = 1;
    }
    return ACTION_ENABLED[currentActionOfParticipant].includes(
      actionToBeEnabled,
    );
  };

  const callStatusChange = (
    callbackFunction,
    projectId,
    participantId,
    currentAction = 'NA',
    instanceId = null,
  ) => {
    if (
      callbackFunction === initiateBaselineEnrollment ||
      callbackFunction === initiateEnrollment
    ) {
      /** Only if the action is 2 or 8 they would be able to move to the next status
       * if the status has already passes ie in-progress then they would
       * not be able to move to the not started status
       */
      if (currentAction == 2 || currentAction == 8) {
        return callbackFunction(projectId, participantId).then((res) => res);
      } else {
        return;
      }
    }

    return callbackFunction(projectId, participantId, instanceId).then(
      (res) => res,
    );
  };

  const reOpenStatus = ({ title, isReopen, projectId, participantId }) => {
    if (title === enrollmentReOpen().title && isReopen) {
      callStatusChange(reopenEnrollmentSendRequest, projectId, participantId);
    } else if (title === enrollmentReOpen().title && !isReopen) {
      callStatusChange(
        reopenEnrollmentReopenOnBehalf,
        projectId,
        participantId,
      );
    } else if (title === baselineReOpen().title && isReopen) {
      callStatusChange(
        reopenBaselineReportingSendRequest,
        projectId,
        participantId,
      );
    } else if (title === baselineReOpen().title && !isReopen) {
      callStatusChange(
        reopenBaselineReportingReopenOnBehalf,
        projectId,
        participantId,
      );
    }
  };

  const initiateBaselineEnrollment = (projectId, participantId) =>
    dispatch(`9:${LIFE_CYCLE_STATUS_ACTIONS[9]}`, projectId, participantId);

  const baselineReportingSendRequest = (projectId, participantId) =>
    dispatch(`9a:${LIFE_CYCLE_STATUS_ACTIONS['9a']}`, projectId, participantId);

  const baselineReportingReportOnBehalf = (projectId, participantId) =>
    dispatch(`9b:${LIFE_CYCLE_STATUS_ACTIONS['9b']}`, projectId, participantId);

  const reportOnBaselineViaEmailLink = (projectId, participantId) =>
    dispatch(`10:${LIFE_CYCLE_STATUS_ACTIONS[10]}`, projectId, participantId);

  const submitBaselineReportingOnBehalf = (projectId, participantId) =>
    dispatch(
      `11a:${LIFE_CYCLE_STATUS_ACTIONS['11a']}`,
      projectId,
      participantId,
    );

  const submitBaselineReporting = (projectId, participantId) =>
    dispatch(
      `11b:${LIFE_CYCLE_STATUS_ACTIONS['11b']}`,
      projectId,
      participantId,
    );

  const reviewBaselineReporting = (projectId, participantId) =>
    dispatch(`12:${LIFE_CYCLE_STATUS_ACTIONS[12]}`, projectId, participantId);

  const reopenBaselineReporting = (projectId, participantId) =>
    dispatch(`13:${LIFE_CYCLE_STATUS_ACTIONS[13]}`, projectId, participantId);

  const reopenBaselineReportingSendRequest = (projectId, participantId) =>
    dispatch(
      `13a:${LIFE_CYCLE_STATUS_ACTIONS['13a']}`,
      projectId,
      participantId,
    );

  const reopenBaselineReportingReopenOnBehalf = (projectId, participantId) =>
    dispatch(
      `13b:${LIFE_CYCLE_STATUS_ACTIONS['13b']}`,
      projectId,
      participantId,
    );

  const approveBaselineReporting = (projectId, participantId) =>
    dispatch(`14:${LIFE_CYCLE_STATUS_ACTIONS[14]}`, projectId, participantId);

  const submitEnrollmentORBaseline = (
    isBaselineMenu,
    projectId,
    participantId,
  ) => {
    if (isBaselineMenu) {
      return callStatusChange(
        submitBaselineReporting,
        projectId,
        participantId,
      );
    } else {
      return callStatusChange(submitEnrollment, projectId, participantId);
    }
  };

  const reopenEnrollmentOrBaseline = ({ title, projectId, participantId }) => {
    if (title === enrollmentReview().title) {
      callStatusChange(reopenEnrollment, projectId, participantId);
    } else {
      callStatusChange(reopenBaselineReporting, projectId, participantId);
    }
  };

  /**
   *
   * @param {*} isParticipantStatusActive
   * @returns User Activity Action to perform based on the participant status
   */
  const doGetActivityActionBasedOnParticipantStatus = (
    isParticipantStatusActive,
  ) => {
    return checkTernaryCondition(
      isParticipantStatusActive,
      'ACTIVITY_SEND_REQ',
      'ACTIVITY_DEFAULT',
    );
  };

  const approveEnrollmentOrBaseline = ({
    title,
    projectId,
    participantId,
    instanceId = null,
    contractId,
    actionId,
    baselineActionId,
    formId,
    modalCloseCb,
    setReviewEnrollmentProps,
    setLoading,
    activityActionId,
    statusMappingList,
    projectCycleId,
    baselineReportingRowData,
    activityReportingRowData,
  }) => {
    setReviewEnrollmentProps((previous) => ({
      ...previous,
      modalData: {
        ...previous.modalData,
        disableSecondSubmitBtn: true,
      },
    }));
    if (title === enrollmentReview().title) {
      setLoading(true);
      return updateParticipantStatus({
        contractId: contractId,
        projectId: projectId,
        participantId: participantId,
        instanceId: instanceId,
        actionId: actionId,
        projectCycleId: projectCycleId,
      })
        .then(async () => {
          const accountingApproachAndParticipantStatus =
            await getProjectAccountingApproachAndParticipantStatus(
              projectId,
              participantId,
            );
          const isBaselineOptional =
            accountingApproachAndParticipantStatus.projectAccountingApproach ===
            INVENTORY_ACCOUNTING;
          const isParticipantStatusActive =
            accountingApproachAndParticipantStatus.participantStatus ===
            USER_STATUS.STATUS.ACTIVE;
          generateBaselineId({
            projectId: projectId,
            participantId: participantId,
            enrollmentType: 'Baseline',
            projectCycleId,
          }).then((response) => {
            const updatedFormId = response.data;
            isBaselineOptional &&
              generateBaselineId({
                projectId: projectId,
                participantId: participantId,
                enrollmentType: 'Activity',
                projectCycleId,
              }).then((response) => {
                /* istanbul ignore else */
                if (isParticipantStatusActive) {
                  sendBaselineRequest(projectId, participantId, projectCycleId);
                  sendActivityRequest(projectId, participantId, projectCycleId);
                }
                const updatedActivityFormId = response.data;
                activityReportingRowData?.formId !== updatedActivityFormId &&
                  updateParticipantBaselineStatus({
                    formId: updatedActivityFormId,
                    actionId: getActionIdFromActionCode(
                      doGetActivityActionBasedOnParticipantStatus(
                        isParticipantStatusActive,
                      ),
                      statusMappingList,
                    ),
                    projectCycleId,
                  });
              });
            baselineReportingRowData.formId !== updatedFormId &&
              updateParticipantBaselineStatus({
                formId: updatedFormId,
                actionId: getActionIdFromActionCode(
                  isParticipantStatusActive
                    ? 'BASELINE_SEND_REQ'
                    : 'BASELINE_DEFAULT',
                  statusMappingList,
                ),
                projectCycleId,
              });
          });
          modalCloseCb();
          setReviewEnrollmentProps((previous) => ({
            ...previous,
            modalData: {
              flag: false,
              disableSecondSubmitBtn: false,
            },
          }));
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (title === baselineReview().title) {
      setLoading(true);
      return updateParticipantBaselineStatus({
        formId: formId,
        actionId: baselineActionId,
        projectCycleId,
      })
        .then(async () => {
          const accountingApproachAndParticipantStatus =
            await getProjectAccountingApproachAndParticipantStatus(
              projectId,
            ).then((data) => data);
          const isBaselineOptional =
            accountingApproachAndParticipantStatus.projectAccountingApproach ===
            INVENTORY_ACCOUNTING;
          if (!isBaselineOptional) {
            generateBaselineId({
              projectId: projectId,
              participantId: participantId,
              enrollmentType: 'Activity',
              projectCycleId,
            }).then((response) => {
              const updatedFormId = response.data;
              activityReportingRowData.formId !== updatedFormId &&
                updateParticipantBaselineStatus({
                  formId: response.data,
                  actionId: getActionIdFromActionCode(
                    'ACTIVITY_DEFAULT',
                    statusMappingList,
                  ),
                  projectCycleId,
                });
            });
          }
          modalCloseCb();
          setReviewEnrollmentProps((previous) => ({
            ...previous,
            modalData: {
              flag: false,
              disableSecondSubmitBtn: false,
            },
          }));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // Activity reporting
      setLoading(true);
      return updateParticipantBaselineStatus({
        formId: formId,
        actionId: activityActionId,
        projectCycleId,
      })
        .then(() => {
          modalCloseCb();
          setReviewEnrollmentProps((previous) => ({
            ...previous,
            modalData: {
              flag: false,
              disableSecondSubmitBtn: false,
            },
          }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const value = React.useMemo(
    () => ({
      addParticipantToProject,
      addContract,
      initiateEnrollment,
      sendRequest,
      enrollOnBehalf,
      enrollViaEmailLink,
      submitEnrollmentOnBehalf,
      submitEnrollment,
      reviewEnrollment,
      reopenEnrollment,
      reopenEnrollmentSendRequest,
      reopenEnrollmentReopenOnBehalf,
      approveEnrollment,
      isActionEnabled,
      callStatusChange,
      reOpenStatus,
      initiateBaselineEnrollment,
      baselineReportingSendRequest,
      baselineReportingReportOnBehalf,
      reportOnBaselineViaEmailLink,
      submitBaselineReportingOnBehalf,
      submitBaselineReporting,
      reviewBaselineReporting,
      reopenBaselineReporting,
      reopenBaselineReportingSendRequest,
      reopenBaselineReportingReopenOnBehalf,
      approveBaselineReporting,
      submitEnrollmentORBaseline,
      reopenEnrollmentOrBaseline,
      approveEnrollmentOrBaseline,
      updateParticipantStatus,
      generateBaselineId,
      updateParticipantBaselineStatus,
    }),
    [],
  );

  return (
    <LifeCycleContext.Provider value={value}>
      {children}
    </LifeCycleContext.Provider>
  );
};

LifeCycleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
