import React, { useRef, useEffect, useState } from 'react';
import LightTooltip from 'components/LightTooltip';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { defaultTextStyle, tooltipFixedStyle } from './OverFlowTooltip.style';
import { defaultTooltipProps, resizeProperty } from './OverFlowTooltip.content';
import { TOOLTIP_PLACEMENTS } from 'utils/config';

/**
 * @description Component to show tooltip on overflowed text
 * @param {String} value - value to be displayed
 * @param {Object} stylesSx - styles object for typography element
 * @param {String} tooltipPlacement - placement of tooltip
 * @param {Object} toolTipProps - props for tooltip
 */
const OverFlowTooltip = ({
  value,
  stylesSx = defaultTextStyle,
  tooltipPlacement = TOOLTIP_PLACEMENTS.BOTTOM_START,
  toolTipProps = defaultTooltipProps,
}) => {
  // ref to refernece typography element
  const displayValueRef = useRef();
  //   boolean state to check if text is overflowed
  const [isOverflow, setIsOverflow] = useState(false);

  /**
   * @description compare the scrollWidth and clientWidth of typography element to check if text is overflowed
   */
  const checkTextOverflow = () => {
    const overflow =
      displayValueRef.current.scrollWidth > displayValueRef.current.clientWidth;
    setIsOverflow(overflow);
  };

  useEffect(() => {
    checkTextOverflow();
    // compare once and add resize listener on componentDidMount
    window.addEventListener(resizeProperty, checkTextOverflow);
    return () => {
      // remove resize listener on componentWillUnmount
      window.removeEventListener(resizeProperty, checkTextOverflow);
    };
  }, []);

  return (
    <LightTooltip
      {...toolTipProps}
      title={value}
      disableHoverListener={!isOverflow}
      placement={tooltipPlacement}>
      <Typography
        ref={displayValueRef}
        style={{
          ...stylesSx,
          ...tooltipFixedStyle,
        }}
        noWrap>
        {value}
      </Typography>
    </LightTooltip>
  );
};

export default OverFlowTooltip;

OverFlowTooltip.propTypes = {
  value: PropTypes.string.isRequired,
  stylesSx: PropTypes.object,
  tooltipPlacement: PropTypes.oneOf(Object.values(TOOLTIP_PLACEMENTS)),
  toolTipProps: PropTypes.object,
};
