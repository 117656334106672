import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ControlledInputWrapper,
  FieldErrorWrapper,
  InputWithAdornment,
  TextInput,
} from '../LeftMenuFieldData.style';
import { PAGE_CONTENT } from '../LeftMenuFieldData.content';
import { fieldNamePlaceholder } from './ControlledInput.content';

const ControlledInput = ({
  field,
  setValue,
  farmInfo = [],
  isInBaselinePage,
  isFieldInCurrentFarm,
  fieldSelected = false,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [textValue, setTextValue] = useState(field.value);

  const isFieldNameUnique = useMemo(() => {
    let count = 0;
    let currentFieldId = field.id;
    for (const farm of farmInfo) {
      const fields = farm.fields;
      for (const field of fields) {
        if (currentFieldId !== field.id && field.value === textValue.trim()) {
          count++;
        }
      }
    }

    return count === 0;
  }, [textValue]);

  useEffect(() => {
    setTextValue(field.value);
  }, [field]);

  useEffect(() => {
    if (!isFieldInCurrentFarm) {
      setEditMode(false);
    }
  }, [isFieldInCurrentFarm]);

  return (
    <ControlledInputWrapper>
      <InputWithAdornment isFocused={editMode}>
        <TextInput
          isInBaselinePage={isInBaselinePage}
          selected={fieldSelected}
          autoFocus={editMode}
          dimmed={field.value.length === 0}
          value={textValue}
          placeholder={fieldNamePlaceholder}
          readOnly={!editMode}
          onChange={(event) => setTextValue(event.target.value)}
        />
      </InputWithAdornment>
      {!isFieldNameUnique && editMode && (
        <FieldErrorWrapper>
          {PAGE_CONTENT.fieldNameExistsError}
        </FieldErrorWrapper>
      )}
    </ControlledInputWrapper>
  );
};

ControlledInput.propTypes = {
  field: PropTypes.object,
  setValue: PropTypes.func,
  farmInfo: PropTypes.array,
  isInBaselinePage: PropTypes.bool,
  isFieldInCurrentFarm: PropTypes.bool,
  fieldSelected: PropTypes.bool,
};
export default ControlledInput;
