import React, { createContext, useContext, useMemo } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { mapLibraries } from 'utils/config';
import { SettingsContext } from './settings';
import PropTypes from 'prop-types';

export const mapContext = createContext({});
export const MapProvider = ({ children }) => {
  // Using base64 encryption as a work around for env issue in cloud run
  const { googleMapKey = '' } = useContext(SettingsContext);
  const { isLoaded: isMapLoaded } = useLoadScript({
    googleMapsApiKey: window.atob(googleMapKey),
    libraries: mapLibraries,
    language: 'en',
  });

  const value = useMemo(
    () => ({
      isMapLoaded,
    }),
    [isMapLoaded],
  );

  return <mapContext.Provider value={value}>{children}</mapContext.Provider>;
};

MapProvider.propTypes = {
  children: PropTypes.node,
};
