import {
  addButtonSx,
  ButtonWrapper,
  ComponentWrapper,
  MainContainer,
  StyledCalenderIcon,
} from './DropdownDateComponent.style';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import PropTypes from 'prop-types';
import CustomDatePicker from 'components/FormComponents/CustomDatePicker/CustomDatePicker.react';
import { YEAR_FORMAT } from 'utils/config';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { BLUISH_CYAN, COOL_GRAY, DARK_GREEN } from 'theme/GlobalColors';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { ErrorWrapper } from 'components/FormComponents/FormStyles';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { FIELD_INFO_CONSTANT } from '../ParticipantGeneralFieldInfoForm.content';
import { outlinedDropdownInputSx } from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/Modals.style';
import AddButton from 'components/AddButton/AddButton.react';
import { startIconStyle } from 'components/AddButton/AddButton.style';
import { isHistoricLandUseType20years } from 'utils/helper';

const DropdownDateComponent = ({
  disableFieldForRevisitingForm,
  defaultLandUseType,
  landUseTypeList,
  historicLandUseType,
  addAnotherHistoricLandUseType,
  handleChangeOnHistoricLandUseType,
  removeHistoricLandUseType,
  errors,
}) => {
  return (
    <MainContainer>
      {historicLandUseType.map((item, index) => {
        const currentYear = new Date().getUTCFullYear();
        const startYear = (currentYear - 19).toString();
        const endYear = currentYear.toString();
        return (
          <ComponentWrapper key={uniqueId()}>
            <DropDown
              valueWidth={'6.125rem'}
              valueHeight={'1.188rem'}
              data-testid="landUseType"
              key={uniqueId()}
              customPlaceholderColor={COOL_GRAY}
              isDisabled={disableFieldForRevisitingForm}
              setStateValue={() => {}}
              dropdownlist={
                index === 1
                  ? landUseTypeList.filter(
                      (obj) => obj.label !== defaultLandUseType,
                    )
                  : landUseTypeList
              }
              onUpdate={(event) => {
                const value = event.target.value;
                handleChangeOnHistoricLandUseType(
                  index,
                  'landuseTypeId',
                  value,
                );
              }}
              name="landUseType"
              showLabelAsValue={
                !(item.landuseTypeId === -1 || item.landuseTypeId === '')
              }
              value={
                item.landuseTypeId === -1 || item.landuseTypeId === ''
                  ? `${FIELD_INFO_CONSTANT.emptyPlaceHolder}`
                  : item.landuseTypeId
              }
              dropDownPlaceholder="Land Use Type"
              hasNoBottomMargin={true}
              minWidth={'13.75rem'}
              width={'16.75rem'}
              height={'2rem'}
              customSx={outlinedDropdownInputSx}
            />
            {/*Start Date will be current year - 20 enabled*/}
            <CustomDatePicker
              isDropdownDateComponent
              isDisabled={disableFieldForRevisitingForm}
              notShowValue={false}
              data-testid="startYear"
              customSelectedDateColor={DARK_GREEN}
              hasSideLabels="true"
              name="startYear"
              labelColor={COOL_GRAY}
              hasNoBottomMargin={true}
              alignItems="baseline"
              width={'7.5rem'}
              minWidth={'7.25rem'}
              labelWidth={'2rem'}
              height={'2rem'}
              display="flex"
              gap={'0.25rem'}
              icon={StyledCalenderIcon}
              label="Start"
              fontSize={'0.875rem'}
              value={item.startYear === '0' ? '' : item.startYear}
              setDisabledYears={true}
              startYear={startYear}
              endYear={endYear}
              showOnlyYears={true}
              onUpdate={(date) => {
                const year = new Date(date).getFullYear().toString();
                handleChangeOnHistoricLandUseType(index, 'startYear', year);
              }}
              labelMarginTop={'0rem'}
              labelMarginBottom={'0rem'}
              inputFormat={YEAR_FORMAT}
              minDate={new Date(startYear, 0, 1)}
              maxDate={new Date(Date.UTC(endYear, 11, 31))}
              isEditable={false}></CustomDatePicker>
            {/*End Date will be current year - 1 disabled*/}
            <CustomDatePicker
              isDropdownDateComponent
              notShowValue={false}
              onUpdate={(date) => {
                const year = new Date(date).getFullYear().toString();
                handleChangeOnHistoricLandUseType(index, 'endYear', year);
              }}
              setDisabledYears={true}
              customSelectedDateColor={DARK_GREEN}
              startYear={startYear}
              endYear={endYear}
              isDisabled={disableFieldForRevisitingForm}
              name="endYear"
              labelColor={COOL_GRAY}
              hasNoBottomMargin={true}
              alignItems="baseline"
              justifyContent="center"
              width={'7.5rem'}
              labelMarginTop={'0rem'}
              labelMarginBottom={'0rem'}
              labelWidth={'1.563rem'}
              minWidth={'7.25rem'}
              height={'2rem'}
              display="flex"
              gap={'0.25rem'}
              icon={StyledCalenderIcon}
              label="End"
              fontSize={'0.875rem'}
              value={item.endYear === '0' ? '' : item.endYear}
              showOnlyYears={true}
              inputFormat={YEAR_FORMAT}
              minDate={new Date(startYear, 0, 1)}
              maxDate={new Date(Date.UTC(endYear, 11, 31))}
              isEditable={false}></CustomDatePicker>

            {index != 0 && !disableFieldForRevisitingForm && (
              <IconButton
                disabled={disableFieldForRevisitingForm}
                disableFocusRipple
                disableRipple
                aria-label="delete"
                onClick={() => {
                  if (historicLandUseType.length > 1) {
                    removeHistoricLandUseType(index);
                  }
                }}>
                <DeleteOutlineIcon
                  data-testid="delete"
                  sx={{
                    width: '1.5rem',
                    height: '1.5rem',
                    color: COOL_GRAY,
                    '&:hover': {
                      color: BLUISH_CYAN,
                    },
                  }}></DeleteOutlineIcon>
              </IconButton>
            )}
          </ComponentWrapper>
        );
      })}
      <div>
        <ButtonWrapper>
          <AddButton
            buttonName={FIELD_INFO_CONSTANT.land_use_type}
            hasStartIcon
            startIcon={
              <AddIcon
                sx={{ ...startIconStyle, width: '1rem', height: '1rem' }}
              />
            }
            disableButton={
              isHistoricLandUseType20years(historicLandUseType) ||
              disableFieldForRevisitingForm
            }
            onClick={
              disableFieldForRevisitingForm
                ? () => {}
                : () => addAnotherHistoricLandUseType()
            }
            noDropdown
            customBtnSx={addButtonSx}
          />
        </ButtonWrapper>
        {errors.isError && <ErrorWrapper>{errors.errorMessage}</ErrorWrapper>}
      </div>
    </MainContainer>
  );
};
DropdownDateComponent.propTypes = {
  defaultLandUseType: PropTypes.string,
  disableFieldForRevisitingForm: PropTypes.bool,
  landUseTypeList: PropTypes.array,
  historicLandUseType: PropTypes.array,
  addAnotherHistoricLandUseType: PropTypes.func,
  handleChangeOnHistoricLandUseType: PropTypes.func,
  removeHistoricLandUseType: PropTypes.func,
  errors: PropTypes.object,
};
export default DropdownDateComponent;
