import styled from 'styled-components';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  line-height: 1.225rem;
`;
export const ParaWrapper = styled.div`
  font-size: 0.875rem;
  color: ${DARK_CHARCOAL};
`;
