import {
  AccessDeniedHeadingNormal,
  AccessDeniedWrapper,
  MainWrapper,
} from './AccessDeniedPage.style';
import { useContext } from 'react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { ACCESS_DENIED_PAGE_CONTENT } from './AccessDeniedPage.content';
import { TextWrapper } from 'components/FormComponents/FormStyles';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

const AccessDeniedPage = () => {
  const { clientConfigs } = useContext(userAuthRolesContext);
  const supportEmail = clientConfigs?.find(
    (item) => item.key === ACCESS_DENIED_PAGE_CONTENT.CLIENT_SUPPORT_EMAIL_KEY,
  )?.value;

  return (
    <MainWrapper>
      <AccessDeniedWrapper>
        <AccessDeniedHeadingNormal>
          {ACCESS_DENIED_PAGE_CONTENT.access_denied_heading}
        </AccessDeniedHeadingNormal>
        <TextWrapper
          fontSize="0.875rem"
          color={
            DARK_CHARCOAL
          }>{`"${ACCESS_DENIED_PAGE_CONTENT.permission_body} ${supportEmail} ${ACCESS_DENIED_PAGE_CONTENT.for_support}"`}</TextWrapper>
      </AccessDeniedWrapper>
    </MainWrapper>
  );
};

export default AccessDeniedPage;
