import LightTooltip from 'components/LightTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import { customTitle } from './InfoToolTip.content';
import { IconStyle } from './InfoTooltip.style';
import { FORM_CONTENT } from '../FormComponents.content';
import PropTypes from 'prop-types';
import { TOOLTIP_PLACEMENTS } from 'utils/config';
import { isEmpty } from 'utils/helper';

const InfoTooltip = ({
  content,
  bottomMargin,
  maxWidth = '18.75rem',
  csafToolTipSx,
  handleClick = () => {
    /* do nothing here */
  },
  customSx,
  edgeOfFieldToolTip,
  placement = TOOLTIP_PLACEMENTS.BOTTOM,
  title = '',
}) => {
  if (isEmpty(title) && isEmpty(content)) {
    return;
  }
  const displayTooltip = title !== undefined && content !== undefined;
  return (
    <LightTooltip
      sx={{ maxWidth: maxWidth }}
      title={displayTooltip && customTitle(title, content, edgeOfFieldToolTip)}
      placement={placement}
      arrow>
      <InfoOutlinedIcon
        sx={{ ...IconStyle(bottomMargin), ...csafToolTipSx, ...customSx }}
        color={FORM_CONTENT.disabled}
        data-testid="tooltip"
        onClick={handleClick}
      />
    </LightTooltip>
  );
};

InfoTooltip.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  edgeOfFieldToolTip: PropTypes.bool,
  bottomMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.string,
  csafToolTipSx: PropTypes.object,
  handleClick: PropTypes.func,
  customSx: PropTypes.object,
  placement: PropTypes.oneOf(Object.values(TOOLTIP_PLACEMENTS)),
};

export default InfoTooltip;
