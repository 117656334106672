import styled from 'styled-components';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const ContentSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
`;

export const IconWrapper = styled.div`
  padding: 0.5rem;
`;

export const LabelWrapper = styled.div`
  font-family: Open Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${DARK_CHARCOAL};
`;

export const ContentTextWrapper = styled.div`
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;
