import styled from 'styled-components';
import { DARK_CHARCOAL, TYPOGRAPHY_GRAY, WHITE } from 'theme/GlobalColors';

export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 151px);
`;

export const BoxWrapper = styled.div`
  height: 5rem;
  width: 26.25rem;
  top: 296px;
  left: 406px;
  background-color: ${WHITE};
  display: flex;
  display: -webkit-flex;
  align-items: center;
  padding: 1.5rem 1.5rem;
  flex-direction: column;
  gap: 1rem;
`;

export const AccessDeniedWrapper = styled.div`
  max-width: 31.5rem;
  background-color: ${WHITE};
  display: flex;
  display: -webkit-flex;
  padding: 1.5rem 1.5rem;
  flex-direction: column;
`;

export const AccessDeniedHeading = styled.div`
  font-size: 2rem;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  color: ${TYPOGRAPHY_GRAY};
  margin-bottom: 2rem;
`;

export const AccessDeniedHeadingNormal = styled.div`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1rem;
  color: ${DARK_CHARCOAL};
`;

export const PermissionHeadingWrapper = styled.div`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  color: ${DARK_CHARCOAL};
`;

export const PermissionTextWrapper = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  color: ${DARK_CHARCOAL};
  text-align: center;
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  color: #0076a8;
  cursor: pointer;
`;

export const BackIcon = styled.div`
  padding-top: 0.15rem;
  stroke: #0076a8;
`;

export const BackButtonLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4875rem;
  margin-left: 0.375rem;
`;
