import styled from 'styled-components';
import {
  GRAYISH_BLACK,
  NEW_DARK_GREEN,
  NEW_FOCUS_DARK_GREEN,
  NEW_HOVER_GREEN_BACKGROUND,
  TEAL_GREEN_HOVER,
  WHITE,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
import { pageButtonDefaultStyle } from 'theme/GlobalStyles';

const buttonsBorderColor = NEW_DARK_GREEN;

export const MainWrapper = styled.div`
  padding: 1rem 1rem 0.5rem 1rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const InstructionText = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  gap: 0.8125rem;
`;

export const getButtonStyle = (selectedButton) => {
  return {
    borderRadius: '0.25rem',
    textTransform: 'none',
    ...pageButtonDefaultStyle,
    padding: checkTernaryCondition(
      selectedButton,
      '0rem 0.45rem',
      '0.125rem 0.5rem',
    ),
    fontSize: '0.875rem',
    boxShadow: 'none',
    border: checkTernaryCondition(
      selectedButton,
      `2px solid ${buttonsBorderColor}`,
      `1px solid ${NEW_DARK_GREEN}`,
    ),
    color: checkTernaryCondition(
      selectedButton,
      NEW_FOCUS_DARK_GREEN,
      NEW_DARK_GREEN,
    ),
    backgroundColor: checkTernaryCondition(
      selectedButton,
      TEAL_GREEN_HOVER,
      WHITE,
    ),
    '&:hover': {
      borderColor: selectedButton ? buttonsBorderColor : NEW_DARK_GREEN,
      color: NEW_FOCUS_DARK_GREEN,
      backgroundColor: checkTernaryCondition(
        selectedButton,
        TEAL_GREEN_HOVER,
        NEW_HOVER_GREEN_BACKGROUND,
      ),
      boxShadow: 'none',
    },
    '&& .Mui-disabled': {
      color: GRAYISH_BLACK,
    },
  };
};

export const getIconStyle = (selectedButtonIcon, isbuttonsDisabled) => {
  return {
    fill: isbuttonsDisabled
      ? GRAYISH_BLACK
      : selectedButtonIcon
      ? NEW_FOCUS_DARK_GREEN
      : NEW_DARK_GREEN,
    marginRight: '0.313rem',
  };
};
