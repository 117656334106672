import { findFirstIndex } from 'utils/helper';
import { LABELS } from './Participants.content';
import axios from 'axios';
import { GET_PROJECT_PARTICIPANT_INFO } from 'urls';

/**
 * @description function to get structured object of name and email list in filter click
 * @param {Object} data
 * @returns {Object}
 */
export const getStructuredObject = (data) => {
  return {
    id: data?.id,
    itemLabel: data?.label,
    checked: false,
  };
};

export const findMenuWithDivisions = (list) => {
  const indexList = LABELS.ACTIVITY_MENU_ITEMS.map((item) =>
    findFirstIndex(list, item, LABELS.STATUS),
  ).filter((item) => item !== -1 && item !== 0);
  return indexList;
};

export const getProjectAccountingApproachAndParticipantStatus = async (
  projectId,
  participantId,
) => {
  let projectParticipantInfoURL = `${GET_PROJECT_PARTICIPANT_INFO}?projectId=${projectId}`;
  /* istanbul ignore else */
  if (participantId) {
    projectParticipantInfoURL = `${projectParticipantInfoURL}&participantId=${participantId}`;
  }
  return axios.get(projectParticipantInfoURL).then((res) => res.data);
};
