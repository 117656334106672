import axios from 'axios';
import { SettingsContext } from 'contextAPI/settings';
import { initializeApp } from 'firebase/app';
import {
  browserPopupRedirectResolver,
  initializeAuth,
  SAMLAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { SESSION_LOGOUT, UPDATE_LAST_ACTIVITY } from 'urls';
import { useIdleTimer } from 'react-idle-timer';
import { IDLE_TIMER } from 'utils/config';

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const useFirebaseSignIn = () => {
  let idleTimeout = IDLE_TIMER.IDLE_TIME_OUT; //28 minute
  let warningTimeout = IDLE_TIMER.WARNING_TIME_OUT; //2 minute

  const [idleModal, setIdleModal] = useState(false);
  const navigation = useNavigate();

  const onPrompt = () => {
    setIdleModal(true);
  };

  const signoutUsers = async () => {
    await signOut(auth);
    /* istanbul ignore else */
    if (!settings.isDevEnvironment) {
      await axios.post(SESSION_LOGOUT, {});
    }
  };

  const handleLogout = async () => {
    resetTimer();
    setIdleModal(false);
    pause();
    try {
      signoutUsers();
    } finally {
      sessionStorage.clear();
      navigation('/logout', { replace: true });
      setIdleModal(false);
    }
  };

  const {
    reset: resetTimer,
    activate: restartTimer,
    pause,
    getLastActiveTime,
  } = useIdleTimer({
    timeout: idleTimeout,
    crossTab: true,
    leaderElection: true,
    syncTimers: 500,
    promptBeforeIdle: warningTimeout,
    onPrompt,
    throttle: 500,
    startOnMount: true,
    onIdle: handleLogout,
  });

  const settings = useContext(SettingsContext);

  const firebaseConfig = {
    apiKey: settings.firebaseApiKey,
    authDomain: settings.firebaseAuthDomain,
    authProvider: settings.authProvider,
  };

  const app = initializeApp(firebaseConfig);
  const auth = initializeAuth(app, {
    persistence: [],
    popupRedirectResolver: browserPopupRedirectResolver,
  });
  const provider = new SAMLAuthProvider(`saml.${firebaseConfig.authProvider}`);

  const updateUserLastActivity = async () => {
    await axios.post(UPDATE_LAST_ACTIVITY).catch((error) => {
      console.error(error);
    });
  };

  const loginSetup = async () => {
    restartTimer();
    if (settings.isDevEnvironment) {
      return signInWithPopup(auth, provider);
    } else {
      signInWithRedirect(auth, provider);
    }
  };

  const handleLogin = async () => {
    const response = await loginSetup();
    await updateUserLastActivity();
    return response;
  };

  return {
    handleLogin,
    app,
    handleLogout,
    idleModal,
    setIdleModal,
    warningTimeout,
    restartTimer,
    resetTimer,
    pause,
    getLastActiveTime,
  };
};
