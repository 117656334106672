import styled from 'styled-components';
import {
  BLUISH_CYAN,
  DARK_CHARCOAL,
  TYPOGRAPHY_GRAY,
} from 'theme/GlobalColors';

export const SwitchAndLabelWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;
export const TextWrapper = styled.div`
  color: ${({ color }) => color || DARK_CHARCOAL};
  font-family: 'Open Sans', sans-serif;
  font-size: ${({ fontSize }) => fontSize};
  font-style: normal;
  font-weight: 400;
`;
export const SwitchSx = ({
  customDisableBackgroundColor,
  customThumbColor,
}) => {
  return {
    width: '32px',
    height: '16px',
    padding: '0px',
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: '15px',
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: '2px',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        padding: '2px',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: BLUISH_CYAN,
        },
      },
      '&.Mui-disabled': {
        color: TYPOGRAPHY_GRAY,
        pointerEvents: 'none',
      },
      '&.Mui-disabled+.MuiSwitch-track': {
        backgroundColor: customDisableBackgroundColor,
        opacity: 1,
        pointerEvents: 'none',
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      color: customThumbColor || '#fff',
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  };
};
