import { useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import { PAGE_CONTENT } from './AddFieldsModal.content';
import {
  DropZoneWrapper,
  FieldAddTypeListItem,
  FieldAddTypeText,
  ModalTitleSx,
  UploadBtnWrapper,
  UploaderWrapper,
  closeIconStyle,
  deleteIconStyle,
  subtitleSx,
  uploadBtnSx,
} from './AddFieldsModal.style';
import { IconWrapper } from '../LeftMenuFieldData.style';
import { ReactComponent as DrawBoundaryIcon } from '../../../../../assets/icons/draw_boundary.svg';
import { ReactComponent as UploadFileIcon } from '../../../../../assets/icons/file_upload.svg';
import { fieldAddTypes } from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';
import { useDropzone } from 'react-dropzone';
import {
  MultiFilesUploaderTextContent,
  getApiError,
  getError,
  maxFilesError,
} from 'components/FileUploader/FileUploader.content';
import {
  AcceptedFileItem,
  DragAndDropText,
  FileName,
  SmallUploadWrapper,
  Subtext,
  UploadHelpText,
  cursorPointerSx,
} from 'components/FileUploader/FileUploader.style';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { BOUNDARY_SHAPE_FILE_UPLOAD } from 'urls';
import { formatGeoJson } from 'utils/helper';
import {
  backdropSx,
  dialogSx,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.style';
import uuid from 'react-uuid';
import { primaryButtonStyle } from 'components/FormComponents/FormStyles';
import { pathName } from 'Routes/routes.const';
import { useNavigate } from 'react-router';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    width: '30%',
    minWidth: '25rem',
  },
}));

const AddFieldsModal = ({ acceptedFilesInfo = {} }) => {
  const {
    fieldAddTypeModalOpen,
    setFieldAddTypeModalOpen,
    setSelectedFieldAddType,
    addFieldsFromShapeFile,
    shouldNavigateToLandingPage,
  } = useContext(participantInfoContext);

  const [shapeFileUploadVisible, setShapeFileUploadVisible] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [uploadError, setUploadError] = useState({ flag: false, messages: [] });
  const navigate = useNavigate();

  const dropZoneProps = {
    ...(acceptedFilesInfo?.mimeType &&
      acceptedFilesInfo?.extensions && {
        accept: { [acceptedFilesInfo.mimeType]: acceptedFilesInfo.extensions },
      }),
    ...(acceptedFilesInfo?.maxFiles && {
      maxFiles: acceptedFilesInfo?.maxFiles,
    }),
    ...(acceptedFilesInfo?.maxFileSize && {
      maxSize: acceptedFilesInfo.maxFileSize,
    }),
    ...(acceptedFilesInfo?.minFileSize && {
      minSize: acceptedFilesInfo.minFileSize,
    }),
  };

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone(dropZoneProps);

  const isMaxFilesLimitExceeded = useMemo(
    () =>
      acceptedFilesInfo?.maxFiles &&
      uploadedFiles.length + rejectedFiles.length > acceptedFilesInfo.maxFiles,
    [uploadedFiles, rejectedFiles],
  );

  const addFiles = (files, addFilesHandler) => {
    if (files.length > 0) {
      addFilesHandler((prevFiles) => {
        return [...prevFiles, ...files];
      });
    }
  };

  const handleClose = () => {
    setSelectedFieldAddType(null);
    setFieldAddTypeModalOpen(false);
  };

  const handleFieldAddTypeClick = (fieldAddType) => {
    setSelectedFieldAddType(fieldAddType);
    setFieldAddTypeModalOpen(false);
  };

  const uploadShapeFile = () => {
    if (uploadedFiles.length > 0) {
      const formData = new FormData();
      formData.append('file', uploadedFiles[0].file);
      setLoading(true);
      axios
        .post(BOUNDARY_SHAPE_FILE_UPLOAD, formData)
        .then((resp) => {
          const polygons = formatGeoJson(resp.data);
          addFieldsFromShapeFile(polygons);
          setFieldAddTypeModalOpen(false);
        })
        .catch((error) => {
          const errorMsg = getApiError(
            error.response?.data?.errorCodeMsg ||
              error.response?.data?.message ||
              'Error',
            acceptedFilesInfo.infoText,
          );
          const messagesList = errorMsg.split('\n');
          setUploadError({
            flag: true,
            messages: messagesList,
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const deleteFile = (fileIndex, fileHandler) => {
    fileHandler((prevFiles) =>
      prevFiles.filter((_, index) => fileIndex !== index),
    );
  };

  useEffect(() => {
    if (acceptedFiles.length > 0)
      addFiles(
        acceptedFiles.map((file) => ({ file: file, id: uuid() })),
        setUploadedFiles,
      );
  }, [acceptedFiles]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      setUploadError({
        flag: true,
        messages: [
          getError(
            fileRejections[0].errors[0].code,
            acceptedFilesInfo.minFileSize,
            acceptedFilesInfo.maxFileSize,
            acceptedFilesInfo.fileNotSupportedErrorText,
          ),
        ],
      });
      addFiles(
        fileRejections.map((fileItem) => ({ ...fileItem, id: uuid() })),
        setRejectedFiles,
      );
    }
  }, [fileRejections]);

  useEffect(() => {
    if (rejectedFiles.length === 0)
      setUploadError({ flag: false, messages: [] });
    else if (isMaxFilesLimitExceeded) {
      setUploadError({
        flag: true,
        messages: [maxFilesError(acceptedFilesInfo.maxFiles)],
      });
    } else if (rejectedFiles.length > 0) {
      setUploadError({
        flag: true,
        messages: [
          getError(
            fileRejections[0].errors[0].code,
            acceptedFilesInfo.minFileSize,
            acceptedFilesInfo.maxFileSize,
            acceptedFilesInfo.fileNotSupportedErrorText,
          ),
        ],
      });
    } else setUploadError({ flag: false, messages: [] });
  }, [rejectedFiles]);

  useEffect(() => {
    if (isMaxFilesLimitExceeded) {
      setUploadError({
        flag: true,
        messages: [maxFilesError(acceptedFilesInfo.maxFiles)],
      });
      setUploadButtonDisabled(true);
    } else if (rejectedFiles.length === 0)
      setUploadError({ flag: false, messages: [] });
    else {
      setUploadError({
        flag: false,
        messages: [],
      });
      setUploadButtonDisabled(true);
    }
  }, [isMaxFilesLimitExceeded]);

  useEffect(() => {
    setUploadButtonDisabled(
      rejectedFiles.length > 0 || uploadedFiles.length === 0,
    );
    if (!isMaxFilesLimitExceeded && rejectedFiles.length === 0) {
      setUploadError({ flag: false, messages: [] });
    }
  }, [uploadedFiles, rejectedFiles]);

  useEffect(() => {
    setUploadedFiles([]);
    setRejectedFiles([]);
    setLoading(false);
    setUploadError({ flag: false, messages: [] });
    setUploadButtonDisabled(true);
  }, [shapeFileUploadVisible, fieldAddTypeModalOpen]);

  useEffect(() => {
    setShapeFileUploadVisible(false);
  }, [fieldAddTypeModalOpen]);
  return (
    <div>
      <Backdrop sx={backdropSx} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <BootstrapDialog
        sx={dialogSx}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={fieldAddTypeModalOpen}>
        <DialogTitle sx={ModalTitleSx} id="customized-dialog-title">
          {PAGE_CONTENT.modalTitle}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={closeIconStyle}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography gutterBottom sx={subtitleSx}>
            {PAGE_CONTENT.modalSubtitle}
          </Typography>
          <UploaderWrapper>
            <FieldAddTypeListItem
              border="none"
              marginBottom="0"
              hoverEffect={!shapeFileUploadVisible}
              data-testid="shape-file-upload"
              onClick={async () => {
                const shouldNavigate = await shouldNavigateToLandingPage();
                if (shouldNavigate) {
                  navigate(pathName.participant.landingPage);
                  return;
                }
                setSelectedFieldAddType(fieldAddTypes[0]);
                setShapeFileUploadVisible((prev) => !prev);
              }}>
              <IconWrapper paddingLeft="2.4rem">
                <UploadFileIcon />
              </IconWrapper>
              <FieldAddTypeText>{PAGE_CONTENT.fileUploadText}</FieldAddTypeText>
            </FieldAddTypeListItem>
            {shapeFileUploadVisible && (
              <DropZoneWrapper data-testid="dropzone">
                <SmallUploadWrapper width="100%">
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} data-testid="fileDropzone" />
                    <DragAndDropText>
                      {`${PAGE_CONTENT.dragAndDropText} or `}
                      <Subtext>{MultiFilesUploaderTextContent.BROWSE}</Subtext>
                    </DragAndDropText>
                  </div>
                </SmallUploadWrapper>
                <UploadHelpText
                  invisible={uploadError.flag || isMaxFilesLimitExceeded}>
                  {acceptedFilesInfo?.infoText}
                </UploadHelpText>

                <UploadHelpText
                  invisible={uploadError.flag || isMaxFilesLimitExceeded}>
                  {PAGE_CONTENT?.fileSizeInfo}
                </UploadHelpText>

                <UploadHelpText
                  invisible={uploadError.flag || isMaxFilesLimitExceeded}>
                  {acceptedFilesInfo?.overlappingFieldsInfo}
                </UploadHelpText>

                {uploadError.flag &&
                  uploadError.messages?.map((message) => (
                    <UploadHelpText key={message} iserror={true}>
                      {message}
                    </UploadHelpText>
                  ))}

                {uploadedFiles.map((fileItem, index) => (
                  <AcceptedFileItem key={fileItem.id}>
                    <FileName isError={isMaxFilesLimitExceeded}>
                      {fileItem.file.name}
                    </FileName>
                    <DeleteIcon
                      fontSize="large"
                      sx={deleteIconStyle}
                      style={{ ...deleteIconStyle, ...cursorPointerSx }}
                      onClick={() => deleteFile(index, setUploadedFiles)}
                    />
                  </AcceptedFileItem>
                ))}

                {rejectedFiles.map(({ file, id }, index) => (
                  <AcceptedFileItem key={id}>
                    <FileName isError={true}>{file.name}</FileName>
                    <DeleteIcon
                      fontSize="large"
                      sx={deleteIconStyle}
                      style={{ ...deleteIconStyle, ...cursorPointerSx }}
                      onClick={() => deleteFile(index, setRejectedFiles)}
                    />
                  </AcceptedFileItem>
                ))}

                <UploadBtnWrapper>
                  <Button
                    onClick={uploadShapeFile}
                    variant="contained"
                    sx={[uploadBtnSx, primaryButtonStyle()]}
                    disabled={uploadButtonDisabled}>
                    {PAGE_CONTENT.upload}
                  </Button>
                </UploadBtnWrapper>
              </DropZoneWrapper>
            )}
          </UploaderWrapper>
          <FieldAddTypeListItem
            hoverEffect
            data-testid="draw-boundary"
            onClick={async () => {
              const shouldNavigate = await shouldNavigateToLandingPage();
              if (shouldNavigate) {
                navigate(pathName.participant.landingPage);
                return;
              }
              handleFieldAddTypeClick(fieldAddTypes[1]);
            }}>
            <IconWrapper paddingLeft="2.4rem">
              <DrawBoundaryIcon />
            </IconWrapper>
            <div>
              <FieldAddTypeText>
                {PAGE_CONTENT.drawBoundaryText}
              </FieldAddTypeText>
              <FieldAddTypeText subtext>
                {PAGE_CONTENT.drawBoundarySubText}
              </FieldAddTypeText>
            </div>
          </FieldAddTypeListItem>
          {/* Note: Hiding drop pin for now, can be enabled later */}
          {/* <FieldAddTypeListItem
            hoverEffect
            data-testid="drop-pin"
            onClick={() => handleFieldAddTypeClick(fieldAddTypes[2])}>
            <IconWrapper>
              <DropPinIcon />
            </IconWrapper>
            <div>
              <FieldAddTypeText>{PAGE_CONTENT.dropPinText}</FieldAddTypeText>
              <FieldAddTypeText subtext>
                {PAGE_CONTENT.dropPinSubText}
              </FieldAddTypeText>
            </div>
          </FieldAddTypeListItem> */}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

AddFieldsModal.propTypes = {
  acceptedFilesInfo: PropTypes.object,
};
export default AddFieldsModal;
