const useThrottle = (func, delay) => {
  let timer = null;

  return (...args) => {
    if (timer == null) {
      func(...args);
      timer = setTimeout(() => {
        timer = null;
      }, delay);
    }
  };
};

export default useThrottle;
