import InputField from 'components/FormComponents/InputField/InputField.react';
import { useParticipantGeneralFieldInfo } from 'hooks/useParticipantGeneralFieldInfo';
import { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { BLACK, DARK_CHARCOAL, RED } from 'theme/GlobalColors';
import {
  COVER_CROP,
  FIELD_INFO_CONSTANT,
  NO_TILL,
  NUTRIENT_MANAGEMENT,
  REDUCED_TILL,
  EDGE_OF_FIELD_ERROR,
  plannedProjectActivityGreaterThanFieldAreaError,
  plannedProjectActivityRequiredError,
  INITIAL_ERROR_STATE,
} from './ParticipantGeneralFieldInfoForm.content';
import {
  EDIT_GENERAL_FIELD_INF0,
  FETCH_ACTIVITY_DROPDOWN_LIST,
  FETCH_GENERAL_FIELD_INFO,
  FETCH_LAND_USE_TYPES,
  PARTICIPANT_PROFILE,
  FETCH_USER_LOCK_DETAILS,
} from 'urls';
import {
  DropdownDateWrapper,
  ErrorIconStyle,
  FieldWrapper,
  MainContainer,
  PlannedProjectActivitiesErrorContainer,
  PlannedProjectActivityHeaderWrapper,
  TextWrapper,
  toastStyle,
  AlertIcontStyle,
  FormslableStyle,
} from './ParticipantGeneralFieldInfoForm.style';
import { Divider } from '@mui/material';
import axios from 'axios';
import {
  checkIfValueZero,
  checkTernaryCondition,
  checkValidEndYear,
  findObjectWithKey,
  getBooleanValueForNullOrUndefinedOrEmpty,
  isEmpty,
  isIntersect,
  isLast20yearsDataNotPresent,
  isValueEmptyNullorUndefined,
} from 'utils/helper';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import { useNavigate } from 'react-router';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { pathName } from 'Routes/routes.const';
import {
  handleNextInProgrssSwitcher,
  checkIsEnrollmentCompleted,
  FIELD_AREA,
  PLANNED_TOTAL_ACTIVITY_AREA,
  ENROLLMENT_SUBMITTED_MESSAGE,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';
import { FormLabels } from 'components/FormComponents/FormStyles';
import { STYLE_CONSTANTS } from '../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import {
  ButtonContainer,
  InputSx,
  NavigationBtnWrapper,
} from '../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import DropdownDateComponent from './DropdownDateComponent/DropdownDateComponent.react';
import AddButton from 'components/AddButton/AddButton.react';
import UserNavigationButtonsV2 from '../UserNavigationButtonsV2/UserNavigationButtonsV2.react';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import { RadioErrorIconStyle } from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.style';
import PlannedProjectActivityRow from './PlannedProjectActivityRow/PlannedProjectActivityRow.react';
import { GeneralInfoWrapper } from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import { addButtonSx } from './DropdownDateComponent/DropdownDateComponent.style';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import {
  ERROR_MSGS,
  INPUT_CATEGORY,
  RIGHT_TEXT_ALIGN,
  ToastType,
} from 'utils/config';
import { getFarmsWithSortedFields } from 'pages/ParticipantDataCollection/ReportingUtilFunctions';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { useInterval } from 'hooks/useInterval';
import EdgeOfFieldCalcModal from '../EdgeOfFieldCalcModal/EdgeOfFieldCalcModal.react';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';

const ParticipantGeneralFieldInfoForm = ({
  participantId,
  name,
  projectId,
  selectedFieldId,
  switchState,
  handleSwitchChange,
  isSwitchDisabled,
  setIsSwitchDisabled,
  setSwitchState,
  setProjectIdValue,
  setParticipantIdValue,
  isBlockedModalVisible = false,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectIdValue = searchParams.get('projectid');
  const participantIdValue = searchParams.get('participantid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectCycleId = searchParams.get('projectcycleid');
  const [isFieldEditMode, setIsFieldEditMode] = useState(false);
  const [farmDetails, setFarmDetails] = useState([]);
  const [isEditInProgress, setIsEditInProgress] = useState(false);
  const [landUseTypeList, setLandUseTypeList] = useState([
    {
      label: '',
      value: 1,
    },
  ]);
  const [addedProjectActivities, setAddedProjectActivities] = useState([]);
  const [projectActivityList, setProjectActivityList] = useState([]);
  const [uniqueErrorMessages, setUniqueErrorMessages] = useState([]);
  const [unchangedProjectActivityOptions, setUnchangedProjectActivityOptions] =
    useState([]);
  const [isEOFModalOpen, setIsEOFModalOpen] = useState(false);
  const [containsTillageOption, setContainsTillageOption] = useState({
    noTillage: false,
    reducedTillage: false,
  });
  const {
    userDetails: { userId },
  } = useContext(userAuthRolesContext);

  const {
    currentFarmId,
    setFarmInfo,
    farmInfo,
    savedFieldsForms,
    setSavedFiledForms,
    setSelectedFieldId,
    setShowFieldInfoForm,
    fieldPolygons,
    setFieldPolygons,
    setCurrentFarmId,
    setExpandedFarmId,
    setFarmInfoCurrentTab,
    fieldIdsAddedFromMap,
    setFieldIdsAddedFromMap,
    updateFarmFieldName,
    currentFarmInfo,
    setSubmitData,
    setRightSectionId,
    setUserActivityDetected,
    isFieldDeleted,
    setIsFieldDeleted,
    participantProfileStatus,
    setModalData,
    enrolledFieldIds,
    setIsFieldSubmitted,
    fetchParticipantData,
    isParticipantContributor,
    currentCycleDataYear,
    currentCycleStartDate,
    currentCycleEndDate,
    triggerFieldInfoFetch,
    setTriggerFieldInfoFetch,
    closeUnsavedChangesModal,
    triggerFieldInfoSubmit,
    setTriggerFieldInfoSubmit,
    setFieldInfoTab,
    unsavedChangesModalOpenProps,
    handleSubmitCallback,
    getCurrentFarmFieldsArea,
    shouldNavigateToLandingPage,
    goToProfile,
    setActiveUser,
    setDisableSubmitAllFarmsBtn,
    setIsEnrollmentCompletedAllTogether,
    isEnrollmentCompletedAllTogether,
    fetchEnrollmentCompletionDetails,
    enrollmentDataCollectionCompleted,
    hasUnApprovedFarmsOrFields,
    setLoaderCounter,
    NavigateOutOfEnrollment,
  } = useContext(participantInfoContext);
  const {
    formValue,
    setFormValue,
    handleTextFieldValueChange,
    error,
    setError,
  } = useParticipantGeneralFieldInfo();
  const {
    unitOfMeasureList: { fieldEnrolment },
  } = useContext(uomContext);

  useEffect(() => {
    setDisableSubmitAllFarmsBtn(!isEnrollmentCompletedAllTogether);
  }, [isEnrollmentCompletedAllTogether]);
  const isFieldNameUnique = useMemo(() => {
    let count = 0;
    for (const farm of farmInfo) {
      const fields = farm.fields;
      for (const field of fields) {
        if (
          field.id !== selectedFieldId?.id &&
          field.value === name.trim() &&
          !isEmpty(name)
        ) {
          count++;
        }
      }

      return count === 0;
    }
  }, [name]);

  /**
   * @description Setting user activity flag to true to track web form changes and prompt user to save if they try closing browser window/tab
   */
  const makeFormDirty = () => setUserActivityDetected(true);

  useEffect(() => {
    setSubmitData({
      currentPage: 'Field',
      data: formValue,
      currentId: currentFarmId,
    });
  }, [formValue]);

  // Submit triggered from tab switch
  useEffect(() => {
    if (triggerFieldInfoSubmit) {
      saveData()
        .then(() => {
          if (unsavedChangesModalOpenProps?.triggeredFrom === 'Tab') {
            setFieldInfoTab(1); // Switch to view map
          } else handleSubmitCallback();
        })
        .finally(() => {
          setIsEditInProgress(false);
          fetchParticipantData(false);
          closeUnsavedChangesModal();
          setTriggerFieldInfoSubmit(false);
          setUserActivityDetected(false);
        });
    }
  }, [triggerFieldInfoSubmit]);

  const disableAllFields = useMemo(() => {
    /* istanbul ignore else */
    if (!switchState || isSwitchDisabled) {
      return true;
    } else if (!enrolledFieldIds) return false;
    return enrolledFieldIds?.includes(selectedFieldId.id);
  }, [enrolledFieldIds, selectedFieldId.id, switchState]);

  useEffect(() => {
    if (farmDetails?.length > 0 && farmDetails !== undefined) {
      setIsFieldEditMode(
        farmDetails
          .filter((farm) => farm.farmId === currentFarmId)[0]
          ?.fieldDetails?.filter(
            (field) => field.fieldId === selectedFieldId.id,
          ).length > 0,
      );
    }
  }, [farmDetails, selectedFieldId]);

  useEffect(() => {
    /* istanbul ignore else */
    if (!switchState) {
      setIsEOFModalOpen(false);
      closeUnsavedChangesModal();
    }
  }, [switchState]);

  const returnErrorObject = (formValue, item) => {
    if (
      isValueEmptyNullorUndefined(getValueFromLandUseTypeId(item.landuseTypeId))
    ) {
      return {
        isError: true,
        errorMessage: '',
      };
    }
    if (
      checkIfValueZero(item.startYear) ||
      checkIfValueZero(item.endYear) ||
      isValueEmptyNullorUndefined(item.startYear) ||
      isValueEmptyNullorUndefined(item.endYear)
    ) {
      return {
        isError: true,
        errorMessage:
          FIELD_INFO_CONSTANT.needStartAndEndDateForHistoricLandUseTypeError,
      };
    }
    if (isIntersect(...formValue.fieldHistoricLanduseList)) {
      return {
        isError: true,
        errorMessage: FIELD_INFO_CONSTANT.dateIntersectError,
      };
    }
    if (!checkValidEndYear(...formValue.fieldHistoricLanduseList)) {
      return {
        isError: true,
        errorMessage: FIELD_INFO_CONSTANT.endYearGreaterError,
      };
    }
    // New validation for checking complete data for the last 20 years
    if (isLast20yearsDataNotPresent(formValue.fieldHistoricLanduseList)) {
      return {
        isError: true,
        errorMessage: FIELD_INFO_CONSTANT.last_20_years_required_error,
      };
    }
    return { isError: false, errorMessage: '' }; // Return null if no error
  };
  const getBlankErrorObjectForProjectActivity = (projectActivity) => {
    return {
      id: projectActivity.id,
      isError: false,
      errorMessage: '',
    };
  };
  const returnErrorObjectOnChange = (formValue, _item, i) => {
    const { startYear, endYear } = formValue.fieldHistoricLanduseList[i];
    const isStartYearEmpty = isValueEmptyNullorUndefined(startYear);
    const isEndYearEmpty = isValueEmptyNullorUndefined(endYear);

    if (isIntersect(...formValue.fieldHistoricLanduseList)) {
      return {
        isError: true,
        errorMessage: FIELD_INFO_CONSTANT.dateIntersectError,
      };
    }
    if (!isStartYearEmpty && isEndYearEmpty) {
      return { isError: false, errorMessage: '' };
    }
    if (
      endYear < startYear ||
      (isEndYearEmpty &&
        !checkValidEndYear(...formValue.fieldHistoricLanduseList))
    ) {
      return {
        isError: true,
        errorMessage: FIELD_INFO_CONSTANT.endYearGreaterError,
      };
    }
    return { isError: false, errorMessage: '' }; // Return null if no error
  };

  const getSwitchState = () => {
    axios
      .get(
        `${FETCH_USER_LOCK_DETAILS}/${currentFarmId}?internalProjectId=${projectIdValue}&participantOwnerId=${participantIdValue}`,
      )
      .then((response) => {
        setActiveUser(response.data);
        const { activeUserId } = response.data;
        const disableSwitch =
          !isEmpty(activeUserId) && +activeUserId != +userId;
        setIsSwitchDisabled(disableSwitch);
        if (disableSwitch) {
          setSwitchState(false);
        } else {
          setSwitchState(+activeUserId === +userId);
        }
      });
  };

  useInterval(async () => {
    /* istanbul ignore else */
    if (
      !isBlockedModalVisible &&
      (isSwitchDisabled || !switchState) &&
      +currentFarmId > 0
    ) {
      getSwitchState();
    }
  }, 10000);

  useEffect(() => {
    if (+currentFarmId > 0) {
      getSwitchState();
      fetchEditData();
    }
  }, [switchState]);

  useEffect(() => {
    setLoaderCounter((prev) => prev + 1);
    setProjectIdValue(projectIdValue);
    setParticipantIdValue(participantIdValue);
    axios
      .get(FETCH_LAND_USE_TYPES)
      .then((response) => {
        const responseData = response.data;
        const mappedData = responseData.map((item) => ({
          value: item.id,
          label: item.value,
        }));
        setLandUseTypeList(mappedData);
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(() => {
        setLoaderCounter((prev) => prev - 1);
      });
  }, []);
  const getValueFromLandUseTypeId = (id) => {
    return landUseTypeList.find((item) => item.value === id)?.label;
  };
  const getProjectActivityName = (id) => {
    return unchangedProjectActivityOptions.find(
      (activity) => activity.id === id,
    )?.name;
  };
  /**
   * Retrieves the category of a project activity based on its ID.
   *
   * @param {number} id - The ID of the project activity.
   * @returns {string} The category of the project activity.
   */
  const getProjectActivityCategory = (id) => {
    return unchangedProjectActivityOptions.find(
      (activity) => activity.id === id,
    )?.category;
  };
  const returnRemovedProjectActivity = (
    updatedFetchedImplementedProjectActivity,
  ) => {
    const hasId3 = updatedFetchedImplementedProjectActivity.some(
      (item) => item.name === REDUCED_TILL,
    );
    const hasId4 = updatedFetchedImplementedProjectActivity.some(
      (item) => item.name === NO_TILL,
    );
    const updatedList = unchangedProjectActivityOptions.filter((item) => {
      const isInUpdate = updatedFetchedImplementedProjectActivity.some(
        (activity) => activity.id === item.id,
      );

      if (!isInUpdate) {
        return !(
          (hasId3 && item.name === NO_TILL) ||
          (hasId4 && item.name === REDUCED_TILL)
        );
      }
      return false;
    });
    return updatedList;
  };
  const returnDropdownComponentError = (response, historicLandUseList) => {
    return (
      response.data.historicLanduseList
        ?.map((item) => {
          if (
            isValueEmptyNullorUndefined(
              getValueFromLandUseTypeId(item.landuseTypeId),
            )
          ) {
            return {
              isError: true,
              errorMessage: '',
            };
          }

          if (
            checkIfValueZero(item.startYear) ||
            checkIfValueZero(item.endYear) ||
            isValueEmptyNullorUndefined(item.startYear.toString()) ||
            isValueEmptyNullorUndefined(item.endYear.toString())
          ) {
            return {
              isError: true,
              errorMessage:
                FIELD_INFO_CONSTANT.needStartAndEndDateForHistoricLandUseTypeError,
            };
          }
          if (isIntersect(...historicLandUseList)) {
            return {
              isError: true,
              errorMessage: FIELD_INFO_CONSTANT.dateIntersectError,
            };
          }
          if (!checkValidEndYear(...historicLandUseList)) {
            return {
              isError: true,
              errorMessage: FIELD_INFO_CONSTANT.endYearGreaterError,
            };
          }
          // New validation for checking complete data for the last 20 years
          if (isLast20yearsDataNotPresent(historicLandUseList)) {
            return {
              isError: true,
              errorMessage: FIELD_INFO_CONSTANT.last_20_years_required_error,
            };
          }
          return { isError: false, errorMessage: '' }; // Return null if no error
        })
        .find((error) => error.isError) ?? {
        isError: false,
        errorMessage: '',
      }
    );
  };
  /**
   * Calculates the error list for the given mapping list and total field area.
   *
   * @param {Array} mappingList - The list of project activities.
   * @param {number} [totalFieldArea] - The total field area. (optional)
   * @returns {Array} - The error list.
   */
  const getEdgeOfFieldError = (mappingList, totalFieldArea) => {
    const errorList = mappingList.map((projectActivity) => {
      if (
        projectActivity.category === FIELD_INFO_CONSTANT.EDGE_OF_FIELD_PRACTICES
      ) {
        if (
          +projectActivity.totalAcres >=
          checkTernaryCondition(
            isEmpty(totalFieldArea),
            +formValue.totalFieldArea,
            totalFieldArea,
          )
        ) {
          return {
            id: projectActivity.id,
            isError: true,
            errorMessage: EDGE_OF_FIELD_ERROR,
          };
        } else {
          return getBlankErrorObjectForProjectActivity(projectActivity);
        }
      } else {
        return getBlankErrorObjectForProjectActivity(projectActivity);
      }
    });

    return errorList;
  };
  const getProjectActivityError = (mappingList, totalFieldArea) => {
    const errorList = mappingList.map((projectActivity) => {
      if (
        getBooleanValueForNullOrUndefinedOrEmpty(projectActivity.totalAcres)
      ) {
        return {
          id: projectActivity.id,
          isError: true,
          errorMessage: '',
        };
      } else if (checkIfValueZero(+projectActivity.totalAcres)) {
        return {
          id: projectActivity.id,
          isError: true,
          errorMessage: plannedProjectActivityRequiredError,
        };
      } else if (
        projectActivity.category !==
          FIELD_INFO_CONSTANT.EDGE_OF_FIELD_PRACTICES &&
        +projectActivity.totalAcres >
          checkTernaryCondition(
            isEmpty(totalFieldArea),
            +formValue.totalFieldArea,
            totalFieldArea,
          )
      ) {
        return {
          id: projectActivity.id,
          isError: true,
          errorMessage: plannedProjectActivityGreaterThanFieldAreaError,
        };
      } else {
        return getBlankErrorObjectForProjectActivity(projectActivity);
      }
    });
    return errorList;
  };

  const fetchEditData = () => {
    if (selectedFieldId.id) {
      setLoaderCounter((prev) => prev + 1);
      axios
        .get(
          `${FETCH_GENERAL_FIELD_INFO}?fieldId=${selectedFieldId.id}&projectId=${projectId}`,
        )
        .then((response) => {
          const data = response.data;
          const disableSwitch =
            !isEmpty(data?.activeUserId) && +data?.activeUserId != +userId;
          if (disableSwitch) {
            getSwitchState();
            setSwitchState(false);
            setIsSwitchDisabled(disableSwitch);
          } else {
            setSwitchState(+data?.activeUserId === +userId);
          }
          const updatedFetchedImplementedProjectActivity =
            data.activityList.map((item) => {
              return {
                id: item.projectActivityId,
                category: getProjectActivityCategory(item.projectActivityId),
                name: getProjectActivityName(item.projectActivityId),
                totalAcres: item.plannedTotalArea,
                fieldCropProjectActivityId: item.fieldCropProjectActivityId,
                /*               fieldCsafUpdatedByActivity: item.fieldCsafUpdatedByActivity, */
                totalHeadCount: item.actualTotalHeadcount,
                projectCycleId: item.projectCycleId,
              };
            });
          setAddedProjectActivities(updatedFetchedImplementedProjectActivity);
          // set the unique messages here
          const errorArrayForRequiredError = getProjectActivityError(
            updatedFetchedImplementedProjectActivity,
            checkTernaryCondition(
              data.totalFieldArea === -1.0,
              '',
              data.totalFieldArea,
            ),
          );
          const edgeOfFieldError = getEdgeOfFieldError(
            updatedFetchedImplementedProjectActivity,
            checkTernaryCondition(
              data.totalFieldArea === -1.0,
              '',
              data.totalFieldArea,
            ),
          );
          error.edgeOfFieldErrorList = edgeOfFieldError;
          error.plannedProjectActivityErrorList = errorArrayForRequiredError;
          const messages = new Set();
          const uniqueErrors = errorArrayForRequiredError?.reduce(
            (acc, error) => {
              if (
                error.isError &&
                error.errorMessage &&
                !messages.has(error.errorMessage)
              ) {
                messages.add(error.errorMessage);
                acc.push(error);
              }
              return acc;
            },
            [],
          );
          setUniqueErrorMessages(uniqueErrors);

          //remove all this options from the projectActivityList
          const updatedProjectActivityList = returnRemovedProjectActivity(
            updatedFetchedImplementedProjectActivity,
          );

          setProjectActivityList(updatedProjectActivityList);

          const historicLandUseList = checkTernaryCondition(
            data.historicLanduseList === null,
            [{ landuseTypeId: '', startYear: '', endYear: '' }],
            data.historicLanduseList?.map((item) => ({
              ...item,
              startYear: item.startYear.toString(),
              endYear: item.endYear.toString(),
            })),
          );
          setFormValue((prevFormValue) => {
            return {
              ...prevFormValue,
              fsaFieldId: data.fsaFieldId,
              fsaTractId: data.fsaTractId,
              totalFieldArea: checkTernaryCondition(
                data.totalFieldArea === -1.0,
                '',
                data.totalFieldArea,
              ),
              /*csafImplemented: data.csafImplemented,
            csafUpdatedBySystem: data.csafUpdatedBySystem,
            farmCsafUpdatedByField: data.farmCsafUpdatedByField, */
              /*        isConsistentLanduse20Yrs: data.isConsistentLanduse20Yrs, */
              landUseTypeId: checkTernaryCondition(
                data.landuseTypeId === null,
                -1,
                data.landuseTypeId,
              ),
              fieldHistoricLanduseList: historicLandUseList,
            };
          });

          if (data.newlyCreated) {
            // Prepare the updated list without the selected field ID
            const updatedFieldIds = fieldIdsAddedFromMap.filter(
              (fieldId) => fieldId !== selectedFieldId.id,
            );

            // Set the new state
            setFieldIdsAddedFromMap(updatedFieldIds);
            setError(INITIAL_ERROR_STATE);
          } else {
            setError((prevError) => ({
              ...prevError,
              fsaTractId: !data.fsaTractId.trim(),
              fsaFieldId: !data.fsaFieldId.trim(),
              totalFieldArea: data.totalFieldArea === 0,
              fieldName: !data.fieldName.trim(),
              projectActivityDtoList: data.activityList.length === 0,
              /* csafImplementedRequiredError: isValueEmptyNullorUndefined(
              data.csafImplemented,
            ), */
              /*  commonLandUseTypeFor3YearsError: isValueEmptyNullorUndefined(
              getValueFromLandUseTypeId(data.landuseTypeId),
            ), */
              /* isConsistentFor20YearsError: isValueEmptyNullorUndefined(
              data.isConsistentLanduse20Yrs,
            ), */

              dropDownDateComponentError: returnDropdownComponentError(
                response,
                [...historicLandUseList],
              ),
              plannedProjectActivityErrorList: getProjectActivityError(
                updatedFetchedImplementedProjectActivity,
                checkTernaryCondition(
                  data.totalFieldArea === -1.0,
                  '',
                  data.totalFieldArea,
                ),
              ),
              edgeOfFieldErrorList: getEdgeOfFieldError(
                updatedFetchedImplementedProjectActivity,
                checkTernaryCondition(
                  data.totalFieldArea === -1.0,
                  '',
                  data.totalFieldArea,
                ),
              ),
              noActivityAddedError:
                !updatedFetchedImplementedProjectActivity.length > 0,
            }));
          }

          // On initial fetch we set the user activity to false, once any value is changed, we update the flag
          setUserActivityDetected(false);
        })
        .finally(() => setLoaderCounter((prev) => prev - 1));
    }
  };
  const updateFarmInfo = (prevFarmInfo, currentFarmStatus) => {
    return prevFarmInfo.map((farm) => {
      if (farm.id === currentFarmId) {
        return {
          ...farm,
          status: currentFarmStatus,
        };
      }
      return farm;
    });
  };
  //to fetch updated farm data
  const fetchUpdatedData = (
    isInitialCall,
    updatedFieldId,
    updatedFarmInfo,
    navigateToNext,
  ) => {
    setLoaderCounter((prev) => prev + 1);
    axios
      .get(
        `${PARTICIPANT_PROFILE}?enrollmentType=PARTICIPANT&projectId=${projectId}&participantId=${participantId}`,
      )
      .then((response) => {
        const farmsWithSortedFields = getFarmsWithSortedFields(
          response.data.farmDetails,
        );

        const currentFarmStatus = farmsWithSortedFields?.find(
          (farm) => farm.farmId === currentFarmId,
        )?.farmStatus;
        setFarmInfo((prevFarmInfo) => {
          return updateFarmInfo(prevFarmInfo, currentFarmStatus);
        });
        setFarmDetails(farmsWithSortedFields);
        // to navigate to next field section in enrollment
        if (!isInitialCall) {
          const currentFarmData = updatedFarmInfo.filter(
            (farm) => farm.id === currentFarmId,
          )[0];
          const currentFieldIndex = currentFarmData?.fields?.findIndex(
            (field) => field.id === updatedFieldId.fieldId,
          );

          if (navigateToNext) {
            let inProgressItem = handleNextInProgrssSwitcher({
              updatedFarmInfo: updatedFarmInfo,
              currentFarm: currentFarmData,
              currentField: updatedFieldId,
              isFarmWebform: false,
              participantProfileStatus: participantProfileStatus,
            });
            formInProgressHandler(
              inProgressItem,
              currentFieldIndex,
              currentFarmData,
              updatedFarmInfo,
            );
          }
        }
        setIsFieldDeleted(false);
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  const formInProgressHandler = (
    inProgressItem,
    currentFieldIndex,
    currentFarm,
    updatedFarmInfo,
  ) => {
    if (inProgressItem) {
      switch (inProgressItem.type) {
        case 'field': {
          /* istanbul ignore else */
          if (inProgressItem.farmId !== currentFarmId && switchState) {
            handleSwitchChange({ target: { checked: false } });
          }
          setSelectedFieldId({
            id: inProgressItem.id,
            name: inProgressItem.value,
          });
          setCurrentFarmId(inProgressItem.farmId);
          setExpandedFarmId(inProgressItem.farmId);
          setFarmInfoCurrentTab(0);
          setShowFieldInfoForm(true);
          break;
        }
        case 'farm': {
          /* istanbul ignore else */
          if (inProgressItem.id !== currentFarmId && switchState) {
            handleSwitchChange({ target: { checked: false } });
          }
          setCurrentFarmId(inProgressItem.id);
          setExpandedFarmId(inProgressItem.id);
          setFarmInfoCurrentTab(0);
          setShowFieldInfoForm(false);
          break;
        }
        case 'profile': {
          goToProfile();
        }
      }
    } else {
      //go to next completed sections
      const nextField = currentFarm?.fields[currentFieldIndex + 1];
      const currentFarmIndex = updatedFarmInfo.findIndex(
        (farm) => farm?.id === currentFarm?.id,
      );
      if (currentFieldIndex < currentFarm?.fields.length - 1) {
        // goto next field
        setSelectedFieldId({
          id: nextField.id,
          name: nextField.value,
        });
      } else if (currentFarmIndex < updatedFarmInfo.length - 1) {
        const nextFarmId = updatedFarmInfo[currentFarmIndex + 1].id;
        setCurrentFarmId(nextFarmId);
        setExpandedFarmId(nextFarmId);
        setShowFieldInfoForm(false);
        setFarmInfoCurrentTab(0);
      }
    }
  };
  /**
   * Handles the enrollment status from the response and updates the farm and submit all farms button state information accordingly.
   *
   * @param {Object} response - The response object containing the enrollment status.
   * @returns {boolean} - Returns true if enrollment is completed, otherwise false.
   */
  const handleEnrollmentStatusFromResponse = (response) => {
    if (response?.success === false) {
      return;
    }
    const updatedFarmInfo = farmInfo.map((farm) => {
      return checkTernaryCondition(
        farm.id === currentFarmId,
        {
          ...farm,
          status: response?.data?.farmFormStatus,
          fields: response && returnUpdatedFields(farm, response),
        },
        farm,
      );
    });
    let isEnrollmentCompleted = checkIsEnrollmentCompleted(
      updatedFarmInfo,
      validateForm(),
      participantProfileStatus,
      isParticipantContributor,
    );

    setIsEnrollmentCompletedAllTogether(isEnrollmentCompleted);
    setDisableSubmitAllFarmsBtn(!isEnrollmentCompleted);
    return isEnrollmentCompleted;
  };
  const saveData = async (event) => {
    setLoaderCounter((prev) => prev + 1);
    event?.target?.blur();
    setIsEditInProgress(true);
    const updatedAddedImplementedProjectActivity = addedProjectActivities.map(
      (item) => {
        return {
          projectActivityId: item.id,
          projectCycleId: projectCycleId,
          totalArea: item.totalAcres,
          totalHeadCount: item.totalHeadCount || '',
          fieldCropProjectActivityId: item.fieldCropProjectActivityId || 0,
          /*           fieldCsafUpdatedByActivity: item.fieldCsafUpdatedByActivity || false, */
        };
      },
    );

    const status = checkTernaryCondition(
      validateForm(),
      FIELD_INFO_CONSTANT.completed,
      FIELD_INFO_CONSTANT.in_progress,
    );

    const reqBody = {
      fieldName: name,
      fsaTractId: formValue.fsaTractId,
      fsaFieldId: formValue.fsaFieldId,
      totalFieldArea: +formValue.totalFieldArea,
      /*       csafImplemented: formValue.csafImplemented, */
      formStatus: status,
      activityList: updatedAddedImplementedProjectActivity,
      /*       csafUpdatedBySystem: formValue.csafUpdatedBySystem,
      farmCsafUpdatedByField: formValue.farmCsafUpdatedByField, */
      enrollmentInstanceId: +enrollmentInstanceId,
      participantId: +participantIdValue,
      /*      isConsistentLanduse20Yrs: formValue.isConsistentLanduse20Yrs, 
      landuseTypeId: +formValue.landUseTypeId,*/
      historicLanduseList: formValue.fieldHistoricLanduseList,
      projectCycleId: projectCycleId,
    };
    const url = `${EDIT_GENERAL_FIELD_INF0}?fieldId=${selectedFieldId.id}&projectId=${projectId}`;

    const handleSuccess = (response) => {
      if (!response.data) return;

      setUserActivityDetected(false);
      toast(
        <CustomSnackbar
          type={ToastType.SUCCESS}
          message={FIELD_INFO_CONSTANT.FORM_DETAILS_SUCCESS}
        />,
        toastStyle,
      );
      return response;
    };

    const handleError = () => {
      toast(
        <CustomSnackbar
          type={ToastType.ERROR}
          message={FIELD_INFO_CONSTANT.FORM_DETAILS_ERROR}
        />,
        toastStyle,
      );
      setRightSectionId(2);
      setCurrentFarmId(currentFarmId);
      setExpandedFarmId(currentFarmId);
      setSelectedFieldId({
        id: selectedFieldId.id,
        name: name,
      });
      setFarmInfoCurrentTab(0);
      setShowFieldInfoForm(true);
      return { success: false, error: error };
    };

    return axios
      .put(url, reqBody)
      .then(handleSuccess)
      .catch((error) => {
        const errorMessage = error?.response?.data?.errorCodeMsg;
        if (errorMessage === ENROLLMENT_SUBMITTED_MESSAGE)
          NavigateOutOfEnrollment();
        else handleError();
      })
      .finally(() => {
        setLoaderCounter((prev) => prev - 1);
        setIsEditInProgress(false);
      });
  };

  const handleSave = (event) => {
    saveData(event)
      .then((response) => {
        handleEnrollmentStatusFromResponse(response);
      })
      .finally(() => {
        setIsEditInProgress(false);
        fetchParticipantData(false);
      });
  };
  const returnUpdatedFields = (farm, response) => {
    return farm?.fields.map((field) => {
      return checkTernaryCondition(
        field.id === response?.data?.field?.fieldId,
        {
          ...field,
          status: response.data.field?.formStatus,
        },
        field,
      );
    });
  };
  const handleContinue = (event) => {
    saveData(event)
      .then((response) => {
        if (response?.success === false) {
          return;
        }
        const updatedFarmInfo = farmInfo.map((farm) => {
          return checkTernaryCondition(
            farm.id === currentFarmId,
            {
              ...farm,
              status: response?.data?.farmFormStatus,
              fields: response && returnUpdatedFields(farm, response),
            },
            farm,
          );
        });

        setUserActivityDetected(false);
        setIsFieldSubmitted(true);
        fetchParticipantData(false);
        navigateToNextSectionHandler(response?.data, updatedFarmInfo, true);
      })
      .finally(() => {
        setIsEditInProgress(false);
      });
  };

  const navigateToNextSectionHandler = (
    fieldData,
    updatedFarmInfo,
    navigateToNext,
  ) => {
    //filter out current farm from farmInfo
    let isEnrollmentCompleted = checkIsEnrollmentCompleted(
      updatedFarmInfo,
      validateForm(),
      participantProfileStatus,
      isParticipantContributor,
    );
    handleEnrollmentcompletion(
      isEnrollmentCompleted,
      fieldData,
      updatedFarmInfo,
      navigateToNext,
    );
  };

  const handleEnrollmentcompletion = (
    isEnrollmentCompleted,
    fieldData,
    updatedFarmInfo,
    navigateToNext,
  ) => {
    if (isEnrollmentCompleted) {
      setIsEnrollmentCompletedAllTogether(true);
      setDisableSubmitAllFarmsBtn(false);
      /* istanbul ignore else */
      if (switchState) {
        handleSwitchChange({ target: { checked: false } });
      }
      fetchUpdatedData(
        false,
        {
          fieldId: fieldData.field?.fieldId,
          fieldName: fieldData.field?.fieldName,
        },
        updatedFarmInfo,
        false,
      );
    } else {
      setIsEnrollmentCompletedAllTogether(false);
      setDisableSubmitAllFarmsBtn(true);
      setSavedFiledForms([...savedFieldsForms, selectedFieldId.id]);
      fetchUpdatedData(
        false,
        {
          fieldId: fieldData.field?.fieldId,
          fieldName: fieldData.field?.fieldName,
        },
        updatedFarmInfo,
        navigateToNext,
      );
      setFieldPolygons(
        fieldPolygons.map((polygon) =>
          checkTernaryCondition(
            polygon.fieldId === selectedFieldId.id,
            {
              ...polygon,
              fieldId: fieldData.field?.fieldId,
            },
            polygon,
          ),
        ),
      );
    }
  };

  useEffect(() => {
    isFieldEditMode && !isEditInProgress && fetchEditData();
  }, [isFieldEditMode, selectedFieldId, isEditInProgress]);

  useEffect(() => {
    if (triggerFieldInfoFetch) {
      fetchEditData();
      setTriggerFieldInfoFetch(false);
    }
  }, [triggerFieldInfoFetch]);

  useEffect(() => {
    setFormValue((prevFormValue) => {
      return { ...prevFormValue, fieldName: name };
    });
  }, [selectedFieldId]);

  const validateForm = () => {
    const finalError = { ...error };
    finalError.fsaFieldId = formValue.fsaFieldId === '';
    finalError.fsaTractId = formValue.fsaTractId === '';
    finalError.dropDownDateComponentError = formValue.fieldHistoricLanduseList
      .map((item) => {
        return returnErrorObject(formValue, item);
      })
      .find((error) => error.isError) ?? { isError: false, errorMessage: '' };
    const errorArrayForRequiredError = addedProjectActivities.map(
      (projectActivity) => {
        if (
          getBooleanValueForNullOrUndefinedOrEmpty(projectActivity.totalAcres)
        ) {
          return {
            id: projectActivity.id,
            isError: true,
            errorMessage: '',
          };
        } else if (checkIfValueZero(+projectActivity.totalAcres)) {
          return {
            id: projectActivity.id,
            isError: true,
            errorMessage: plannedProjectActivityRequiredError,
          };
        } else if (+projectActivity.totalAcres > +formValue.totalFieldArea) {
          return {
            id: projectActivity.id,
            isError: true,
            errorMessage: plannedProjectActivityGreaterThanFieldAreaError,
          };
        } else {
          return getBlankErrorObjectForProjectActivity(projectActivity);
        }
      },
    );
    const isActivityListEmpty = addedProjectActivities.length === 0;
    finalError.noActivityAddedError = finalError.projectActivityDtoList =
      isActivityListEmpty;

    const edgeOfFieldErrorList = addedProjectActivities.map(
      (projectActivity) => {
        if (
          projectActivity.category ===
          FIELD_INFO_CONSTANT.EDGE_OF_FIELD_PRACTICES
        ) {
          if (+projectActivity.totalAcres >= +formValue.totalFieldArea) {
            return {
              id: projectActivity.id,
              isError: true,
              errorMessage: EDGE_OF_FIELD_ERROR,
            };
          } else {
            return getBlankErrorObjectForProjectActivity(projectActivity);
          }
        } else {
          return getBlankErrorObjectForProjectActivity(projectActivity);
        }
      },
    );
    finalError.edgeOfFieldErrorList = edgeOfFieldErrorList;
    finalError.plannedProjectActivityErrorList = errorArrayForRequiredError;
    setError(finalError);
    const formErrorArray = Object.values(finalError);
    formErrorArray.push(finalError.dropDownDateComponentError.isError);
    //loop on the array of objects  in finalError.plannedProjectActivityErrorList and push the .isError keys
    formErrorArray.push(
      ...finalError.plannedProjectActivityErrorList.map(
        (error) => error.isError,
      ),
    );
    formErrorArray.push(
      ...finalError.edgeOfFieldErrorList.map((error) => error.isError),
    );

    const currentFarmArea = Number(currentFarmInfo?.totalFarmAcreage);
    const totalFieldAreaUnderCurrentFarm = getCurrentFarmFieldsArea();

    // Note: 10% is added to the farm area to account for any rounding errors
    const totalFieldsAreaExceedingFarmArea =
      totalFieldAreaUnderCurrentFarm > currentFarmArea * 1.1;

    return (
      !formErrorArray.includes(true) &&
      isFieldNameUnique &&
      !totalFieldsAreaExceedingFarmArea
    );
  };

  useEffect(() => {
    fetchEnrollmentCompletionDetails();
    isFieldEditMode && fetchEditData();
    fetchUpdatedData(true, 0, null, true);
    fetchActivityDropdownList();
  }, []);

  useEffect(() => {
    fetchEnrollmentCompletionDetails();
    isFieldDeleted && fetchUpdatedData(true, 0, null, true);
  }, [isFieldDeleted]);
  const handleSubmitAllFarms = () => {
    // Goes into this if when farm is locked but user clicks on "Submit all farms", there is nothing to save at this point so we open the review modal based on the user roles
    // Also this can only be triggered when enrollment data collection is completed(All statuses in "Completed" state)
    if (!switchState) {
      setModalData({
        flag: isEnrollmentCompletedAllTogether,
        id: 0,
      });
      return;
    }

    saveData()
      .then((response) => {
        const isEnrollmentCompleted =
          handleEnrollmentStatusFromResponse(response);
        if (isEnrollmentCompleted) {
          /* istanbul ignore else */
          if (switchState && !isEnrollmentCompletedAllTogether) {
            handleSwitchChange({ target: { checked: false } });
          }
          setModalData({
            flag: isEnrollmentCompletedAllTogether,
            id: 0,
          });
        } else {
          setTriggerFieldInfoFetch(true);
        }
      })
      .finally(() => {
        setIsEditInProgress(false);
        fetchParticipantData(false);
      });
  };
  const updateProjectActivityList = (response) => {
    return response.data.map((item) => {
      if (
        item.id === 1 ||
        item.id === 2 ||
        item.activityCategory === COVER_CROP ||
        item.activityCategory === NUTRIENT_MANAGEMENT
      ) {
        return {
          id: item.id,
          name: item.activityCategory,
        };
      }
      return {
        id: item.id,
        name: `${item.activityCategory}: ${item?.value}`,
        category: item.activityCategory,
      };
    });
  };
  const fetchActivityDropdownList = () => {
    setLoaderCounter((prev) => prev + 1);
    axios
      .get(FETCH_ACTIVITY_DROPDOWN_LIST, {
        params: { projectId: projectIdValue },
      })
      .then((response) => {
        const updatedList = updateProjectActivityList(response);
        let noTillage = false;
        let reducedTillage = false;
        for (const activity of updatedList) {
          if (activity.name === NO_TILL) {
            noTillage = true;
          }
          if (activity.name === REDUCED_TILL) {
            reducedTillage = true;
          }
          if (noTillage && reducedTillage) {
            break;
          }
        }
        setContainsTillageOption({
          noTillage,
          reducedTillage,
        });
        setProjectActivityList(updatedList);
        setUnchangedProjectActivityOptions(() => {
          return updateProjectActivityList(response);
        });
      })
      .finally(() => {
        setLoaderCounter((prev) => prev - 1);
      });
  };

  const addAnotherHistoricLandUseType = () => {
    makeFormDirty();
    setFormValue((prevState) => {
      return {
        ...prevState,
        fieldHistoricLanduseList: [
          ...prevState.fieldHistoricLanduseList,
          { landuseTypeId: -1, startYear: '', endYear: '' },
        ],
      };
    });
  };

  const handleChangeOnHistoricLandUseType = (index, key, value) => {
    makeFormDirty();
    const updatedHistoricLandUseType = formValue.fieldHistoricLanduseList.map(
      (item, i) => {
        if (i === index) {
          return { ...item, [key]: value };
        }
        return item;
      },
    );

    const dropdownCompErr = updatedHistoricLandUseType
      .map((item, i) => {
        return returnErrorObjectOnChange(
          {
            ...formValue,
            fieldHistoricLanduseList: updatedHistoricLandUseType,
          },
          item,
          i,
        );
      })
      .find((error) => error.isError) ?? { isError: false, errorMessage: '' };

    setFormValue((prevState) => {
      return {
        ...prevState,
        fieldHistoricLanduseList: updatedHistoricLandUseType,
      };
    });
    setError((prevError) => ({
      ...prevError,
      dropDownDateComponentError: dropdownCompErr,
    }));
  };
  const removeHistoricLandUseType = (index) => {
    makeFormDirty();
    const updatedHistoricLandUseType =
      formValue.fieldHistoricLanduseList.filter((item, i) => i !== index);

    const dropdownCompErr = updatedHistoricLandUseType
      .map((item) => {
        return returnErrorObject(
          {
            ...formValue,
            fieldHistoricLanduseList: updatedHistoricLandUseType,
          },
          item,
        );
      })
      .find((error) => error.isError) ?? { isError: false, errorMessage: '' };
    setFormValue((prevState) => {
      return {
        ...prevState,
        fieldHistoricLanduseList: updatedHistoricLandUseType,
      };
    });
    setError((prevError) => ({
      ...prevError,
      dropDownDateComponentError: dropdownCompErr,
    }));
  };

  const deleteProjectActivity = (id) => {
    makeFormDirty();
    //add this object back to the projectActivityList find it in the addedProjectActivities
    const projectActivity = addedProjectActivities.find(
      (projectActivity) => projectActivity.id === id,
    );
    const newProjectActivityToBeAdded = {
      id: projectActivity.id,
      name: projectActivity.name,
      category: projectActivity.category,
    };
    if (
      projectActivity.name === REDUCED_TILL &&
      containsTillageOption.noTillage
    ) {
      setProjectActivityList((prevState) => {
        return [
          ...prevState,
          {
            id: 4,
            name: NO_TILL,
            category: newProjectActivityToBeAdded.category,
          },
        ];
      });
    }
    if (
      projectActivity.name === NO_TILL &&
      containsTillageOption.reducedTillage
    ) {
      setProjectActivityList((prevState) => {
        return [
          ...prevState,
          {
            id: 3,
            name: REDUCED_TILL,
            category: newProjectActivityToBeAdded.category,
          },
        ];
      });
    }
    setProjectActivityList((prevState) => {
      return [...prevState, newProjectActivityToBeAdded];
    });

    //remove the object from the addedProjectActivityState where the id matches
    setAddedProjectActivities((prevState) => {
      return prevState.filter((projectActivity) => projectActivity.id !== id);
    });
    //also remove that id from the error list
    setError((prevError) => {
      return {
        ...prevError,
        edgeOfFieldErrorList:
          !isEmpty(prevError.edgeOfFieldErrorList) &&
          prevError.edgeOfFieldErrorList.filter((error) => error.id !== id),
        plannedProjectActivityErrorList:
          prevError.plannedProjectActivityErrorList?.filter(
            (error) => error.id !== id,
          ),
      };
    });
    // update the unique messages here also
    const errorArrayForRequiredError = getProjectActivityError(
      addedProjectActivities.filter(
        (projectActivity) => projectActivity.id !== id,
      ),
    );
    const edgeOfFieldError = getEdgeOfFieldError(
      addedProjectActivities.filter(
        (projectActivity) => projectActivity.id !== id,
      ),
    );
    error.edgeOfFieldErrorList = edgeOfFieldError;
    error.plannedProjectActivityErrorList = errorArrayForRequiredError;
    const messages = new Set();
    const uniqueErrors = errorArrayForRequiredError?.reduce((acc, error) => {
      if (
        error.isError &&
        error.errorMessage &&
        !messages.has(error.errorMessage)
      ) {
        messages.add(error.errorMessage);
        acc.push(error);
      }
      return acc;
    }, []);
    setUniqueErrorMessages(uniqueErrors);
  };
  const addProjectActivity = (id) => {
    //create a new object and add it in the addedProjectActivityState but first get the details from the projectActivityOptions where the id matches
    const projectActivity = projectActivityList.find(
      (projectActivity) => projectActivity.id === id,
    );
    const newProjectActivityToBeAdded = {
      id: projectActivity.id,
      name: projectActivity.name,
      category: projectActivity.category,
      totalAcres: checkTernaryCondition(
        projectActivity.category ===
          FIELD_INFO_CONSTANT.RESIDUE_TILLAGE_MANAGEMENT,
        formValue.totalFieldArea,
        '',
      ),
    };
    setAddedProjectActivities((prevState) => {
      return [...prevState, newProjectActivityToBeAdded];
    });
    setProjectActivityList((prevState) => {
      return prevState.filter((projectActivity) => {
        // If the id is either 3 or 4, remove any items with id 3 or 4.
        if (
          (id === 4 && projectActivity.name === REDUCED_TILL) ||
          (id === 3 && projectActivity.name === NO_TILL)
        ) {
          return false;
        }
        return projectActivity.id !== id;
      });
    });
    setError((prevError) => ({
      ...prevError,
      noActivityAddedError: false,
    }));
  };

  const updateFieldName = (event) => {
    updateFarmFieldName({
      farmId: currentFarmId,
      fieldId: selectedFieldId.id,
      name: event.target.value,
    });
    setFormValue({ ...formValue, fieldName: event.target.value });
    makeFormDirty();
  };

  const updateFsadata = (event, falseValue) => {
    handleTextFieldValueChange(event, null, falseValue);
    makeFormDirty();
  };

  const handleSaveClick = async () => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    handleSave();
  };

  const handleContinueClick = async () => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    handleContinue();
  };

  return (
    <>
      <MainContainer data-testid="field-info">
        <GeneralInfoWrapper minHeight="calc(100vh - 18rem)">
          <InputField
            isDisabled={disableAllFields}
            label={FIELD_INFO_CONSTANT.fieldName}
            value={formValue.fieldName}
            placeholder={FIELD_INFO_CONSTANT.fieldNamePlaceholder}
            name={FIELD_INFO_CONSTANT.fieldName}
            width="16.75rem"
            isRequired
            maxLength={80}
            category={INPUT_CATEGORY.NAME_FIELD}
            primaryError={error.fieldName}
            secondaryError={!isFieldNameUnique}
            secondaryErrorMessage={FIELD_INFO_CONSTANT.fieldNameRepeatedError}
            onUpdate={updateFieldName}
            customInputSx={InputSx}
            labelMarginTop={STYLE_CONSTANTS.zero_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            rightSideLabelFormLabelMargin={STYLE_CONSTANTS.one_point_five_rem}
          />
          <InputField
            isDisabled={disableAllFields}
            label={FIELD_INFO_CONSTANT.fsaTractId}
            value={formValue.fsaTractId}
            placeholder={FIELD_INFO_CONSTANT.fsaTractIdPlaceholder}
            name={FIELD_INFO_CONSTANT.fsaTractId}
            width="16.75rem"
            category={INPUT_CATEGORY.TEXT}
            isRequired
            primaryError={error.fsaTractId}
            onUpdate={(event) => updateFsadata(event, 'fsaTractId')}
            maxLength={100}
            labelMarginTop={STYLE_CONSTANTS.zero_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            rightSideLabelFormLabelMargin={STYLE_CONSTANTS.one_point_five_rem}
            customInputSx={InputSx}
          />
          <InputField
            isDisabled={disableAllFields}
            label={FIELD_INFO_CONSTANT.fsaFieldId}
            value={formValue.fsaFieldId}
            placeholder={FIELD_INFO_CONSTANT.fsaFieldIdPlaceholder}
            name={FIELD_INFO_CONSTANT.fsaFieldId}
            maxLength={100}
            category={INPUT_CATEGORY.TEXT}
            isRequired
            primaryError={error.fsaFieldId}
            onUpdate={(event) => updateFsadata(event, 'fsaFieldId')}
            width="16.75rem"
            labelMarginTop={STYLE_CONSTANTS.zero_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            rightSideLabelFormLabelMargin={STYLE_CONSTANTS.one_point_five_rem}
            customInputSx={InputSx}
          />

          <TextWrapper
            fontSize="14px"
            marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            color={checkTernaryCondition(error.totalFieldArea, RED, BLACK)}>
            {FIELD_INFO_CONSTANT.totalFieldArea}
          </TextWrapper>
          <InputField
            // Acreage will always be disabled since we are validating during field addition on map.
            isDisabled={true}
            label={
              findObjectWithKey(fieldEnrolment, FIELD_AREA)?.FIELD_AREA[0]
                ?.uomNameDisplay
            }
            value={formValue.totalFieldArea}
            placeholder={FIELD_INFO_CONSTANT.totalFieldAreaPlaceholder}
            name={FIELD_INFO_CONSTANT.totalFieldArea}
            width="4.75rem"
            maxLength={7}
            category={INPUT_CATEGORY.NUMBER}
            isRequired
            labelOnRightSide={true}
            rightSideLabelFormLabelMargin="0rem"
            display="flex"
            labelMarginTop={STYLE_CONSTANTS.zero_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_rem}
            textDisabledColor={BLACK}
            textAlignment={RIGHT_TEXT_ALIGN}
          />
          <DropdownDateWrapper>
            <FormLabels
              marginBottom={'0rem'}
              marginTop={'1.5rem'}
              flexDirection="row"
              style={FormslableStyle}>
              {FIELD_INFO_CONSTANT.historicLandUseType}
              {error.dropDownDateComponentError.isError && (
                <AlertIcon
                  data-testid="activity-error-icon"
                  style={{ ...RadioErrorIconStyle, ...AlertIcontStyle }}
                />
              )}
            </FormLabels>
            <DropdownDateComponent
              disableFieldForRevisitingForm={disableAllFields}
              errors={error.dropDownDateComponentError}
              defaultLandUseType={getValueFromLandUseTypeId(
                formValue.landUseTypeId,
              )}
              historicLandUseType={formValue.fieldHistoricLanduseList}
              addAnotherHistoricLandUseType={addAnotherHistoricLandUseType}
              handleChangeOnHistoricLandUseType={
                handleChangeOnHistoricLandUseType
              }
              removeHistoricLandUseType={removeHistoricLandUseType}
              landUseTypeList={landUseTypeList}
            />
          </DropdownDateWrapper>

          <FieldWrapper>
            <PlannedProjectActivityHeaderWrapper>
              <TextWrapper
                fontSize="0.875rem"
                fontWeight="700"
                color={DARK_CHARCOAL}
                lineHeight="1.225rem">
                {FIELD_INFO_CONSTANT.project_activity_title}
              </TextWrapper>
              {error.noActivityAddedError && (
                <AlertIcon
                  style={{
                    ...ErrorIconStyle,
                  }}
                />
              )}
            </PlannedProjectActivityHeaderWrapper>

            <TextWrapper
              fontWeight="400"
              fontSize="0.875rem"
              color={DARK_CHARCOAL}>
              {FIELD_INFO_CONSTANT.project_activity_info +
                ` ${currentCycleDataYear} (${currentCycleStartDate} - ${currentCycleEndDate})`}
            </TextWrapper>
            {addedProjectActivities.map((projectActivity) => {
              return (
                <PlannedProjectActivityRow
                  setUniqueErrorMessages={setUniqueErrorMessages}
                  totalFieldArea={formValue.totalFieldArea}
                  addedProjectActivities={addedProjectActivities}
                  setAddedProjectActivities={(value) => {
                    setAddedProjectActivities(value);
                    makeFormDirty();
                  }}
                  projectActivity={projectActivity}
                  error={error}
                  disableProjectActivity={disableAllFields}
                  deleteProjectActivityHandler={deleteProjectActivity}
                  setError={setError}
                  getProjectActivityError={getProjectActivityError}
                  getEdgeOfFieldError={getEdgeOfFieldError}
                  labelVal={
                    findObjectWithKey(
                      fieldEnrolment,
                      PLANNED_TOTAL_ACTIVITY_AREA,
                    ).PLANNED_TOTAL_ACTIVITY_AREA[0]?.uomNameDisplay
                  }
                  key={projectActivity.id}
                  setIsEOFModalOpen={
                    setIsEOFModalOpen
                  }></PlannedProjectActivityRow>
              );
            })}
            <PlannedProjectActivitiesErrorContainer
              display={checkTernaryCondition(
                error.plannedProjectActivityErrorList.length === 0 ||
                  error.noActivityAddedError ||
                  error.plannedProjectActivityErrorList.every(
                    (error) => error.isError === false,
                  ),
                'none',
                'flex',
              )}>
              {addedProjectActivities.length > 0 &&
                // get only the unique messages from error.plannedProjectActivityErrorList and display them try using a set to get the unique messages and then display them
                uniqueErrorMessages.map((error, index) => {
                  const key = index;
                  return (
                    <TextWrapper key={key} fontSize="0.688rem" color={RED}>
                      {error.errorMessage}
                    </TextWrapper>
                  );
                })}
            </PlannedProjectActivitiesErrorContainer>

            <AddButton
              onClickHandlerForProjectActivities={addProjectActivity}
              options={projectActivityList}
              handleForProjectActivities={true}
              disableButton={disableAllFields}
              hasStartIcon
              buttonName={FIELD_INFO_CONSTANT.activity}
              customBtnSx={addButtonSx}
              applySort
            />
          </FieldWrapper>
        </GeneralInfoWrapper>

        <NavigationBtnWrapper>
          <Divider />
          <ButtonContainer>
            <UserNavigationButtonsV2
              handleSave={handleSaveClick}
              handleContinue={handleContinueClick}
              disabledContinue={!switchState}
              handleSubmitAllFarms={handleSubmitAllFarms}
              disableSubmitAllFarms={
                !(
                  enrollmentDataCollectionCompleted &&
                  hasUnApprovedFarmsOrFields
                )
              }
              disabledSave={!switchState}
            />
          </ButtonContainer>
        </NavigationBtnWrapper>
      </MainContainer>
      <EdgeOfFieldCalcModal
        isOpen={isEOFModalOpen}
        setIsOpen={setIsEOFModalOpen}
      />
    </>
  );
};

ParticipantGeneralFieldInfoForm.propTypes = {
  participantId: PropTypes.string,
  name: PropTypes.string,
  projectId: PropTypes.string,
  selectedFieldId: PropTypes.string,
  switchState: PropTypes.bool,
  handleSwitchChange: PropTypes.func,
  isSwitchDisabled: PropTypes.bool,
  setIsSwitchDisabled: PropTypes.func,
  setSwitchState: PropTypes.func,
  setProjectIdValue: PropTypes.func,
  setParticipantIdValue: PropTypes.func,
  isBlockedModalVisible: PropTypes.bool,
};
export default ParticipantGeneralFieldInfoForm;
