import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
  UploadHelpText,
  TitleSection,
  TitleSectionHeading,
  cancelButtonSx,
  uploadBtnSx,
  AcceptedFileItem,
  FileName,
  deleteIconSx,
  SmallUploadWrapper,
  DragAndDropTitle,
  DragAndDropText,
  Subtext,
  cursorPointerSx,
  dialogActionsSx,
  closeIconSx,
  FileUploadContentWrapper,
} from './FileUploader.style';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  MultiFilesUploaderTextContent,
  getError,
  maxFilesError,
} from './FileUploader.content';
import { checkTernaryCondition } from 'utils/helper';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import uuid from 'react-uuid';
import { DARK_CHARCOAL } from 'theme/GlobalColors';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';

const MultiFilesUploader = ({
  onCancel,
  onUpload,
  isOpen,
  title,
  dragAndDropSectionTitle = '',
  dragAndDropText = '',
  cancelText = '',
  uploadText = '',
  acceptedFilesInfo = {},
  apiError = { flag: false, messages: [] },
  setApiError = () => null,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState({ flag: false, message: '' });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  const dropZoneProps = {
    ...(acceptedFilesInfo.mimeType &&
      acceptedFilesInfo.extensions && {
        accept: { [acceptedFilesInfo.mimeType]: acceptedFilesInfo.extensions },
      }),
    ...(acceptedFilesInfo.maxFiles && { maxFiles: acceptedFilesInfo.maxFiles }),
    ...(acceptedFilesInfo.maxFileSize && {
      maxSize: acceptedFilesInfo.maxFileSize,
    }),
    ...(acceptedFilesInfo.minFileSize && {
      minSize: acceptedFilesInfo.minFileSize,
    }),
  };

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone(dropZoneProps);

  const isMaxFilesLimitExceeded = useMemo(
    () =>
      uploadedFiles.length + rejectedFiles.length > acceptedFilesInfo.maxFiles,
    [uploadedFiles, rejectedFiles],
  );

  const addFiles = (files, addFilesHandler) => {
    if (files.length > 0) {
      addFilesHandler((prevFiles) => {
        return [...prevFiles, ...files];
      });
    }
  };

  const uploadFiles = () => {
    onUpload(uploadedFiles.map((fileItem) => fileItem.file));
  };

  const deleteFile = (fileIndex, fileHandler) => {
    fileHandler((prevFiles) =>
      prevFiles.filter((_, index) => fileIndex !== index),
    );
  };

  useEffect(() => {
    if (acceptedFiles.length > 0)
      addFiles(
        acceptedFiles.map((file) => ({ file: file, id: uuid() })),
        setUploadedFiles,
      );
  }, [acceptedFiles]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.minFileSize,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
      addFiles(
        fileRejections.map((fileItem) => ({ ...fileItem, id: uuid() })),
        setRejectedFiles,
      );
    }
  }, [fileRejections]);

  useEffect(() => {
    if (rejectedFiles.length === 0) {
      setUploadError({ flag: false, message: '' });
      setApiError({ flag: false, messages: [] });
    } else if (isMaxFilesLimitExceeded) {
      setUploadError({
        flag: true,
        message: maxFilesError(acceptedFilesInfo.maxFiles),
      });
    } else
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.minFileSize,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
  }, [rejectedFiles]);

  useEffect(() => {
    if (isMaxFilesLimitExceeded) {
      setUploadError({
        flag: true,
        message: maxFilesError(acceptedFilesInfo.maxFiles),
      });
    } else if (rejectedFiles.length === 0) {
      setUploadError({ flag: false, message: '' });
      setApiError({ flag: false, message: [] });
    } else
      setUploadError({
        flag: acceptedFilesInfo.minFileSize && acceptedFilesInfo.maxFileSize,
        message: getError(
          fileRejections[0]?.errors[0].code,
          acceptedFilesInfo.minFileSize,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
  }, [isMaxFilesLimitExceeded]);

  useEffect(() => {
    setButtonDisabled(rejectedFiles.length > 0 || uploadedFiles.length === 0);
    setApiError({ flag: false, message: [] });
  }, [uploadedFiles, rejectedFiles]);

  useEffect(() => {
    setUploadedFiles([]);
    setRejectedFiles([]);
    setLoading(false);
    setUploadError({ flag: false, message: '' });
    setApiError({ flag: false, message: [] });
    setButtonDisabled(true);
  }, [isOpen]);

  return (
    <div>
      <Dialog
        sx={{ zIndex: 1000 }}
        open={isOpen}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            pb: 0,
          }}>
          <TitleSection>
            <TitleSectionHeading
              color={DARK_CHARCOAL}
              isError={
                uploadError.flag || isMaxFilesLimitExceeded || apiError.flag
              }>
              {title}
            </TitleSectionHeading>
            <CloseIcon
              onClick={onCancel}
              sx={{ ...cursorPointerSx, ...closeIconSx }}
            />
          </TitleSection>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <FileUploadContentWrapper>
            <DragAndDropTitle
              isError={
                uploadError.flag || isMaxFilesLimitExceeded || apiError.flag
              }>
              {dragAndDropSectionTitle}
            </DragAndDropTitle>
            <SmallUploadWrapper>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} data-testid="fileDropzone" />
                <DragAndDropText>
                  {`${dragAndDropText} or `}
                  <Subtext>{MultiFilesUploaderTextContent.BROWSE}</Subtext>
                </DragAndDropText>
              </div>
            </SmallUploadWrapper>
            {acceptedFilesInfo.infoTextList?.map((infoText) => (
              <UploadHelpText
                key={infoText}
                invisible={
                  uploadError.flag || isMaxFilesLimitExceeded || apiError.flag
                }>
                {infoText}
              </UploadHelpText>
            ))}

            {uploadError.flag && (
              <UploadHelpText iserror={true}>
                {uploadError.message}
              </UploadHelpText>
            )}
            {apiError.flag &&
              apiError?.messages?.map((message) => (
                <UploadHelpText key={message} iserror={true}>
                  {message}
                </UploadHelpText>
              ))}

            {uploadedFiles.map((fileItem, index) => (
              <AcceptedFileItem key={fileItem.id}>
                <FileName isError={isMaxFilesLimitExceeded}>
                  {fileItem.file.name}
                </FileName>
                <DeleteIcon
                  fontSize="large"
                  sx={deleteIconSx}
                  style={{ ...deleteIconSx, ...cursorPointerSx }}
                  onClick={() => deleteFile(index, setUploadedFiles)}
                />
              </AcceptedFileItem>
            ))}

            {rejectedFiles.map(({ file, id }, index) => (
              <AcceptedFileItem key={id}>
                <FileName isError={true}>{file.name}</FileName>
                <DeleteIcon
                  fontSize="large"
                  sx={deleteIconSx}
                  style={{ ...deleteIconSx, ...cursorPointerSx }}
                  onClick={() => deleteFile(index, setRejectedFiles)}
                />
              </AcceptedFileItem>
            ))}
          </FileUploadContentWrapper>
        </DialogContent>
        <DialogActions sx={dialogActionsSx}>
          <Button
            onClick={onCancel}
            sx={[cancelButtonSx, tertiaryButtonStyle]}
            size="small"
            color="success">
            {checkTernaryCondition(
              cancelText === '',
              MultiFilesUploaderTextContent.CANCEL,
              cancelText,
            )}
          </Button>
          {(!loading && (
            <Button
              onClick={uploadFiles}
              sx={[uploadBtnSx, primaryButtonStyle()]}
              size="small"
              autoFocus
              variant="contained"
              color="success"
              disabled={buttonDisabled}>
              {checkTernaryCondition(
                uploadText === '',
                MultiFilesUploaderTextContent.UPLOAD,
                uploadText,
              )}
            </Button>
          )) || (
            <Button
              sx={uploadBtnSx}
              size="small"
              autoFocus
              variant="contained"
              color="success"
              disabled={buttonDisabled}>
              <CircularProgress
                style={{
                  color: 'white',
                  paddingTop: '0.375rem',
                  paddingBottom: '0.375rem',
                }}
                size="12%"
              />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

MultiFilesUploader.propTypes = {
  onCancel: PropTypes.func,
  onUpload: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  dragAndDropSectionTitle: PropTypes.string,
  dragAndDropText: PropTypes.string,
  cancelText: PropTypes.string,
  uploadText: PropTypes.string,
  acceptedFilesInfo: PropTypes.object,
  apiError: PropTypes.shape({
    flag: PropTypes.bool,
    messages: PropTypes.arrayOf(PropTypes.string),
  }),
  setApiError: PropTypes.func,
};

export default MultiFilesUploader;
