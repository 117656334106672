import {
  CustomInfoWindow,
  InfoWindowContent,
  ProgressBar,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.style';
import { useContext, useEffect, useState } from 'react';
import { INFO_WINDOW_CONTENT } from './InfoWindow.content';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import { GLOBAL_STRING_CONSTANTS } from 'utils/config';
import { getNextName } from 'utils/helper';

const LoadingInfoWindow = () => {
  const [name, setName] = useState('');
  const { farmInfo, currentFarmId } = useContext(participantInfoContext);

  useEffect(() => {
    const currentFarmIndex = farmInfo.findIndex(
      (farm) => farm.id === currentFarmId,
    );
    if (currentFarmIndex >= 0) {
      const existingFieldNames =
        farmInfo?.flatMap((farm) => farm.fields?.map((field) => field.value)) ??
        [];
      const newFieldName = getNextName(
        existingFieldNames,
        GLOBAL_STRING_CONSTANTS.default_field_name_prefix,
      );
      setName(newFieldName);
    } else {
      setName(GLOBAL_STRING_CONSTANTS.default_field_name_prefix);
    }
  }, []);

  return (
    <CustomInfoWindow>
      <div>
        <InfoWindowContent>{name}</InfoWindowContent>
        <InfoWindowContent>
          {INFO_WINDOW_CONTENT.boundaryLoadingText}
        </InfoWindowContent>
        <ProgressBar />
      </div>
    </CustomInfoWindow>
  );
};

export default LoadingInfoWindow;
