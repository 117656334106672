import { Typography } from '@mui/material';
import { EditModeLabelSx, IconSx } from './FarmEditModeLabel.style';
import {
  EDIT_MODE_BLOCKED,
  EDIT_MODE_DISABLED,
  EDIT_MODE_ENABLED,
} from 'utils/config';
import PropTypes from 'prop-types';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useContext } from 'react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { BRIGHTER_BLUE, BRIGHTER_RED } from 'theme/GlobalColors';

const FarmEditModeLabel = ({
  isEditModeOn,
  isdisabled,
  userName,
  activeUserId,
}) => {
  const {
    userDetails: { userId },
  } = useContext(userAuthRolesContext);
  const isEditBlocked = isdisabled && +activeUserId !== +userId && userName;
  return (
    <>
      {!isdisabled && (
        <>
          {!isEditModeOn && (
            <Typography sx={EditModeLabelSx({ labelColor: BRIGHTER_BLUE })}>
              <EditOutlinedIcon sx={IconSx({ color: BRIGHTER_BLUE })} />
              {EDIT_MODE_DISABLED}
            </Typography>
          )}
          {isEditModeOn && (
            <Typography sx={EditModeLabelSx({ labelColor: BRIGHTER_BLUE })}>
              <DoneOutlinedIcon sx={IconSx({ color: BRIGHTER_BLUE })} />
              {EDIT_MODE_ENABLED}
            </Typography>
          )}
        </>
      )}
      {isEditBlocked && (
        <Typography sx={EditModeLabelSx({ labelColor: BRIGHTER_RED })}>
          <LockOutlinedIcon sx={IconSx({ color: BRIGHTER_RED })} />
          {EDIT_MODE_BLOCKED}&nbsp;{userName}
        </Typography>
      )}
    </>
  );
};

export default FarmEditModeLabel;
FarmEditModeLabel.propTypes = {
  isEditModeOn: PropTypes.bool.isRequired,
  isdisabled: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  activeUserId: PropTypes.string,
};
