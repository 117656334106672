import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { ButtonWrapper, DialogStyles } from './UnsavedChangesModal.style';
import { containedBtnSx, outlinedBtnSx, textBtnSx } from 'theme/GlobalStyles';
import { MODAL_CONTENT } from './UnsavedChangesModal.content';
import PropTypes from 'prop-types';

const UnsavedChangesModal = ({
  open,
  handleLeaveWithoutSave,
  handleSaveAndLeave,
  handleClose,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} sx={DialogStyles.main}>
      <DialogTitle sx={DialogStyles.title}>
        <div>{MODAL_CONTENT.title}</div>
      </DialogTitle>
      <DialogContent sx={DialogStyles.content}>
        <div>{MODAL_CONTENT.content}</div>
      </DialogContent>
      <DialogActions sx={DialogStyles.actions}>
        <ButtonWrapper>
          <Button sx={textBtnSx} onClick={handleClose} disableElevation>
            {MODAL_CONTENT.cancelBtn}
          </Button>
          <Button
            sx={outlinedBtnSx}
            onClick={handleLeaveWithoutSave}
            disableElevation>
            {MODAL_CONTENT.leaveWithoutSaveBtn}
          </Button>
          <Button
            sx={containedBtnSx}
            onClick={handleSaveAndLeave}
            disabled={false}
            disableElevation>
            {MODAL_CONTENT.saveAndLeaveBtn}
          </Button>
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

// proptypes
UnsavedChangesModal.propTypes = {
  open: PropTypes.bool,
  handleLeaveWithoutSave: PropTypes.func,
  handleSaveAndLeave: PropTypes.func,
  handleClose: PropTypes.func,
};

export default UnsavedChangesModal;
