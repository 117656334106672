import styled from 'styled-components';
import { RED_FOCUSED_BORDER } from './GlobalColors';

export const TextWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  align-items: center;
  color: ${({ color }) => color};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const ErrorBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  border-radius: 2px 0px 0px 0px;
  border: 1px solid ${RED_FOCUSED_BORDER};
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const ContentBox = styled.div`
  display: flex;
  display: -webkit-flex;
  width: ${({ width }) => width};
  gap: 4px;
  justify-content: ${({ content }) => content};
  cursor: ${({ cursor }) => cursor};
`;
