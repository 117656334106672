import { Button } from '@mui/material';
import styled from 'styled-components';
import { DARK_GREEN, WHITE } from 'theme/GlobalColors';

export const mapStyle = {
  boxSizing: 'border-box',
  border: '1px solid transparent',
  width: '100%',
  height: '100%',
  padding: '9px 12px',
  borderRadius: '3px',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
  fontSize: '14px',
  outline: 'none',
  textOverflow: 'ellipses',
};

export const MapParentContainer = styled.div`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

export const BoundaryFilesAddBtn = styled(Button)(() => ({}));

export const relativePosition = {
  position: 'relative',
};

export const normalCase = {
  textTransform: 'none',
};

export const addBoundaryBtnStyles = {
  color: DARK_GREEN,
  border: `1px solid ${DARK_GREEN}`,
  position: 'absolute',
  textTransform: 'none',
  backgroundColor: WHITE,
  right: '3.438rem',
  bottom: '4%',

  '&:hover': {
    backgroundColor: WHITE,
  },
};
