import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_GREEN,
  QUILL_GRAY,
  TYPOGRAPHY_GRAY,
  WHITE,
  DARK_CHARCOAL,
  GLOBAL_BLUE,
  BLACK,
  BLUE,
  PAGE_BACKGROUND_COLOR,
  BLUISH_CYAN,
  NEW_HOVER_GREEN_BACKGROUND,
  NEW_FOCUS_DARK_GREEN,
  TEAL_GREEN_HOVER,
  NEW_DARK_GREEN,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { secondaryButtonStyle } from 'components/FormComponents/FormStyles';

export const TextWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  gap: ${({ gap }) => gap};
`;
export const MainContainer = styled.div`
  display: flex;
  display: -webkit-flex;
  padding: ${({ padding }) => padding};
  flex-direction: column;
  background-color: white;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
`;
export const buttonStyleContinue = (disabled) => {
  return {
    textTransform: 'none',
    background: checkTernaryCondition(disabled, QUILL_GRAY, DARK_GREEN),
    color: checkTernaryCondition(disabled, COOL_GRAY, WHITE),
    padding: '0.375rem 1rem',
    '&:hover': {
      background: checkTernaryCondition(disabled, QUILL_GRAY, DARK_GREEN),
      color: checkTernaryCondition(disabled, COOL_GRAY, WHITE),
    },
    '&.Mui-disabled': {
      color: BLACK,
      backgroundColor: QUILL_GRAY,
    },
  };
};
export const buttonStyleClose = (disabled) => {
  return {
    textTransform: 'none',
    background: WHITE,
    border: `solid 1px ${COOL_GRAY}`,
    padding: '0.344rem 1rem',
    color: checkTernaryCondition(disabled, COOL_GRAY, DARK_GREEN),
    '&:hover': {
      background: WHITE,
      color: checkTernaryCondition(disabled, COOL_GRAY, DARK_GREEN),
    },
    '&.Mui-disabled': {
      border: `1px solid ${TYPOGRAPHY_GRAY}`,
    },
  };
};
export const buttonStyleCancel = () => {
  return {
    textTransform: 'none',
    background: WHITE,
    color: DARK_GREEN,
    '&:hover': {
      background: WHITE,
      color: DARK_GREEN,
    },
  };
};
export const toastStyle = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
export const ParentContainer = styled.div`
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  min-height: 100vh;
`;
export const LeftSectionWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  background-color: white;
  border-right: solid 1px #d0d0ce;
  width: 19rem;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;
export const DatePickersWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
export const LineItemWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
`;
export const AddOptionWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
  cursor: pointer;
`;
export const CropYearSectionWrapper = styled.div`
  display: flex;
  border-radius: 2px;
  flex-direction: column;
`;
export const BaseLineFieldInfoWrapper = styled.div`
  display: flex;
  gap: 1rem;
  border: 1px solid #d0d0ce;
  flex-direction: column;
  padding: 0.5rem;
`;
export const TabsStyle = styled.div`
  display: flex;
  display: -webkit-flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
`;

export const ProjectNameWrapper = styled.div`
  display: flex;
`;

export const ProjectName = styled.div`
  color: ${DARK_CHARCOAL};
  text-align: left;
  font: 400 20px/140% 'OpenSans-Regular', sans-serif;
  margin: 0.5rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  text-wrap: nowrap;
`;
export const projectNameStyle = {
  padding: '0rem 2rem',
};
export const CustomBreadCrumbItem = styled(Typography)`
  color: ${COOL_GRAY};
  text-align: left;
  font: 400 12px/20px 'OpenSans-Regular', sans-serif;
  position: relative;
  display: flex;
`;

export const CustomBreadCrumbLink = styled(Link)`
  color: ${GLOBAL_BLUE};
  text-align: left;
  font: 400 12px/20px 'OpenSans-Regular', sans-serif;
  position: relative;
  text-decoration: none;
  display: flex;
`;

export const MainWrapper = styled.div`
  height: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  padding: 0rem;
  position: relative;
  overflow: hidden;
`;

export const TabberBoxCustomStyle = {
  position: 'sticky',
  top: 0,
  zIndex: 100,
  backgroundColor: WHITE,
};

export const DividerStyles = {
  position: 'absolute',
  left: '0',
  right: '0',
  top: '2.5rem',
  height: '5px',
};

export const backButtonStyle = {
  padding: 0,
  color: BLUE,
  textTransform: 'none',
  fontSize: '0.875rem',
  fontWeight: 400,
  minWidth: 'unset',
};
export const footerStyle = {
  backgroundColor: PAGE_BACKGROUND_COLOR,
  padding: 0,
  marginTop: 'auto',
};
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const HeaderButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
export const CollapseExpandButtonSx = {
  ...secondaryButtonStyle,
  width: '5.688rem',
  height: '1.75rem',
  backgroundColor: WHITE,
  padding: '0rem',
  borderRadius: '0.25rem',
  fontSize: '0.875rem',
  lineHeight: '1.192rem',
  '&:hover': {
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: NEW_FOCUS_DARK_GREEN,
    border: `0.063rem solid ${BLUISH_CYAN}`,
  },
  '&:focus': {
    color: NEW_FOCUS_DARK_GREEN,
    backgroundColor: TEAL_GREEN_HOVER,
    border: `2px solid ${NEW_DARK_GREEN}`,
  },
};

export const TabComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const customTabPanelBoxStyle = {
  overflow: 'hidden',
  '&.MuiBox-root': { height: '100vh' },
};

export const ParticipantFlowFooterWrapper = styled.div`
  marginTop: 'auto',
  marginBottom: '-0.5em',
`;
