export const PAGE_CONTENT = {
  transaction_purchase_heading: 'Add new purchase transaction',
  transaction_name_label: 'Transaction Name',
  transaction_name_placeholder: 'Type a reference name for your transaction',
  transaction_type_label: 'What is the transaction type?',
  transaction_type_radio_1: 'Purchase',
  transaction_type_radio_2: 'Sale',
  transaction_purchase_category_label: 'Category of purchase',
  transaction_purchase_category_placeholder: 'Select category',
  transaction_purchase_status_label:
    'What is the purchase status of this transaction?',
  transaction_purchase_status_radio_1: 'In Negotiation',
  transaction_purchase_status_radio_2: 'Contracted',
  transaction_status_radio_2_1: 'Pitched',
  transaction_status_radio_2_2: 'Committed',
  purchased_from_label: 'Purchased from',
  purchased_from_placeholder: 'Type to add stakeholder name',
  transaction_cost_label: 'Total Fixed Transaction Cost Amount (if applicable)',
  option_field: '(optional)',
  type_amount_placeholder: 'Type amount',
  payment_type_label: 'Payment Type',
  payment_type_placeholder: 'Select payment type',
  share_type_label: 'Benefit Share Assumption Type',
  share_type_placeholder: 'Select type',
  profit_share_percentage_label: 'Benefit Share Percentage',
  profit_share_percentage_placeholder: 'Type percentage',
  percentage_subtext: '%',
  cost_structure_label: 'Fixed Cost Structure',
  cost_structure_placeholder: 'Select Structure',
  add_project_text: 'Add project',
  submit_button_label: 'Submit',
  cancel_button_label: 'Cancel',
  transaction_name_error: 'A transaction name is required for submission',
  transaction_category_error:
    'A category of purchase selection is required for submission',
  transaction_signed_error:
    'A transaction signed on date is required for submission',
  transaction_subtype_error:
    'A transaction sub-type is required for submission',
  transaction_status_error:
    'A status for this transaction is required for submission',
  stakeholder_error:
    'A purchased from stakeholder name is required for submission',
  cost_structure_error: 'A payment type is required for submission',
  assumption_type_error:
    'A profit share assumption type is required for submission',
  share_percentage_error:
    'A profit share percentage is required for submission when benefit share assumption type is not none',
  confirm_modal_title: 'Please confirm you want to add ',
  edit_modal_title: 'Please confirm you want to update ',
  date_signed_label: 'Transaction signed on',
  associated_type_label: 'Associated investor / financer',
  associated_type_placeholder: 'Select Associated',
  notes_label: 'Notes',
  notes_placeholder: 'Type your notes',
  edit_transaction_heading: 'Edit purchase transaction',
  view_transaction_heading: 'Purchase Transaction',
  edit_button_label: 'Edit',
  delete_button_label: 'Delete',
  delete_message_line1:
    'Are you sure you want to delete and all data associated with it?',
  success: 'SUCCESS',
  success_message: 'has been deleted from your portfolio.',
  failed: 'FAILED',
  failed_message:
    'couldn’t be deleted due to a technical error. Please, try again.',
  delete_message_line2:
    'Any associated projects or stakeholders will not be deleted.',
  denied: 'DENIED',
  projects_label: 'Projects',
};

export const RouteIncludes = {
  view: 'view',
};

export const getValueOrDefault = (value) => {
  return value === null ? '' : value;
};

export const convertToDate = (value) => {
  return new Date(value.year, value.month - 1, value.dayOfMonth);
};

export const ConditionConstants = {
  ROFR: 'ROFR',
  Contracted: 'Contracted',
  InNegotiation: 'In Negotiation',
  UnderConsideration: 'Under consideration',
};

export const validateProjectNames = (projects, projectDetail) => {
  if ('value' in projectDetail) {
    return (
      projects
        .filter(
          (project) =>
            projects.find((dl) => dl.value === project.value).id === project.id,
        )
        .filter(
          (projectData) =>
            projectData.value === projectDetail.value &&
            projectData.id !== projectDetail.id,
        ).length > 0
    );
  }
};

export const validateVariableCost = (project, payment) => {
  return (
    project.paymentDetails
      .filter(
        (detail) =>
          project.paymentDetails.find(
            (dl) => dl.variableCost === detail.variableCost,
          ).id === detail.id,
      )
      .filter(
        (cost) =>
          cost.variableCost === payment.variableCost && cost.id !== payment.id,
      ).length > 0
  );
};

export const validateVintageYears = (payment, vintageDetail) => {
  return (
    payment.vintageDetails
      .filter(
        (detail) =>
          payment.vintageDetails.find(
            (dl) =>
              dl.vintageStart === detail.vintageStart &&
              dl.vintageEnd === detail.vintageEnd,
          ).id === detail.id,
      )
      .filter(
        (vintage) =>
          vintage.vintageStart === vintageDetail.vintageStart &&
          vintage.vintageEnd === vintageDetail.vintageEnd &&
          vintage.id !== vintageDetail.id,
      ).length > 0
  );
};

export const validateDeliveryDetails = (quantity, deliveryList) => {
  const totalAmount = deliveryList.reduce(
    (total, delivery) => total + parseFloat(delivery.projectedCreditDelivery),
    0,
  );
  return parseFloat(quantity) !== totalAmount;
};
