import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SnacBarWrapper,
  SnackBarIcon,
  SnackBarMessage,
  CloseSnackBar,
  IconStyle,
} from './CustomSnackbar.style';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { ReactComponent as ClearIcon } from '../../assets/icons/clearIcon.svg';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { ReactComponent as WarningIcon } from '../../assets/icons/warningIcon.svg';
import {
  COOL_GRAY,
  WHITE,
  DARK_CHARCOAL,
  RED,
  TOAST_ERROR_BG,
  TOAST_SUCCESS,
  TOAST_SUCCESS_BORDER,
  TOAST_WARNING_BG,
  TOAST_WARNING_BORDER,
} from 'theme/GlobalColors';

const CustomSnackbar = ({ type, message }) => {
  const [toastStyle, setToastStyle] = useState({});

  useEffect(() => {
    if (type === 'error') {
      setToastStyle({
        backgroundColor: TOAST_ERROR_BG,
        colorStroke: WHITE,
        border: RED,
        icon: <ReportProblemOutlinedIcon style={IconStyle} />,
      });
    } else if (type === 'warning') {
      setToastStyle({
        backgroundColor: TOAST_WARNING_BG,
        colorStroke: COOL_GRAY,
        border: TOAST_WARNING_BORDER,
        icon: <WarningIcon />,
      });
    } else {
      setToastStyle({
        backgroundColor: TOAST_SUCCESS,
        color: DARK_CHARCOAL,
        colorStroke: COOL_GRAY,
        border: TOAST_SUCCESS_BORDER,
        icon: <CheckCircleOutlineOutlinedIcon style={IconStyle} />,
      });
    }
  }, [type]);

  return (
    <SnacBarWrapper styleValues={toastStyle}>
      <SnackBarIcon>{toastStyle?.icon}</SnackBarIcon>
      <SnackBarMessage>{message}</SnackBarMessage>
      <CloseSnackBar styleValues={toastStyle}>
        <ClearIcon />
      </CloseSnackBar>
    </SnacBarWrapper>
  );
};

CustomSnackbar.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

export default CustomSnackbar;
