import { RED } from 'theme/GlobalColors';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { stringToArray } from './ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.content';
import {
  checkTernaryCondition,
  isValueEmptyNullorUndefined,
  rotateData,
} from 'utils/helper';
export const PARTICAPNT_INFO = [
  {
    id: 1,
    status: '',
    value: '',
  },
];

export const FARM_INFO = [
  {
    id: -1,
    status: 'in progress',
    value: 'Farm 1',
    fields: [],
    isApiPopulated: false,
  },
];

export const FARM_AND_CONTRIBUTOR_INFO = [
  {
    id: 1,
    status: 'Not Started',
    value: 'Add farms',
  },
];

export const toastStyle = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const PAGE_CONTENT = {
  save_and_close_btn: 'Save & Close',
  enrollmentLabel: ' Enrollment',
  cancel_btn: 'Cancel',
  PARTICIPANT_CONTRIBUTOR: 'PARTICIPANT_CONTRIBUTOR',
  review_and_enroll_btn: 'Review & Enroll',
  fieldAddTypeInstructionText: 'Add fields using selection method. ',
  enrollmentFormPath: '/stakeholders/participant-data-collection',

  unsavedChangesPromptMsg:
    "You've made updates that haven't been saved yet. Would you like to save before closing?",
  participant: 'Participant',
  submit_complete_modal_title: 'All enrollment forms are complete',
  submit_complete_modal_content:
    'You’re ready to review and submit or you may continue to add more fields to enroll.',
  cancel_btn_label: 'Cancel',
  continue_btn_label: 'Continue to add fields',
  review_btn_label: 'Review and submit',
  farm_and_contributor_title: 'Farm and contributor',
  noFarmsDefaultText:
    'Farms added during farm setup will be listed here for you or your contributors to continue adding farm and field info.',
  completed: 'Completed',
  complete: 'complete',
  in_progress: 'In Progress',
  back: 'Back',
  PARTICIPANT_OWNER: 'PARTICIPANT_OWNER',
  PARTICIPANT: 'PARTICIPANT',
  addFarms: 'Add farms',
  ADD_FIELD: 'Add fields',
};

export const SHAPE_FILE_UPLOAD_CONTENT = {
  modalTitle: 'Upload field boundary shape file(s)',
  dragAndDropSectionTitle:
    'Would you like to upload any farm or field boundary shape files?',
  dragAndDropInsideText: 'Drag and drop shape file here',
  cancelText: 'Cancel',
  uploadText: 'Upload',
  acceptedMimeType: 'application/zip',
  acceptedExtensions: ['.zip'],
  maxNoOfFiles: 1,
  maxFileSize: 1024 * 1024 * 6, //6MB
  minFileSize: 1024, //1KB
  infoText:
    'Please upload one file of each format .shp, .prj, and .dbf  in a .zip file.',
  infoTextList: [
    'Please upload one file of each format .shp, .prj, and .dbf  in a .zip file.',
    'Please note any duplicate or overlapping fields already added to map will be replaced.',
  ],
  fileNotSupportedError:
    'File not supported. Please upload a .zip file containing .shp, .prj, .dbf, .xml files',
  fieldOverlappingInfoText:
    'Please note any duplicate or overlapping fields already added to map will be replaced.',
};

export const breadcrumbData = [
  { id: uniqueId(), title: 'Home' },
  { id: uniqueId(), title: 'Participant profile' },
  { id: uniqueId(), title: 'Farm enrollment' },
  { id: uniqueId(), title: 'Field selection' },
  { id: uniqueId(), title: 'Field enrollment' },
];

export const fieldAddTypes = ['Boundary file', 'Draw boundary', 'Drop pin'];

export const convertStatus = (status) => {
  if (status === 'Completed') return 'complete';
};

export const mapControlOptions = {
  mapTypeId: 'satellite',
  zoom: 10,
  labels: true,
};

export const errorPolygonOptions = {
  fillColor: RED,
  strokeColor: RED,
};

export const NAVIGATION_PROPS = {
  STATUS: {
    COMPLETED: 'complete',
    NOT_COMPLETED: 'in progress',
  },
  FARM_TYPE: 'farm',
  FIELD_TYPE: 'field',
  PROFILE_TYPE: 'profile',
};

// Note: This function creates an array of pages in enrollment data collection
export function constructPages(farmList, profileStatus) {
  const pages = [
    {
      type: NAVIGATION_PROPS.PROFILE_TYPE,
      status: checkTernaryCondition(
        profileStatus === PAGE_CONTENT.completed,
        NAVIGATION_PROPS.STATUS.COMPLETED,
        NAVIGATION_PROPS.STATUS.NOT_COMPLETED,
      ),
    },
  ];

  for (const farm of farmList) {
    const farmPage = {
      type: NAVIGATION_PROPS.FARM_TYPE,
      id: farm.id,
      status: checkTernaryCondition(
        farm.formValidationFlag,
        NAVIGATION_PROPS.STATUS.COMPLETED,
        NAVIGATION_PROPS.STATUS.NOT_COMPLETED,
      ),
    };
    pages.push(farmPage);

    for (const field of farm.fields) {
      const fieldPage = {
        type: NAVIGATION_PROPS.FIELD_TYPE,
        id: field.id,
        status: checkTernaryCondition(
          field.status === PAGE_CONTENT.completed,
          NAVIGATION_PROPS.STATUS.COMPLETED,
          NAVIGATION_PROPS.STATUS.NOT_COMPLETED,
        ),
        value: field.value,
        farmId: farm.id,
      };
      pages.push(fieldPage);
    }
  }

  return pages;
}

function getCurrentPageIndex(pages, isFarmWebform, currentFarm, currentField) {
  for (const page of pages) {
    if (page.type === NAVIGATION_PROPS.FARM_TYPE && isFarmWebform) {
      if (page.id === currentFarm.id) {
        return pages.indexOf(page);
      }
    } else if (page.type === NAVIGATION_PROPS.FIELD_TYPE && !isFarmWebform) {
      if (page.id === currentField.fieldId) {
        return pages.indexOf(page);
      }
    }
  }
}

export const handleNextInProgrssSwitcher = ({
  updatedFarmInfo,
  currentFarm,
  currentField,
  isFarmWebform,
  participantProfileStatus,
  handleSaveAndContinueOnMapView = false,
}) => {
  const pages = constructPages(updatedFarmInfo, participantProfileStatus);
  const currentPageIndex = getCurrentPageIndex(
    pages,
    isFarmWebform,
    currentFarm,
    currentField,
  );

  // updatedPages are pages in changed order, current page stays at the 0th index, pages before current page gets appended to the end
  const updatedPages = rotateData(pages, currentPageIndex);

  // skip the current page and find the next page that is not completed
  const nextPage = updatedPages.find(
    (page, index) =>
      index > 0 && page.status === NAVIGATION_PROPS.STATUS.NOT_COMPLETED,
  );
  if (handleSaveAndContinueOnMapView) return nextPage ?? updatedPages[1];

  return nextPage;
};

export const checkIsEnrollmentCompleted = (
  farmData,
  formValidationStatus,
  profileStatus,
  isParticipantContributor,
) => {
  return (
    farmData.every(
      (farm) =>
        (farm.status.toLowerCase() === 'completed' ||
          farm.status.toLowerCase() === 'complete') &&
        farm.fields &&
        farm.fields.every(
          (field) =>
            field?.status?.toLowerCase() === 'completed' ||
            field?.status?.toLowerCase() === 'complete',
        ),
    ) &&
    formValidationStatus &&
    (profileStatus === 'complete' ||
      profileStatus === 'Completed' ||
      profileStatus === 'CP' ||
      isParticipantContributor)
  );
};

export const enrollmentInstanceStatus = {
  approved: 'APPROVED',
  notApproved: 'NOT_APPROVED',
};

export const getFieldAreaExceedingError = (fieldAreaSum, farmArea) => {
  return `Total sum of field area uploaded of ${fieldAreaSum.toFixed(
    2,
  )} acres is greater than the total farm area entered of ${farmArea} acres. Please update the farm area entered.`;
};

const getFmsList = (data) => {
  if (typeof data === 'string') {
    return stringToArray(data);
  }
  return [];
};

export const formatBulkFarmSubmitData = (
  farmsData,
  projectId,
  enrollmentInstanceId,
  projectCycleId,
) => {
  return farmsData.map((farm) => {
    return {
      projectCycleId: Number(projectCycleId),
      agronomistUse: farm.agronomistUse ?? '',
      agronomistUseOrganization: farm.agronomistUseOrganization ?? '',
      carbonRightsAssociated: farm.carbonRightsAssociated,
      isLivestockRaised: farm.isLivestockRaised,
      city: farm.farmCity,
      contributorStatus: checkTernaryCondition(
        farmHasErrors(farm),
        farmAndContributorTabStatus.inProgress,
        farmAndContributorTabStatus.completed,
      ),
      county: farm.farmCounty,
      cropArea: farm.cropArea,
      /* csafFederalFunding: farm.csafFederalFunding,
      csafImplemented: farm.csafImplemented ?? '',
      csafMarketIncentives: farm.csafImplemented,
      csafNonProfitFunding: farm.csafImplemented,
      csafStateFunding: farm.csafImplemented, */
      enrollmentInstanceId: Number(enrollmentInstanceId),
      entityName: farm.entityName,
      extentCsafImplemented: farm.extentCsafImplemented,
      farmId: checkTernaryCondition(
        typeof farm.farmId === 'string',
        0,
        farm.farmId,
      ),
      farmLivestock1Id: farm.farmLivestock1Id ?? '',
      farmLivestock1headcount: farm.farmLivestock1headcount ?? '',
      farmLivestock2Id: farm.farmLivestock2Id ?? '',
      farmLivestock2headcount: farm.farmLivestock2headcount ?? '',
      farmLivestock3Id: farm.farmLivestock3Id ?? '',
      farmLivestock3headcount: farm.farmLivestock3headcount ?? '',
      farmName: farm.farmName ?? '',
      farmZipcode: farm.farmZipcode,
      fieldsOrganicStatus: farm.fieldsOrganicStatus,
      firstName: farm.firstName,
      fmsList: getFmsList(farm.fmsList),
      fmsUsed: farm.fmsUsed,
      /*  forestArea: farm.forestArea, */
      formStatus: farm.formStatus ?? 'Not Started',
      formValidationFlag: farm.formValidationFlag ?? false,
      fsaId: farm.fsaId,
      fsaIdType: farm.fsaIdType,
      internalProjectId: Number(projectId),
      lastName: farm.lastName ?? '',
      /*   livestockArea: farm.livestockArea, */
      mailingAddress: farm.farmMailingAddress,
      /*       organicStatus: farm.farmOrganicStatus, */
      soilSampling: farm.soilSampling,
      state: farm.farmState,
      totalFarmAcreage: farm.totalFarmAcreage,
    };
  });
};

export const farmAndContributorTabStatus = {
  inProgress: 'In Progress',
  completed: 'Completed',
  notStarted: 'Not Started',
};

// Note: Refers to add farm and contributor page
export const getFarmErrors = (farm) => {
  return {
    farmNameRequiredError: isValueEmptyNullorUndefined(farm.farmName),
    fsaIdRequiredError: isValueEmptyNullorUndefined(farm.fsaId),
    fsaIdTypeRequiredError: isValueEmptyNullorUndefined(farm.fsaIdType),
    entityNameRequiredError:
      farm.fsaIdType === 'Entity' &&
      isValueEmptyNullorUndefined(farm.entityName),
    firstNameRequiredError:
      farm.fsaIdType === 'Individual' &&
      isValueEmptyNullorUndefined(farm.firstName),
    lastNameRequiredError:
      farm.fsaIdType === 'Individual' &&
      isValueEmptyNullorUndefined(farm.lastName),
    mailingAddressRequiredError: isValueEmptyNullorUndefined(
      farm.farmMailingAddress,
    ),
    cityRequiredError: isValueEmptyNullorUndefined(farm.farmCity),
    stateRequiredError: isValueEmptyNullorUndefined(farm.farmState),
    countyRequiredError: isValueEmptyNullorUndefined(farm.farmCounty),
    zipCodeRequiredError: isValueEmptyNullorUndefined(farm.farmZipcode),
  };
};

export const farmHasErrors = (farm) => {
  const farmErrorObj = getFarmErrors(farm);
  return Object.values(farmErrorObj).includes(true);
};

export const LEFT_MENU_SECTION_HEADERS = {
  profileSection: 'Participant',
  farmAndContributorSection: 'Farm and contributor',
  farmAndFieldsSection: 'Farms',
};

export const getAddFarmRequestBody = ({
  internalProjectId,
  enrollmentInstanceId,
  farmName,
  projectCycleId,
}) => {
  return {
    internalProjectId: +internalProjectId,
    enrollmentInstanceId: +enrollmentInstanceId,
    farmName: farmName,
    fsaId: '',
    contributorStatus: 'In Progress',
    totalFarmAcreage: '',
    /*     csafImplemented: '',
    csafFederalFunding: '',
    csafStateFunding: '',
    csafNonProfitFunding: '',
    csafMarketIncentives: '', */
    fmsList: [],
    agronomistUseOrganization: '',
    cropArea: '',
    /*     livestockArea: '',
    forestArea: '', */
    formStatus: 'In Progress',
    formValidationFlag: false,
    fsaIdType: '',
    firstName: '',
    lastName: '',
    entityName: '',
    mailingAddress: '',
    city: '',
    county: '',
    state: '',
    farmZipcode: null,
    extentCsafImplemented: '',
    farmLivestock1Id: '',
    farmLivestock1headcount: '',
    farmLivestock2Id: '',
    farmLivestock2headcount: '',
    farmLivestock3Id: '',
    farmLivestock3headcount: '',
    projectCycleId: +projectCycleId,
    farmId: 0,
    isLivestockRaised: null,
  };
};

export const UNSAVED_CHANGES_MODAL_PROPS_INIT = {
  isOpen: false,
  nextTab: -1,
  targetFieldId: null,
  targetFieldName: '',
  targetFarmId: null,
  targetFarmName: '',
  navigateNextTo: '',
  triggeredFrom: '',
};

export const FIELD_SUBMIT_TRIGGER_INIT = {
  trigger: false,
  navigateTo: '', // Tab, Field, Farm --> Determines where to navigate after submitting the field data
  // target ids will be used based on the navigateTo value
  targetFarmId: null,
  targetFarmName: '',
  targetFieldId: null,
  targetFieldName: '',
  targetTab: null, // index of next tab to switch to
};
export const CONTRACTING = 'CONTRACTING';
export const CROP_LAND_USE = 'CROP_LAND_USE';
export const ADD_PIPELINE_AREA = 'ADD_PIPELINE_AREA';
export const PIPELINE_CONTRACTED_UNIT = 'PIPELINE_CONTRACTED_UNIT';
export const TRACTOR_VEHICLE_FUEL = 'TRACTOR_VEHICLE_FUEL';
export const FUEL_CONSUMPTION = 'FUEL_CONSUMPTION';
export const FERTILIZER = 'FERTILIZER';
export const FERTILIZER_APPLICATION_RATE = 'FERTILIZER_APPLICATION_RATE';
export const TILLAGE = 'TILLAGE';
export const SOIL_AMENDMENT_AMOUNT_APPLIED = 'SOIL_AMENDMENT_AMOUNT_APPLIED';
export const DEPTH_OF_TILLAGE = 'DEPTH_OF_TILLAGE';
export const IRRIGATION_VOLUME = 'IRRIGATION_VOLUME';
export const PLANTING_DENSITY = 'PLANTING_DENSITY';
export const SEEDING_DEPTH = 'SEEDING_DEPTH';
export const CROP_AREA = 'CROP_AREA';
export const IMPLEMENTED_ACTIVITY_SUMMARY = 'IMPLEMENTED_ACTIVITY_SUMMARY';
export const ACTUAL_TOTAL_ACTIVITY_AREA = 'ACTUAL_TOTAL_ACTIVITY_AREA';
export const PROOF_OF_IMPLEMENTATION = 'PROOF_OF_IMPLEMENTATION';
export const ESTIMATED_IMPLEMENTATION_COST = 'ESTIMATED_IMPLEMENTATION_COST';
export const FIELD_ENROLLMENT = 'FIELD_ENROLLMENT';
export const FIELD_AREA = 'FIELD_AREA';
export const FARM_ENROLLMENT = 'FARM_ENROLLMENT';
export const FARM_AREA = 'FARM_AREA';
export const CROPLAND_AREA = 'CROPLAND_AREA';
export const ANNUAL_YIELD = 'ANNUAL_YIELD';
export const TOTAL_CONTRACTED_AREA = 'TOTAL_CONTRACTED_AREA';
export const INCENTIVE_RATE = 'INCENTIVE_RATE';
export const PLANNED_TOTAL_ACTIVITY_AREA = 'PLANNED_TOTAL_ACTIVITY_AREA';
export const CONTRACT_TOTAL_ACRES = 'Contract Total Acres';
export const PIPELINE_ACTIVITY_PLANNED_AREA = 'Pipeline activity planned area';
export const PIPELINE_CONTRACTED_UNITS = 'Pipeline contracted units';

//Find UOM from list
export const findUnitOfMeasure = (mainList, type) => {
  return mainList?.find((item) => item[type] !== undefined)[0]?.uomNameDisplay;
};

// Activity categories for implemented activities
export const ACTIVITY_CATEGORIES = {
  coverCrop: 'Cover crop',
  residueTillage: 'Residue + tillage management',
  soilAmendment: 'Soil carbon amendment',
};

// Activity practices for implemented activities
export const ACTIVITY_PRACTICES = {
  NO_TILLAGE: 'No tillage',
  REDUCED_TILLAGE: 'Reduced tillage',
  COVER_CROP: 'Cover crop',
};

export const ENROLLMENT_SUBMITTED_MESSAGE = 'Enrollment is already submitted.';
