import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const EditPurchaseTransactionFormDirtyContext = createContext({});

export const EditPurchaseTransactionFormDirtyProvider = ({ children }) => {
  const [dirty, setDirty] = useState(false);
  const markFormDirty = () => setDirty(true);

  const value = React.useMemo(
    () => ({
      dirty,
      markFormDirty,
    }),
    [dirty],
  );

  return (
    <EditPurchaseTransactionFormDirtyContext.Provider value={value}>
      {children}
    </EditPurchaseTransactionFormDirtyContext.Provider>
  );
};
EditPurchaseTransactionFormDirtyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
