const getRandomNumber = () =>
  crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;

export function generateNonce() {
  const length = 32;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(getRandomNumber() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}
/**
 * @description A custom decryption function
 * @param {String} encryptedValue
 * @param {String} key
 * @returns {String}
 */
export function decrypt(encryptedValue, key) {
  const decoded = atob(encryptedValue);
  let result = [];
  for (let i = 0; i < decoded.length; i++) {
    let charCode = decoded.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    charCode = charCode - key.charCodeAt(i % key.length);
    result.push(charCode);
  }
  return String.fromCharCode(...result);
}
