import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { menuItemSx, zeroPaddingSx } from './CustomOptionsButton.style';
import { COOL_GRAY } from 'theme/GlobalColors';
import PropTypes from 'prop-types';
import { checkTernaryCondition } from 'utils/helper';

const CustomOptionsButton = ({ options = [], iconAlignment = 'vertical' }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        data-testid="three-dots-btn"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ ...zeroPaddingSx, color: COOL_GRAY }}>
        {checkTernaryCondition(
          iconAlignment === 'vertical',
          <MoreVertIcon />,
          <MoreHorizIcon />,
        )}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={zeroPaddingSx}>
        {options.map((option) => {
          return (
            <MenuItem
              key={uniqueId()}
              disabled={option.disabled ?? false}
              sx={menuItemSx}
              onClick={() => {
                option.clickHandler();
                setAnchorEl(false);
              }}>
              {option.itemLabel}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

CustomOptionsButton.propTypes = {
  options: PropTypes.array,
  iconAlignment: PropTypes.string,
};

export default CustomOptionsButton;
