import React from 'react';
import PropTypes from 'prop-types';
import Tabber from 'components/Tabber/Tabber.react';

const ProfileInfoWithTabber = ({ currentTab, setCurrentTab, tabList }) => {
  return (
    <Tabber
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      tabList={tabList}
    />
  );
};

ProfileInfoWithTabber.propTypes = {
  currentTab: PropTypes.number,
  setCurrentTab: PropTypes.func,
  tabList: PropTypes.array,
};

export default ProfileInfoWithTabber;
