export const INFO_WINDOW_CONTENT = {
  acceptBtnText: 'Accept',
  deleteBtnText: 'Delete',
  boundaryLoadingText: 'Field boundary loading...',
  fieldNameExistsErrorMsg: 'Field name already exists',
  fieldAreaUnit: ' acres',
  fieldAreaExceedingFarmAreaErrorMsg:
    'Field area can’t be larger than total farm area.',
  FIELD_AREA_ZERO_ERROR_MSG: 'Field area can’t be 0',
};
