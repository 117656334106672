import { useState } from 'react';

export const useForm = (formModal) => {
  const [formValue, setFormValue] = useState(formModal);

  const formFieldChangeHandler = (field) => {
    setFormValue({
      ...formValue,
      [field.target.name]: field.target.value,
    });
  };

  /**
   * Function added a custom value to the form field
   * @param {} key field name to be stored
   * @param {} value field value to be stored
   */
  const customFieldChangeHandler = (key, value) => {
    setFormValue({
      ...formValue,
      [key]: value,
    });
  };

  const nestedFieldChangeHandler = (key, id, value) => {
    setFormValue({
      ...formValue,
      [key]: {
        ...formValue[key],
        [id]: value,
      },
    });
  };

  return {
    formValue,
    formFieldChangeHandler,
    customFieldChangeHandler,
    nestedFieldChangeHandler,
    setFormValue,
  };
};
