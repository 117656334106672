import {
  AcceptBtn,
  AcceptanceBtnWrapper,
  CustomInfoWindow,
  DeleteBtn,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.style';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { INFO_WINDOW_CONTENT } from './InfoWindow.content';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import {
  ErrorWrapper,
  FieldNameInput,
  ContentWrapper,
} from './InfoWindow.style';
import {
  checkTernaryCondition,
  findObjectWithKey,
  getNextName,
} from 'utils/helper';
import { RED } from 'theme/GlobalColors';
import { GLOBAL_STRING_CONSTANTS } from 'utils/config';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import { FIELD_AREA } from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';

const InfoWindowContent = ({
  onAccept,
  onDelete,
  fieldArea,
  totalFarmArea,
}) => {
  const {
    unitOfMeasureList: { fieldEnrolment },
  } = useContext(uomContext);
  const [name, setName] = useState('');
  const { farmInfo, currentFarmId, switchState } = useContext(
    participantInfoContext,
  );
  // Note: We are allowing a buffer of 10% on the farmArea, eg: if farmArea is 10acres, fieldArea can be <=11acres(10acres + 10% of 10acres)
  const fieldAreaExceedingFarmArea = fieldArea > totalFarmArea * 1.1;
  const isFieldAreaZero = +fieldArea === 0;
  const isFieldNameUnique = useMemo(() => {
    let count = 0;
    for (const farm of farmInfo) {
      const fields = farm.fields;
      for (const field of fields) {
        if (field.value === name.trim()) count++;
      }
    }

    return count === 0;
  }, [name]);

  useEffect(() => {
    const currentFarmIndex = farmInfo.findIndex(
      (farm) => farm.id === currentFarmId,
    );
    if (currentFarmIndex >= 0) {
      const existingFieldNames =
        farmInfo?.flatMap((farm) => farm.fields?.map((field) => field.value)) ??
        [];
      const newFieldName = getNextName(
        existingFieldNames,
        GLOBAL_STRING_CONSTANTS.default_field_name_prefix,
      );
      setName(newFieldName);
    } else {
      setName(GLOBAL_STRING_CONSTANTS.default_field_name_prefix);
    }
  }, [farmInfo]);
  //Validator for the google map input
  function validateNameHandler(inputValue) {
    const namePattern = /^[a-zA-Z0-9 ,'.-]*$/;
    return namePattern.test(inputValue);
  }

  return (
    <CustomInfoWindow>
      <div>
        {fieldAreaExceedingFarmArea ? (
          <ContentWrapper>{name}</ContentWrapper>
        ) : (
          <FieldNameInput
            value={name}
            onChange={(event) =>
              validateNameHandler(event.target.value) &&
              setName(event.target.value)
            }
            isError={!isFieldNameUnique}
            data-testid="name-input"
            disabled={!switchState}
          />
        )}
        {!isFieldNameUnique && (
          <ErrorWrapper>
            {INFO_WINDOW_CONTENT.fieldNameExistsErrorMsg}
          </ErrorWrapper>
        )}
        <ContentWrapper
          color={
            (fieldAreaExceedingFarmArea || isFieldAreaZero) && RED
          }>{`${fieldArea} ${
          findObjectWithKey(fieldEnrolment, FIELD_AREA)?.FIELD_AREA[0]
            ?.uomNameDisplay
        }`}</ContentWrapper>

        {(fieldAreaExceedingFarmArea || isFieldAreaZero) && (
          <ContentWrapper color={RED}>
            {checkTernaryCondition(
              isFieldAreaZero,
              INFO_WINDOW_CONTENT.FIELD_AREA_ZERO_ERROR_MSG,
              INFO_WINDOW_CONTENT.fieldAreaExceedingFarmAreaErrorMsg,
            )}
          </ContentWrapper>
        )}

        <AcceptanceBtnWrapper>
          {!fieldAreaExceedingFarmArea && (
            <AcceptBtn
              onClick={checkTernaryCondition(
                isFieldNameUnique && +fieldArea > 0,
                () => onAccept(name),
                () => {
                  /* do nothing */
                },
              )}
              disabled={!isFieldNameUnique || isFieldAreaZero || !switchState}>
              {INFO_WINDOW_CONTENT.acceptBtnText}
            </AcceptBtn>
          )}
          <DeleteBtn
            onClick={onDelete}
            disabled={!switchState}
            color={fieldAreaExceedingFarmArea && RED}>
            {INFO_WINDOW_CONTENT.deleteBtnText}
          </DeleteBtn>
        </AcceptanceBtnWrapper>
      </div>
    </CustomInfoWindow>
  );
};

InfoWindowContent.propTypes = {
  onAccept: PropTypes.func,
  onDelete: PropTypes.func,
  fieldArea: PropTypes.number,
  totalFarmArea: PropTypes.number,
};

InfoWindowContent.defaultProps = {
  onAccept: () => {
    /* do nothing */
  },
  onDelete: () => {
    /* do nothing */
  },
  fieldArea: 0,
  totalFarmArea: 0,
};

export default InfoWindowContent;
