import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  RED,
  ROW_FOCUSED_BG,
  ROW_HOVER_BG,
  WHITE,
} from 'theme/GlobalColors';
import { flexSpaceBetween, pointerCursor } from 'theme/GlobalStyles';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import {
  deletePrimaryButtonStyle,
  deleteTertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';

// Styles used for sx props and style props in DeleteFieldSelector.react.js
export const deleteFieldSelectorStyles = {
  slotProps: {
    paper: {
      sx: {
        '& .MuiAutocomplete-listbox': {
          "& .MuiAutocomplete-option[aria-selected='true']": {
            backgroundColor: ROW_FOCUSED_BG,
            '&.Mui-focused': {
              backgroundColor: ROW_FOCUSED_BG,
            },
          },
        },
        '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
          backgroundColor: WHITE,
          '&:hover': {
            background: ROW_HOVER_BG,
          },
        },
      },
    },
  },
  dialog: {
    '.MuiDialog-paper ': { width: '500px', padding: '1.5rem' },
  },
  dialogTitle: { ...flexSpaceBetween, padding: '0 0 1rem 0' },
  closeIcon: {
    ...pointerCursor,
    alignSelf: 'flex-start',
    color: COOL_GRAY,
  },
  dialogContent: { padding: 0, marginBottom: '1rem', overflow: 'hidden' },
  autocompleteListBoxProps: { style: { fontSize: '12px', fontWeight: 400 } },
  autocomplete: {
    height: '28px',
    '& .MuiAutocomplete-input': {
      fontSize: '12px',
      fontWeight: 400,
    },
  },
  textField: {
    '.MuiOutlinedInput-root': {
      padding: 0,
      height: '28px',
      fontWeight: 400,
      color: DARK_CHARCOAL,
    },
    '.MuiInputBase-root ': {
      padding: '0 0 0 8px',
      fontSize: '12px',
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: COOL_GRAY,
    },
  },
  dialogActions: { padding: 0, marginTop: '2rem' },
  deleteBtn: deletePrimaryButtonStyle(),
  cancelBtn: deleteTertiaryButtonStyle,
};

export const DeleteModalTitleText = styled.div`
  font-size: 1.25rem;
  font-weight: 400;
  color: ${RED};
`;

export const ContentText = styled.div`
  font-size: 0.875rem;
  color: ${DARK_CHARCOAL};
  margin-bottom: 1rem;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;

export const Search = () => {
  return (
    <IconWrapper>
      <SearchIcon />
    </IconWrapper>
  );
};
