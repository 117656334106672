import DialogBox from 'components/DialogBox/DialogBox.react';
import CustomOptionsButton from 'components/SimpleOptionsButton/CustomOptionsButton.react';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DeleteFieldSelector from './DeleteFieldSelector.react';
import {
  DELETE_CONFIRMATION_MODAL_INIT_STATE,
  getConfirmModalContent,
  getLastFieldDeleteConfirmationContent,
} from './DeleteFarmAndFields.content';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getActivityUnapprovedItems } from 'pages/ParticipantDataCollection/ReportingUtilFunctions';
import { checkTernaryCondition } from 'utils/helper';
import { FARM_INFO_BASE, FIELD_INFO_BASE } from 'urls';
import axios from 'axios';
import Loader from 'components/Loader/Loader.react';
import { pathName } from 'Routes/routes.const';
import { activityInstanceStatus } from 'pages/ParticipantDataCollection/ActivityReporting/ActivityReporting.content';
import { customDialogSx } from 'theme/GlobalStyles';

const DeleteFarmAndFields = ({
  farmList,
  currentFarmId,
  currentFieldId,
  projectCycleData,
  refreshData,
  navigateCallback,
  hideFarmDelete = false,
  isParticipantContributor = false,
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [fieldSelectorOpen, setFieldSelectorOpen] = useState(false);
  const [deleteConfirmModalProps, setDeleteConfirmModalProps] = useState(
    DELETE_CONFIRMATION_MODAL_INIT_STATE,
  );
  const [loading, setLoading] = useState(false);

  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const projectCycleId = searchParams.get('projectcycleid');

  const currentFarmData = useMemo(() => {
    if (!currentFarmId || !farmList) return null;

    const farmData = farmList.find((farm) => farm.farmId === currentFarmId);
    return {
      ...farmData,
      name: farmData?.farmName ?? '',
      fields: farmData?.fieldDetails ?? [],
    };
  }, [farmList, currentFarmId]);

  const handleFieldDelete = (fieldData) => {
    // Note: If farm has only one field, then show a modal indicating that deleting this field will also delete the farm.
    if (currentFarmData?.fields?.length === 1) {
      const projectCycle = `${projectCycleData?.cycleStartDate} - ${projectCycleData?.cycleEndDate}`;
      const modalContent = getLastFieldDeleteConfirmationContent(
        currentFarmData?.name,
        projectCycle,
      );

      setDeleteConfirmModalProps((prev) => {
        return {
          ...prev,
          isOpen: true,
          title: modalContent.title,
          subtitle: modalContent.subtitle,
          deleteType: 'field',
          deleteItem: fieldData,
        };
      });
      return;
    }

    const fieldName = fieldData.fieldName;
    const modalContent = getConfirmModalContent(fieldName);

    setDeleteConfirmModalProps((prev) => {
      return {
        ...prev,
        isOpen: true,
        title: modalContent.title,
        subtitle: modalContent.subtitle,
        deleteType: 'field',
        deleteItem: fieldData,
      };
    });
  };

  const selectFarmDelete = () => {
    const modalContent = getConfirmModalContent(currentFarmData?.name);

    setDeleteConfirmModalProps((prev) => {
      return {
        ...prev,
        isOpen: true,
        title: modalContent.title,
        subtitle: modalContent.subtitle,
        deleteType: 'farm',
        deleteItem: currentFarmData,
      };
    });
  };

  const selectFieldDelete = () => {
    setFieldSelectorOpen(true);
  };

  const navigateToNextPage = () => {
    if (
      deleteConfirmModalProps.deleteType === 'farm' &&
      deleteConfirmModalProps.deleteItem.farmId === currentFarmId
    ) {
      const nextFarm = farmList.find((farm) => farm.farmId !== currentFarmId);
      navigateCallback({
        navigateTo: 'FARM',
        farmData: { farmId: nextFarm.farmId, farmName: nextFarm.farmName },
      });
    } else if (
      deleteConfirmModalProps.deleteType === 'field' &&
      deleteConfirmModalProps.deleteItem.fieldId === currentFieldId
    ) {
      // If we delete the current field, then we need to navigate to the farm or the next field
      if (currentFarmData?.fields.length === 1) {
        // If there is only one field in the farm, then navigate to another farm since current farm will also be deleted
        const nextFarm = farmList.find((farm) => farm.farmId !== currentFarmId);
        navigateCallback({
          navigateTo: 'FARM',
          farmData: { farmId: nextFarm.farmId, farmName: nextFarm.farmName },
        });
      } else {
        const nextField = currentFarmData.fields.find(
          (field) => field.fieldId !== currentFieldId,
        );
        navigateCallback({
          navigateTo: 'FIELD',
          fieldData: {
            fieldId: nextField.fieldId,
            fieldName: nextField.fieldName,
            farmId: currentFarmId,
            farmName: currentFarmData.name,
          },
        });
      }
    } else {
      refreshData();
    }
  };

  const handleDeleteConfirm = () => {
    const activityUnApprovedItems = getActivityUnapprovedItems(farmList);
    const deleteAllItemsInCurrentFarm =
      deleteConfirmModalProps.deleteType === 'farm' ||
      (deleteConfirmModalProps.deleteType === 'field' &&
        currentFarmData?.fields?.length === 1);

    const itemsAfterFilter = activityUnApprovedItems.filter((item) => {
      if (deleteAllItemsInCurrentFarm) {
        return item.data.farmId !== deleteConfirmModalProps.deleteItem?.farmId;
      } else {
        return (
          item.type === 'field' &&
          item.data.fieldId !== deleteConfirmModalProps.deleteItem.fieldId
        );
      }
    });

    const formHasNoActivityUnApprovedItems = itemsAfterFilter.length === 0;

    const URL = checkTernaryCondition(
      deleteConfirmModalProps.deleteType === 'farm',
      `${FARM_INFO_BASE}/${currentFarmId}?returnEnrollmentInstanceId=${formHasNoActivityUnApprovedItems}`,
      `${FIELD_INFO_BASE}/${deleteConfirmModalProps.deleteItem.fieldId}?returnEnrollmentInstanceId=${formHasNoActivityUnApprovedItems}`,
    );

    setLoading(true);
    axios
      .delete(URL)
      .then((response) => {
        if (formHasNoActivityUnApprovedItems) {
          // Note Response structure is a bit different for farm and field delete
          const enrollmentInstanceId = checkTernaryCondition(
            deleteConfirmModalProps.deleteType === 'farm',
            response.data?.data?.enrollmentInstanceId,
            response.data?.enrollmentInstanceId,
          );

          const enrollmentPage = `${pathName.participant.participantDataCollection}?projectid=${projectId}&participantid=${participantId}&enrollmentInstanceId=${enrollmentInstanceId}&projectcycleid=${projectCycleId}`;
          const landingPage = pathName.participant.landingPage;

          // Role based navigation
          const navigateLink = checkTernaryCondition(
            isParticipantContributor,
            landingPage,
            enrollmentPage,
          );

          navigate(navigateLink, { replace: true });
          return;
        }
        navigateToNextPage();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setDeleteConfirmModalProps(DELETE_CONFIRMATION_MODAL_INIT_STATE);
      });
  };

  const options = [
    {
      disabled: false,
      clickHandler: selectFarmDelete,
      itemLabel: 'Delete Farm',
    },
    {
      disabled: false,
      clickHandler: selectFieldDelete,
      itemLabel: 'Delete Field',
    },
  ];

  if (hideFarmDelete) {
    options.shift(); // removes first option(Delete farm)
  }

  const activityNotApprovedFields = currentFarmData?.fields.filter(
    (field) =>
      field?.activityReportingStatus != activityInstanceStatus.approved,
  );

  return (
    <>
      <Loader loading={loading} zIndex={999999} />
      <CustomOptionsButton iconAlignment="horizontal" options={options} />

      <DialogBox
        deleteOperation
        isCloseEnabled
        customSx={customDialogSx}
        isOpen={deleteConfirmModalProps.isOpen}
        title={deleteConfirmModalProps.title}
        subtitle={deleteConfirmModalProps.subtitle}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Delete"
        onCancel={() =>
          setDeleteConfirmModalProps(DELETE_CONFIRMATION_MODAL_INIT_STATE)
        }
        onConfirm={handleDeleteConfirm}
        dialogActions
        isInsideBaseline
      />

      <DeleteFieldSelector
        open={fieldSelectorOpen}
        handleClose={() => setFieldSelectorOpen(false)}
        handleDelete={handleFieldDelete}
        fieldList={activityNotApprovedFields}
      />
    </>
  );
};

DeleteFarmAndFields.propTypes = {
  farmList: PropTypes.array,
  currentFarmId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentFieldId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  projectCycleData: PropTypes.object,
  refreshData: PropTypes.func,
  navigateCallback: PropTypes.func,
  hideFarmDelete: PropTypes.bool,
  isParticipantContributor: PropTypes.bool,
};

export default DeleteFarmAndFields;
