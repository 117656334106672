export const getConfirmModalContent = (farmOrFieldName) => {
  return {
    title: `Delete ${farmOrFieldName}`,
    subtitle: `Are you sure you want to delete ${farmOrFieldName}? Any data entered will be lost.`,
  };
};

export const getLastFieldDeleteConfirmationContent = (
  farmName,
  projectCycle,
) => {
  return {
    title: 'Delete Field',
    subtitle: `This is the last field you have added to the farm “${farmName}” for the ${projectCycle} project cycle. Deleting this field will also delete “${farmName}” from this project cycle.`,
  };
};

export const DELETE_CONFIRMATION_MODAL_INIT_STATE = {
  isOpen: false,
  title: '',
  subtitle: '',
  deleteType: '',
  deleteItem: null,
};

export const FIELD_SELECTOR_MODAL_CONTENT = {
  TITLE: 'Delete Field',
  CONTENT_TEXT: 'Search and select the field you’d like to delete.',
  CANCEL_BTN_LABEL: 'Cancel',
  DELETE_BTN_LABEL: 'Delete',
};
