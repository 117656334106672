import {
  BLUISH_CYAN,
  COOL_GRAY,
  DARK_CHARCOAL,
  ROW_HOVER_BG,
} from 'theme/GlobalColors';

export const menuItemSx = {
  minWidth: '9rem',
  height: '2.5rem',
  padding: '6px 8px',
  fontSize: '0.75rem',
  color: DARK_CHARCOAL,
  ':hover': { backgroundColor: ROW_HOVER_BG },
};

export const menuItemDisabledSx = {
  ...menuItemSx,
  opacity: '0.6 !important',
};
export const MenuSx = {
  padding: '0px',
  '& .MuiPaper-root': {
    borderRadius: '0.125rem',
    transform: 'translateX(-3rem) !important',
  },
  '& .MuiMenu-list': {
    padding: '0.5rem 0 ',
  },
};
export const zeroPaddingSx = {
  padding: 0,
  ':hover': { color: BLUISH_CYAN },
};
export const IconButtonSx = {
  padding: 0,
  color: COOL_GRAY,
  paddingLeft: '0.8rem',
};
export const ActivityActionButtonSx = {
  padding: 0,
  color: COOL_GRAY,
  marginLeft: '0.875rem',
};
