import DialogBox from 'components/DialogBox/DialogBox.react';
import { MODAL_CONTENT } from './EdgeOfFieldCalcModal.content';
import { ContentWrapper, ParaWrapper } from './EdgeOfFieldCalcModal.style';
import React from 'react';
import PropTypes from 'prop-types';

const EdgeOfFieldCalcModal = ({ isOpen, setIsOpen }) => {
  return (
    <DialogBox
      title={MODAL_CONTENT.TITLE}
      isOpen={isOpen}
      isCloseEnabled={false}
      onConfirm={() => {
        setIsOpen(!open);
      }}
      acceptCtnLabel={MODAL_CONTENT.ACCEPT_BTN}
      dialogActions>
      <ContentWrapper>
        {MODAL_CONTENT.DESCRIPTION.map((content, index) => {
          let keyVal = index;
          return <ParaWrapper key={keyVal}>{content}</ParaWrapper>;
        })}
      </ContentWrapper>
    </DialogBox>
  );
};
EdgeOfFieldCalcModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};
export default EdgeOfFieldCalcModal;
