export const ACCESS_DENIED_PAGE_CONTENT = {
  access_denied_heading: 'Access denied',
  permission_heading: 'This page requires permission',
  permission_text:
    'Sorry, you don’t have permission for the page you are trying to access. If you believe you should be authorized please contact your administrator.',
  participant_portal_permission_text:
    'Sorry, you don’t have permission for the page you are trying to access. If you believe you should be authorized contact support@clearcarbon.earth.',
  participant_contributor_permission_text:
    'Sorry, you don’t have permission for the page you are trying to access. If you believe you should be authorized contact',
  back_button_label: 'Go back to previous page',
  permission_body:
    "Sorry, you don't have permission to access this page. Please reach out to",
  for_support: 'for support.',
  ACCESS_DENIED_BOX_CONTENT:
    "Sorry, you don't have permission to access this page. Please reach out to support@email.com for support.",
  CLIENT_SUPPORT_EMAIL_KEY: 'client_support_email',
  RETURN_TO_HOME: 'Return to home',
};
export const testClientConfigs = [
  {
    key: 'client_support_email',
    value: 'support@email.com',
  },
];
