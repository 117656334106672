import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: DARK_CHARCOAL,
    border: '1px solid #E6E8ED',
    fontSize: '12px',
    marginLeft: '10px',
    maxWidth: 'fit-content',
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.1)',
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    fontSize: 20,
    color: theme.palette.common.white,
  },
}));

export default LightTooltip;
