import styled from 'styled-components';
import {
  BLUISH_CYAN,
  COOL_GRAY,
  DARK_CHARCOAL,
  DARK_GREEN,
  GLOBAL_BLUE,
  QUILL_GRAY,
  RED,
  WHITE,
  NEW_HOVER_GREEN_BACKGROUND,
  NEW_FOCUS_DARK_GREEN,
  NEW_DARK_GREEN,
  PAGE_BACKGROUND_COLOR,
  BLACK,
  TYPOGRAPHY_GRAY,
} from 'theme/GlobalColors';
import { FooterWrapper } from '../components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';

export const GeneralInfoWrapper = styled.div`
  padding: 1.5rem;
  height: ${({ minHeight }) => minHeight};
  overflow: auto;
`;

export const GeneralInfoFooterWrapper = styled(FooterWrapper)`
  background: ${PAGE_BACKGROUND_COLOR};
  & .muibox-root: {
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
  }
  & a,
  div {
    color: ${DARK_CHARCOAL};
  }
  & .MuiContainer-root {
    margin-top: 16px;
  }
`;

export const FormSection = styled.div`
  box-sizing: border-box;
  max-height: calc(100vh - 10.7rem);
  background-color: ${WHITE};
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${QUILL_GRAY};
    border-radius: 3px;
  }
`;
export const MainWrapper = styled.div`
  width: 552px;
  margin-bottom: 1rem;
`;
export const HeadingWrapper = styled.div`
  display: flex;
  padding-left: 1.5rem;
  width: fit-content;
  height: 1.75rem;
  gap: 1rem;
`;
export const FormFieldsWrapper = styled.div`
  margin-bottom: 1.5rem;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const FormHeading = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: ${DARK_CHARCOAL};
  display: flex;
  justify-content: space-between;
`;
export const SubsectionWrapper = styled.div`
  margin-left: 0rem;
  width: 100%;
  max-width: 508px;
`;
export const FlexWrapper = styled.div`
  margin-left: 0.75rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;
export const EachFundingWrapper = styled.div`
  display: block;
`;
export const AcerageWrapper = styled.div``;
export const generalFarmButtonSx = {
  textTransform: 'none',
  backgroundColor: DARK_GREEN,
  '&:hover': {
    backgroundColor: DARK_GREEN,
  },
};
export const fundingSx = () => {
  return {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      marginTop: '0.1rem',
      width: '40%',
      marginBottom: '-0.1rem',
    },
  };
};

export const NameSectionWrapper = styled.div`
  display: flex;
  grid-column-gap: 1rem;
  width: 100%;
`;

export const LocationSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 1.5rem;
  width: 100%;
`;

export const USDAStatusLabelSx = {
  '& p': {
    width: '100%',
  },
};

export const LivestockTypeWrapper = styled.div`
  display: flex;
  grid-column-gap: 1.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
`;

export const LivestockTypeLabelWrapper = styled.div`
  display: inline-flex;
  grid-column-gap: 5.25rem;
  width: 100%;
`;

export const ErrorWrapper = styled.div`
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 1rem;
  color: ${RED};
  letter-spacing: 0.013rem;
  margin-bottom: 0.625rem;
`;

export const TextWrapper = styled.div`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  width: ${({ width }) => width};
`;

export const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  padding-top: 0.35rem;
  height: fit-content;
`;

export const InputWithDeleteIconWrapper = styled.div`
  display: flex;
  grid-column-gap: 0.5rem;
  margin-bottom: 0.5rem;
`;
export const dropdownInputSx = {
  '& .MuiInputBase-input': {
    padding: '0.25rem 2rem 0.25rem 0.5rem',
  },
};
export const outlinedDropdownInputSx = {
  '& .MuiOutlinedInput-input': {
    padding: '0.25rem 2rem 0.25rem 0.5rem',
    minWidth: '10rem',
  },
};
export const radioBtnPadding = '0rem 0.5rem 0rem 0.563rem';
export const OwnerDisclaimer = styled.div`
  display: flex;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 0rem 2rem 0rem 0rem;
  gap: 0.25rem;
  border-radius: 0.125rem 0rem 0rem 0rem;
  opacity: 0px;
  width: 55.5rem;
  height: 2.5rem;
`;

export const OwnerDisclaimerText = styled.div`
  font-family: Open Sans;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: ${DARK_CHARCOAL};
  font-style: italic;
`;
export const closeIconSx = {
  cursor: 'pointer',
  alignSelf: 'flex-start',
  color: COOL_GRAY,
};
export const CloseLink = styled.span`
  color: ${GLOBAL_BLUE};
  cursor: pointer;
`;

export const AddButtonSx = {
  border: `0.0625rem solid ${BLUISH_CYAN}`,
  borderRadius: '0.25rem',
  padding: '0.375rem 0.5rem 0.375rem 0.5rem',
  backgroundColor: WHITE,
  color: BLUISH_CYAN,
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.192rem',
  textAlign: 'left',
  height: '1.75rem',
  '&:focus': {
    border: `0.0625rem solid ${BLUISH_CYAN}`,
  },
  '&:hover': {
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: NEW_FOCUS_DARK_GREEN,
  },
  '&.Mui-disabled.MuiButton-root': {
    color: TYPOGRAPHY_GRAY,
    border: `1px solid ${TYPOGRAPHY_GRAY}`,
    backgroundColor: WHITE,
  },
};

export const DeleteIconCustomStyle = {
  color: COOL_GRAY,
  ':hover': {
    color: DARK_GREEN,
  },
};
export const deleteIcon = {
  position: 'absolute',
  right: '0.65rem',
  color: COOL_GRAY,
  '&:hover': {
    fill: NEW_DARK_GREEN,
  },
};
export const deleteIconHoverStyle = {
  '&.MuiSvgIcon-root': {
    ':hover': { fill: NEW_DARK_GREEN },
  },
};

export const deleteFarmDialogTitleSx = {
  justifyContent: 'flex-start',
};

export const deleteFarmModalTitleSx = {
  width: '100%',
  wordWrap: 'break-word',
};

export const deleteFarmCustomContentSx = {
  wordWrap: 'break-word',
};

export const SwitchContainer = styled.div`
  display: flex;
  opacity: ${({ opacity }) => opacity ?? 1};
  pointer-events: ${({ pointerEvents }) => pointerEvents ?? 'auto'};
  width: fit-content;
  justify-content: center;
  gap: 0.5rem;
`;

export const switchCompStyle = { marginTop: '0.3rem' };

export const headCountStyle = { width: '8.75rem' };

export const customCityStyles = { width: '100%' };

export const switchLabelStyle = {
  fontSize: '0.75rem',
  color: BLACK,
  alignSelf: 'center',
  width: '6rem',
};
