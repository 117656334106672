import React, { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FETCH_UOM_LIST } from 'urls';
import axios from 'axios';
import { findObjectWithKey } from 'utils/helper';
import {
  ADD_PIPELINE_AREA,
  CONTRACTING,
  CROP_LAND_USE,
  FARM_ENROLLMENT,
  FERTILIZER,
  FIELD_ENROLLMENT,
  IMPLEMENTED_ACTIVITY_SUMMARY,
  PROOF_OF_IMPLEMENTATION,
  TILLAGE,
  TRACTOR_VEHICLE_FUEL,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import { ERROR_MSGS } from 'utils/config';

export const uomContext = createContext({});
export const UnitOfMeasureListProvider = ({ children }) => {
  const [unitOfMeasureList, setUnitOfMeasureList] = useState({
    contracting: [],
    cropAndLandUse: [],
    addPipeLineArea: [],
    tractorVehicleFuel: [],
    fertilizer: [],
    tillage: [],
    proofOfImplementation: [],
    fieldEnrolment: [],
    farmEnrolment: [],
  });

  /**
   * @description Fetches the list of unit of measure
   */
  const fetchUnitOfMeasureList = () => {
    axios
      .get(FETCH_UOM_LIST)
      .then((res) => {
        setUnitOfMeasureList({
          contracting: findObjectWithKey(res.data, CONTRACTING).CONTRACTING,
          cropAndLandUse: findObjectWithKey(res.data, CROP_LAND_USE)
            .CROP_LAND_USE,
          addPipeLineArea: findObjectWithKey(res.data, ADD_PIPELINE_AREA),
          tractorVehicleFuel: findObjectWithKey(res.data, TRACTOR_VEHICLE_FUEL)
            .TRACTOR_VEHICLE_FUEL,
          fertilizer: findObjectWithKey(res.data, FERTILIZER).FERTILIZER,
          tillage: findObjectWithKey(res.data, TILLAGE).TILLAGE,
          implementedActivities: findObjectWithKey(
            res.data,
            IMPLEMENTED_ACTIVITY_SUMMARY,
          ).IMPLEMENTED_ACTIVITY_SUMMARY,
          proofOfImplementation: findObjectWithKey(
            res.data,
            PROOF_OF_IMPLEMENTATION,
          ).PROOF_OF_IMPLEMENTATION,
          fieldEnrolment: findObjectWithKey(res.data, FIELD_ENROLLMENT)
            .FIELD_ENROLLMENT,
          farmEnrolment: findObjectWithKey(res.data, FARM_ENROLLMENT)
            .FARM_ENROLLMENT,
        });
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      });
  };

  useEffect(() => {
    fetchUnitOfMeasureList();
  }, []);

  const value = useMemo(
    () => ({
      unitOfMeasureList,
      setUnitOfMeasureList,
    }),
    [unitOfMeasureList],
  );

  return <uomContext.Provider value={value}>{children}</uomContext.Provider>;
};

UnitOfMeasureListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
