import { checkAccess, isEmpty } from 'utils/helper';
import { useContext } from 'react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import AccessDeniedPage from 'pages/AccessDeniedPage/AccessDeniedPage.react';
import PropTypes from 'prop-types';
import {
  ADMIN,
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
  USER_STATUS,
  contributorAccessRoutes,
} from 'utils/config';
import { Navigate } from 'react-router';

/**
 * @description ProtectedRoute component to check user permissions and navigate to the respective page
 * @param {Array} [userPermissions=[]]
 * @param {Array} [requiredPermissions=[]]
 * @param {Element} element
 * @param {String} url
 * @returns {Element}
 */
const ProtectedRoute = ({
  userPermissions = [],
  requiredPermissions = [],
  element,
  url,
}) => {
  const { setIsAccessDenied, isDataFetched, userRoles, userDetails } =
    useContext(userAuthRolesContext);

  /**
   * @description Function to check user permissions
   * @returns {Boolean}
   */
  const checkPermission = () => {
    if (isEmpty(requiredPermissions)) {
      return true;
    }
    return requiredPermissions?.some((permission) =>
      checkAccess(userPermissions, permission),
    );
  };

  /* istanbul ignore else */
  if (isDataFetched) {
    /* istanbul ignore else */
    if (
      userRoles.includes(ADMIN) &&
      userDetails?.userStatus === USER_STATUS.STATUS.INACTIVE
    ) {
      setIsAccessDenied(true);
      return <AccessDeniedPage />;
    }

    if (
      userRoles.includes(PARTICIPANT_CONTRIBUTOR) ||
      userRoles.includes(PARTICIPANT)
    ) {
      /* istanbul ignore else */
      if (
        !isEmpty(userDetails.userStatus) &&
        [USER_STATUS.STATUS.NA, USER_STATUS.STATUS.INACTIVE].includes(
          userDetails?.userStatus,
        )
      ) {
        setIsAccessDenied(true);
        return <AccessDeniedPage />;
      } else if (!contributorAccessRoutes.includes(url)) {
        setIsAccessDenied(false);
        return <Navigate replace to={'/stakeholders/landing-page'} />;
      }
    }

    if (checkPermission()) {
      setIsAccessDenied(false);
      return element;
    } else {
      setIsAccessDenied(true);
      return <AccessDeniedPage />;
    }
  }
};

ProtectedRoute.propTypes = {
  userPermissions: PropTypes.array,
  requiredPermissions: PropTypes.array,
  element: PropTypes.element,
  url: PropTypes.string,
};

export default ProtectedRoute;
