import styled from 'styled-components';
import {
  BLACK,
  COOL_BLACK,
  DARK_CHARCOAL,
  DARK_GREEN,
  TEAL_GREEN_FOCUS,
  NEW_HOVER_DARK_GREEN,
  NEW_DARK_GREEN,
  WHITE,
  DOWNLOAD_LINK,
  EMPTY_STATE_TEXT_COLOR,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight ?? 'calc(100vh - 151px)'};
  margin-top: ${({ marginTop }) => marginTop ?? '3rem'};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '3rem'};
`;

export const LandingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
  width: 100%;
`;

export const LandingPageHeading = styled.div`
  color: ${WHITE};
  font-size: 4rem;
  font-family: 'Open Sans';
  font-weight: 300;
  line-height: 4rem;
  margin-bottom: 1.5rem;
  width: 62.5rem;
`;
export const HeadingWrapper = styled.div`
  width: 75%;
  margin-bottom: 2rem;
`;
export const LandingPageContent = styled.div`
  color: ${WHITE};
  font-size: 1.5rem;
  font-family: 'Open Sans';
  line-height: 2.125rem;
  letter-spacing: -0.013rem;
  width: 37.5rem;
  margin-bottom: 4.75rem;
`;

export const LandingPageButtonStyle = {
  textTransform: 'none',
  backgroundColor: NEW_DARK_GREEN,
  '&:hover': {
    backgroundColor: NEW_DARK_GREEN,
  },
  width: '18.75rem',
};

export const MenuOrReviewPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => checkTernaryCondition(width, width, '45%')};
  margin-top: ${({ marginTop }) => marginTop};
  height: ${({ height }) => checkTernaryCondition(height, height, '65%')};
  padding: 1.5rem;
  background: ${WHITE};
  border-radius: 2rem;
`;

export const AlmostDonePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(prop) => prop.marginTop};
  padding: 1.5rem;
  width: 46rem;
  background: white;
  height: ${({ height }) => height};
  border-radius: 2rem;
  opacity: 0.9;
  max-height: 42rem;
`;
export const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height}; /* Dynamic height */
  width: 100vw; /* Full viewport width */
`;
export const MenuOrReviewSubHeading = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  color: #21323a;
  text-align: center;
  font-size: 1.25rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: -0.013rem;
  margin-top: 2rem;
`;

export const MenuOrReviewContent = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${({ maxWidth }) => maxWidth ?? '37.5rem'};
  cursor: pointer;
`;

export const SubHeadingWrapperParent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  text-align: center;
`;

export const MenuOrReviewSubHeading1 = styled.div`
  width: 37.5rem;
  color: ${COOL_BLACK};
  text-align: center;
  font-size: ${({ customFontSize }) => customFontSize};
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  margin-top: 2rem;
`;

export const MenuOrReviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${({ marginTop }) => marginTop};
  grid-row-gap: 0.5rem;
`;

export const MenuFooter = styled.div`
  display: flex;
  width: 37.5rem;
  flex-direction: column;
  flex-shrink: 0;
  color: #21323a;
  text-align: center;
  font-size: 0.875rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 1.225rem;
  margin-top: 2.5rem;
`;

export const DownloadResponseWrapper = styled.div`
  padding: 0.375rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 0.25rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  color: ${({ disabled }) =>
    checkTernaryCondition(disabled, EMPTY_STATE_TEXT_COLOR, NEW_DARK_GREEN)};
  &:hover {
    color: ${({ disabled }) =>
      checkTernaryCondition(
        disabled,
        EMPTY_STATE_TEXT_COLOR,
        NEW_HOVER_DARK_GREEN,
      )};
  }
  &:focus {
    color: ${({ disabled }) =>
      checkTernaryCondition(
        disabled,
        EMPTY_STATE_TEXT_COLOR,
        TEAL_GREEN_FOCUS,
      )};
  }
`;

export const DownloadResponseText = styled.span`
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
`;

export const MenuOrReviewHeading = styled.div`
  display: flex;
  width: 37.5rem;
  color: ${COOL_BLACK};
  text-align: center;
  font-size: ${({ fontSize }) => fontSize ?? '2.813rem'};
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: ${({ fontWeight }) =>
    checkTernaryCondition(fontWeight, fontWeight, 600)};
  line-height: 3.98rem;
  justify-content: center;
  flex-shrink: 0;
`;

export const ReturnToHomeLink = styled.div`
  color: ${WHITE};
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
`;

export const LeftArrowButton = styled.div`
  position: absolute;
  left: ${({ left }) => left ?? '3%'};
  top: ${({ top }) => top ?? '3%'};
  gap: 0.375rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const MenuProjectEnrollmentWrapper = styled.div`
  text-align: center;
  font-family: 'Open Sans';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.125rem;
  letter-spacing: -0.2px;
  color: ${COOL_BLACK};
  margin-top: 1.5rem;
  max-width: 80%;
  overflow-wrap: break-word;
`;

export const buttonStyleSx = {
  backgroundColor: DARK_GREEN,
  textTransform: 'none',
  color: 'white',
  borderRadius: '0.25rem',
  background: DARK_GREEN,
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  padding: '0.875rem 1rem',
  '&: hover': {
    background: DARK_GREEN,
  },
};

export const ReviewPageBtnWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  grid-column-gap: 1rem;
  margin-top: 2.5rem;
`;

export const reviewBtnSx = {
  textTransform: 'none',
  border: '1px solid var(--border, #17585E)',
  background: 'white',
  color: 'var(--text-fg, #17585E);',
  borderRadius: '0.25rem',
};

export const finishBtnSx = {
  textTransform: 'none',
  background: '#17585E',
  borderRadius: '0.25rem',
  color: 'white',
  '&: hover': {
    background: '#17585E',
  },
};

export const EnrollmentClosedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
  padding: 1.5rem;
  grid-row-gap: 1rem;
  width: 39%;
`;

export const EnrollmentClosedHeading = styled.div`
  font-family: 'Open Sans';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${BLACK};
`;

export const EnrollmentClosedContent = styled.div`
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${BLACK};
`;

export const AttestationCheckBoxLabel = styled.span`
  color: ${DARK_CHARCOAL};
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 75%;
`;

export const StyledSpan = styled.span`
  color: ${DOWNLOAD_LINK};
  border-bottom: 1px solid ${DOWNLOAD_LINK};
  cursor: pointer;
`;

export const switchLabelStyle = {
  fontSize: '0.75rem',
  color: BLACK,
  alignSelf: 'center',
  width: '6rem',
};

export const swithStyle = { marginTop: '0.1rem' };
